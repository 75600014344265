import { useState, useCallback, useEffect } from 'react'
import { Modal, Spacer, Button, Select } from '@truepill/react-capsule'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import { OrderStatus, PrinterPurpose } from '@truepill/tpos-types'
import { ModalDescription, ModalHeader, ModalTitle, InputContainer, ButtonsContainer } from 'components/Modal'
import { GENERATE_SHIPPING_LABEL, LIST_PRINTERS, PRINT_SHIPPING_LABEL } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useInfoToast from 'hooks/toast/useInfoToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { usePrintProvider } from 'providers/PrintProvider'
import { useTaskContext } from 'providers/TaskProvider'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import type { Order, Printer } from 'types'
import { isShipmentGenerated } from 'utils'

interface PrintShippingLabelModalProps {
  isOpen: boolean
  setIsOpen(isOpen: boolean): void
  order: Order
}

const MakePrinterOption = (printer: Printer, getLocationNameById: { (locationId: string): string }) => ({
  label: `${printer.printerName} - ${getLocationNameById(printer.locationId)}`,
  value: printer,
})

const PrintShippingLabelModal = ({ isOpen, setIsOpen, order }: PrintShippingLabelModalProps): JSX.Element => {
  const showErrorToast = useErrorToast(true)
  const showSuccess = useSuccessToast(true)
  const { tasks } = useTaskContext()
  const hasIncompleteTasks = tasks.some(({ completed }) => !completed)
  const [selectedShippingLabelPrinter, setShippingLabelPrinter] = useState<
    { label: string; value: Printer } | undefined
  >()
  const [isGenerateLabelDisabled, setIsGenerateLabelDisabled] = useState(false)
  const { setPrinter, getSavedPrinterId } = usePrintProvider()
  const { getLocationNameById, getPrinterById } = useTPCacheContext()
  const showInfoToast = useInfoToast()

  const { data, error } = useQuery(LIST_PRINTERS, {
    variables: { printerPurpose: PrinterPurpose.ShippingLabel, locationId: order.locationId },
  })

  useEffect(() => {
    const persistedPrinterId = getSavedPrinterId(PrinterPurpose.ShippingLabel)
    if (persistedPrinterId && persistedPrinterId.length) {
      const printer = getPrinterById(persistedPrinterId)

      if (printer) {
        setPrinter(printer as Printer)
        setShippingLabelPrinter(MakePrinterOption(printer, getLocationNameById))
      }
    }
  }, [setPrinter, getPrinterById, getSavedPrinterId, setShippingLabelPrinter, getLocationNameById])

  const shippingLabelPrinterOptions = () => {
    if (error) {
      showErrorToast(`Failed to load printers: ${error.message}`)
      return [{ label: 'Failed to load printers...', value: 'error' }]
    }

    return data?.getPrinters?.map((printer: Printer) => MakePrinterOption(printer, getLocationNameById)) ?? []
  }

  const [generateShippingLabel] = useMutation(GENERATE_SHIPPING_LABEL, {
    onCompleted: () => showSuccess('Shipping label generated.'),
    onError: error => showErrorToast(`Failed to generate label: ${error?.message}`),
  })

  const [printShippingLabel, { loading: printingShippingLabel, reset }] = useMutation(PRINT_SHIPPING_LABEL, {
    onCompleted: () => {
      showSuccess('Print request sent.')
      handleDismiss()
    },
    onError: error => {
      showErrorToast(`Failed to print shipping label. ` + error.message)
      handleDismiss()
    },
  })

  const handleDismiss = useCallback(() => {
    reset()
    setIsOpen(false)
  }, [reset, setIsOpen])

  const handleSubmitClick = useCallback(async () => {
    if (!isShipmentGenerated(order)) {
      if (isGenerateLabelDisabled) return
      setIsGenerateLabelDisabled(true)
      setTimeout(() => setIsGenerateLabelDisabled(false), 30000)
      showInfoToast('Requesting shipping label')
      const scannedTasks =
        order.status === OrderStatus.Packing
          ? tasks
              .filter(task => task.productType === 'OTC')
              .map(task => {
                return {
                  sku: task.code,
                  upc: task.additionalData?.upcScanned || '',
                }
              })
          : []
      await generateShippingLabel({
        variables: { coreOrderId: order.coreOrderId, scannedTasks },
        refetchQueries: ['getOrderQuery'],
      })
    }

    printShippingLabel({
      variables: {
        orderId: order._id,
        printerUrl: selectedShippingLabelPrinter?.value.GCPAddress,
      },
    })
  }, [
    generateShippingLabel,
    printShippingLabel,
    order.coreOrderId,
    order._id,
    selectedShippingLabelPrinter?.value.GCPAddress,
  ])

  const handleSelectShippingLabelPrinter = useCallback(printer => {
    if (printer) {
      setPrinter(printer.value as Printer, PrinterPurpose.ShippingLabel)
      setShippingLabelPrinter(printer)
      showInfoToast(`${printer.label} is selected as shipping label printer.`)
    }
  }, [])

  const hasErrors = !selectedShippingLabelPrinter || hasIncompleteTasks

  return (
    <Modal overlayCss={{ zIndex: 2 }} isOpen={isOpen} onDismiss={handleDismiss}>
      <ModalHeader hideClose>
        <ModalTitle>Print shipping label</ModalTitle>
      </ModalHeader>
      <Spacer />
      <ModalDescription>
        Printer is defaulted to the nearest printer near your work station. You may still change the printer.
      </ModalDescription>
      <InputContainer>
        <Select
          value={selectedShippingLabelPrinter}
          label="Select shipping label printer"
          options={shippingLabelPrinterOptions()}
          onChange={handleSelectShippingLabelPrinter}
          selectedKey="label"
          placeholder="Select a shipping label printer"
        />
      </InputContainer>
      <ButtonsContainer>
        <Button onClick={handleDismiss} variant="primary-text">
          Cancel
        </Button>
        <Button onClick={handleSubmitClick} disabled={hasErrors || printingShippingLabel}>
          Print
        </Button>
      </ButtonsContainer>
      <Spacer />
    </Modal>
  )
}

export default PrintShippingLabelModal
