import type { ClaimEdiRecieved, ClaimEdiSent } from '../ncpdp'
import type { EntityId } from '../shared'

export const ClaimDataPaidStatus = ['Paid', 'DuplicateOfPaid']

export interface ClaimData {
  _id: EntityId
  claimId?: string
  runIds?: string[]
  externalId?: string
  cost?: number
  createdAt?: Date
  updatedAt?: Date
  status?: string
  responseMessage?: string
  bin: string
  pcn?: string
  dateOfService: string
  billingOrder?: number
  payerName?: string
  transactionCode: string
  transmissionDate: Date
  sentAt: Date
  receivedAt?: Date
  sent: ClaimEdiSent
  received?: ClaimEdiRecieved
  reversal?: {
    reversedAt: Date
    sent: ClaimEdiSent
    received: ClaimEdiRecieved
  }
  failedReversals: {
    sent: ClaimEdiSent
    received: ClaimEdiRecieved
  }[]
  rejections?: {
    code: string
    rejectCode: string
    rejectReason: string
    message: string
  }[]
  userId: EntityId
}
