import type { ChangeEvent } from 'react'
import { useState, useCallback, useEffect } from 'react'
import { Modal, Spacer, Button, Select } from '@truepill/react-capsule'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import { PrinterPurpose, ShippingLabelReprintReason } from '@truepill/tpos-types'
import CustomizedTextField from 'components/CustomizedTextField'
import { ModalDescription, ModalHeader, ModalTitle, ButtonsContainer } from 'components/Modal'
import ReprintShippingLabelReasonSelect from 'components/ReprintShippingLabelReasonSelect'
import { LIST_PRINTERS, PRINT_SHIPPING_LABEL } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import { usePrintProvider } from 'providers/PrintProvider'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import styled from 'styled-components'
import { borderColor } from 'styles/styleVariables'
import type { Printer } from 'types'

interface ReprintShippingModalProps {
  isOpen: boolean
  setIsOpen(isOpen: boolean): void
  orderId: string
  locationId: string
  customerLegacyId?: number
}

const MakePrinterOption = (printer: Printer, getLocationNameById: { (locationId: string): string }) => ({
  label: `${printer.printerName} - ${getLocationNameById(printer.locationId)}`,
  value: printer,
})

const ReprintShippingModal = ({ isOpen, setIsOpen, orderId, locationId }: ReprintShippingModalProps): JSX.Element => {
  const [selectedShippingLabelPrinter, setShippingLabelPrinter] = useState<
    { label: string; value: Printer } | undefined
  >()
  const [reprintMessage, setReprintMessage] = useState<string | undefined>('')
  const [reprintReason, setLabelReprintReason] = useState<ShippingLabelReprintReason | undefined>(
    ShippingLabelReprintReason.LabelWrinkedOrDamaged,
  )
  const showErrorToast = useErrorToast(true)
  const showSuccess = useSuccessToast(true)
  const { getLocationNameById, getPrinterById } = useTPCacheContext()
  const { setPrinter, getSavedPrinterId } = usePrintProvider()

  const { data, error } = useQuery(LIST_PRINTERS, {
    variables: { printerPurpose: PrinterPurpose.ShippingLabel, locationId },
  })

  useEffect(() => {
    const persistedPrinterId = getSavedPrinterId(PrinterPurpose.ShippingLabel)
    if (persistedPrinterId && persistedPrinterId.length) {
      const printer = getPrinterById(persistedPrinterId)

      if (printer) {
        setPrinter(printer as Printer)
        setShippingLabelPrinter(MakePrinterOption(printer, getLocationNameById))
      }
    }
  }, [setPrinter, getPrinterById, getSavedPrinterId, setShippingLabelPrinter, getLocationNameById])

  const shippingLabelPrinterOptions = () => {
    if (error) {
      showErrorToast(`Failed to load printers: ${error.message}`)
      return [{ label: 'Failed to load printers...', value: 'error' }]
    }

    return data?.getPrinters?.map((printer: Printer) => MakePrinterOption(printer, getLocationNameById)) ?? []
  }

  const [printShippingLabel, { loading: printingShippingLabel, reset }] = useMutation(PRINT_SHIPPING_LABEL, {
    onCompleted: () => {
      showSuccess('Print request sent.')
      handleDismiss()
    },
    onError: error => {
      showErrorToast(`Failed to print shipping label. ` + error.message)
      handleDismiss()
    },
  })

  const handleMessageChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setReprintMessage(e.target.value)
  }, [])

  const handleReasonChange = useCallback((value: ShippingLabelReprintReason | undefined) => {
    setLabelReprintReason(value)
  }, [])

  const handleDismiss = useCallback(() => {
    setLabelReprintReason(ShippingLabelReprintReason.LabelWrinkedOrDamaged)
    setReprintMessage('')
    reset()
    setIsOpen(false)
  }, [reset, setIsOpen])

  const handleSubmitClick = useCallback(() => {
    printShippingLabel({
      variables: {
        orderId,
        printerUrl: selectedShippingLabelPrinter?.value.GCPAddress,
        reprintInfo: {
          reason: reprintReason,
          message: reprintMessage,
        },
      },
    })
  }, [orderId, printShippingLabel, selectedShippingLabelPrinter?.value.GCPAddress, reprintMessage, reprintReason])

  const handleSelectShippingLabelPrinter = useCallback(
    printer => {
      if (printer) {
        setPrinter(printer.value as Printer, PrinterPurpose.ShippingLabel)
        setShippingLabelPrinter(printer)
        showSuccess(`${printer.label} is selected as shipping label printer.`)
      }
    },
    [setPrinter, showSuccess],
  )

  const hasErrors = !reprintMessage && reprintReason === ShippingLabelReprintReason.Other
  const OtherReasonSelected = reprintReason === ShippingLabelReprintReason.Other

  return (
    <Modal overlayCss={{ zIndex: 2 }} isOpen={isOpen} onDismiss={handleDismiss}>
      <ModalHeader hideClose>
        <ModalTitle>Reprint shipping label</ModalTitle>
      </ModalHeader>
      <Spacer />
      <ModalDescription>
        The shipping label has already been printed. Are you sure you want to reprint the label?
      </ModalDescription>
      <InputContainer>
        <Select
          value={selectedShippingLabelPrinter}
          label="Select shipping label printer"
          options={shippingLabelPrinterOptions()}
          onChange={handleSelectShippingLabelPrinter}
          selectedKey="label"
          placeholder="Select a shipping label printer"
        />
        <ReprintShippingLabelReasonSelect
          required
          label="Reason to reprint"
          value={reprintReason}
          onChange={handleReasonChange}
        />
        <CustomizedTextField
          maxLength={255}
          css={{ resize: 'none' }}
          onChange={handleMessageChange}
          type="textarea"
          placeholder={OtherReasonSelected ? 'Enter reason…' : 'Type additional comment...'}
          value={reprintMessage}
          required={OtherReasonSelected}
          label={`Message ${!OtherReasonSelected ? '(optional)' : ''}`}
          state={hasErrors ? 'error' : 'default'}
          helperText={hasErrors ? 'Message is required' : ''}
        />
      </InputContainer>
      <ButtonsContainer>
        <Button onClick={handleDismiss} variant="primary-text">
          Cancel
        </Button>
        <Button onClick={handleSubmitClick} disabled={hasErrors || printingShippingLabel}>
          Yes, reprint label
        </Button>
      </ButtonsContainer>
      <Spacer />
    </Modal>
  )
}

export const InputContainer = styled.div<{ width?: string; margin?: string }>`
  ${({ width }) => width && `width: ${width};`}
  margin-top: 0.625rem;
  ${({ margin }) => margin && `margin: ${margin};`}
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  > textarea {
    padding-top: 0.3125rem;
    height: 7rem;
    margin-top: 0.625rem;
    border: 0.125rem solid ${borderColor};
  }
  > *:not(:last-child) {
    padding-bottom: 16px;
  }
`

export default ReprintShippingModal
