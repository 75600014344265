interface filterParams {
  isMultiPet?: boolean
}

export const getMultiPetFilter = ({ isMultiPet }: filterParams) => {
  return {
    ...(isMultiPet === true && { patientCount: { $gt: 1 } }),
  }
}

export default getMultiPetFilter
