import { useCallback, useMemo, useState } from 'react'
import { Chip } from '@truepill/react-capsule'
import { useHistory, useLocation, useParams, useQuery } from '@truepill/tpos-react-router'
import { UserRoles, Species, PatientStatus, PatientStatusChangeReason, LaunchDarkly } from '@truepill/tpos-types'
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg'
import { ReactComponent as PetIcon } from 'assets/icons/paw.svg'
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg'
import { ReactComponent as TransferIcon } from 'assets/icons/request.svg'
import AttachmentsPane from 'components/AttachmentsPane'
import AuthLimited from 'components/AuthLimited'
import BreadCrumb from 'components/BreadCrumb'
import CopayHistory from 'components/CopayHistory'
import DashboardTokenLink from 'components/DashboardTokenLink'
import IconWrapper from 'components/IconWrapper'
import LoadingSpinner from 'components/Loading'
import MedicalHistory from 'components/MedicalHistory'
import MessagesPane from 'components/MessagesPane'
import NotesPane from 'components/NotesPane'
import type { NoteTab } from 'components/NotesPane/NotesList/types'
import OrderHistory from 'components/OrderHistory'
import { PageContainer, StickyPageHeadingContainer } from 'components/PageStructure'
import PatientData from 'components/PatientData'
import PriorAuthorizationHistory from 'components/PriorAuthorizationHistory'
import { ToolButton } from 'components/SideToolBar'
import ToolBar, { AttachmentsButton, NotesButton, LogButton } from 'components/ToolBar'
import { GET_DOCUMENTS } from 'gql'
import useLogs from 'hooks/navigation/useLogs'
import usePatient from 'hooks/navigation/usePatient'
import { useFormData } from 'hooks/useFormData'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import useSetPageTitle from 'hooks/useSetPageTitle'
import TransferRequestModal from 'modals/TransferRequestModal'
import UpdatePatientStatusModal from 'modals/UpdatePatientStatusModal'
import useCriticalNotesModal from 'modals/useCriticalNotesModal'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { usePlusClient } from 'providers/VisionRouter'
import {
  goToCreatePharmacyPrescriptionForPatient,
  goToCreatePharmacyPrescriptionForPatientV2,
  goToEditPatient,
} from 'routes'
import styled from 'styled-components'
import { alertRed, borderColor, errorRed, capsuleDarkBlueColor, lightRed } from 'styles/styleVariables'
import type { TPOSDocument } from 'types'
import { NoteSpecialTags } from 'types'
import { isActivePatient } from 'utils'
import {
  Content,
  LoadingSpinnerContainer,
  NewTeamMemberControls,
  SearchControls,
  SearchLabel,
  SearchTables,
  StyledActionBar,
  StyledActionButton,
  StyledSearchInput,
  Title,
  TitleSection,
  ToolBarContainer,
} from './StyledComponents'

const patientNotesTabs: NoteTab[] = ['All', 'Patient', 'Fill', 'Copay', 'Order', 'Rx', 'Critical', 'External']
const patientNotesTabsOriginal: NoteTab[] = ['Patient']
const patientNotesTabsOrder: NoteTab[] = ['All', 'Patient', 'Fill', 'Copay', 'Order', 'Rx', 'Critical', 'External']

const PatientPage = (): JSX.Element => {
  const { patientId } = useParams<{ patientId: string }>()
  const [searchTermPresc, setSearchTermPresc] = useState('')
  const [searchTermOrder, setSearchTermOrder] = useState('')
  const [searchTermCopay, setSearchTermCopay] = useState('')
  const [searchTermPA, setSearchTermPA] = useState('')
  const isCSANotesEnabled = useFlag(LaunchDarkly.FeatureFlags.TEMP_SUPPORT_CSA_NOTES)

  const { showModal } = useModalContext()

  const { error, loading, patient } = usePatient({ patientId })
  const { logs, loading: isLoadingLogs } = useLogs({ patientId }, { shouldIncludePatientRelatedLogs: true })
  const history = useHistory()
  const { search } = useLocation()
  const {
    actions: { updateFormData },
  } = useFormData()

  const goToEditPage = useCallback(() => {
    history.push(goToEditPatient({ patientId, search }))
  }, [patientId, history, search])

  useCriticalNotesModal({ logs, showPatientNotes: true })

  useHotKey('be', HotKeyLevel.normal, goToEditPage)

  const { data: documents } = useQuery<{ getDocuments: TPOSDocument[] }>(GET_DOCUMENTS, {
    variables: {
      patientId,
    },
  })

  const usePrescriptionV2 = useFlag(LaunchDarkly.FeatureFlags.ENABLE_NEW_PRESCRIPTION_CREATE_V2)
  const supportCSANotes = useFlag(LaunchDarkly.FeatureFlags.TEMP_SUPPORT_CSA_NOTES)

  const patientNotes = logs?.filter(note => note.event === 'note' && !note.isArchived)
  const allNotesAreEncounter = patientNotes?.every(note => note.tags.includes(NoteSpecialTags.ENCOUNTER)) ?? false
  const badgeBackgroundColor = allNotesAreEncounter && supportCSANotes ? capsuleDarkBlueColor : lightRed
  const patientAttachments = documents?.getDocuments?.filter(attach => !!attach.patient)

  const pageTitle = useMemo(() => {
    return !patient ? 'Patient' : `Patient - ${patient.firstName} ${patient.lastName}`
  }, [patient])

  const { tokenContext } = usePlusClient()
  const { isCustomerSupport } = tokenContext

  useSetPageTitle(pageTitle)

  if (error) {
    return (
      <PageContainer>
        <StickyPageHeadingContainer>
          <BreadCrumb />
        </StickyPageHeadingContainer>
        <Title>Error loading Patient: {JSON.stringify(error)}</Title>
      </PageContainer>
    )
  }

  if (loading || !patient) {
    return (
      <PageContainer>
        <StickyPageHeadingContainer />
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </PageContainer>
    )
  }

  // Some patients have the medicalHistory set to null, populate it with empty arrays
  if (!patient.medicalHistory) {
    updateFormData({
      patient: {
        medicalHistory: {
          $set: {
            allergies: [],
            conditions: [],
            medications: [],
          },
        },
      },
    })
  }

  const isHuman = !patient.species || patient.species === Species.Human
  const activePatient: boolean = isActivePatient(patient.status)

  return (
    <>
      <PageContainer>
        <BreadCrumb />
        <TitleSection>
          <StyledTitle data-private>
            {patient.firstName} {patient.lastName}
          </StyledTitle>
          {patient.truepillPatientToken && <DashboardTokenLink token={patient.truepillPatientToken} entity="patient" />}
          <Chip>
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
              {!isHuman && <PetIcon style={{ verticalAlign: 'bottom', margin: '0px 0.313rem' }} />}
              <span>{isHuman ? Species.Human : 'PET'}</span>
            </span>
          </Chip>
          {patient.status === PatientStatus.Inactive && <StyledChip state="error">Deactivated</StyledChip>}
          {patient.status !== PatientStatus.Merged && (
            <UpdatePatientStatusButton
              hotKey="no"
              hotKeyLabel="N-O"
              onClick={() =>
                showModal(() => (
                  <UpdatePatientStatusModal
                    title={
                      patient.status === PatientStatus.Active
                        ? 'Deactivate Patient Profile?'
                        : 'Reactivate Patient Profile?'
                    }
                    status={patient.status === PatientStatus.Active ? PatientStatus.Inactive : PatientStatus.Active}
                    reason={PatientStatusChangeReason.Other}
                    patientId={patient._id}
                  />
                ))
              }
              label={patient.status === PatientStatus.Active ? 'Deactivate' : 'Reactivate'}
              disabled={!activePatient} // until reactivation introduced
              background-color="red"
            />
          )}
        </TitleSection>
        <ToolBarContainer>
          <Content>
            <PatientData patientId={patientId} />
            <SearchTables>
              <Content>
                <StyledActionBar>
                  <SearchControls>
                    <SearchLabel>Prescription list</SearchLabel>
                    {/* ToDo: I'm note certain this search control actually does anything */}
                    {/* ToDo: Now that the prescriptions list is paged, we should put some paging controls in place */}
                    <StyledSearchInput
                      value={searchTermPresc}
                      iconColor={borderColor}
                      placeholder="Search prescriptions..."
                      onChange={({ currentTarget: { value } }) => setSearchTermPresc(value)}
                      width="100%"
                    />
                  </SearchControls>
                  {activePatient && (
                    <NewTeamMemberControls>
                      <StyledActionButton
                        icon={
                          <IconWrapper>
                            <TransferIcon fill="black" />
                          </IconWrapper>
                        }
                        hotKey="tr"
                        hotKeyLabel="T-R"
                        label="Request"
                        onClick={() => showModal(() => <TransferRequestModal />)}
                      />
                      <AuthLimited
                        roles={[
                          UserRoles.Pharmacist,
                          UserRoles.Technician,
                          UserRoles.Admin,
                          UserRoles.LeadCustomerSupport,
                          UserRoles.CustomerSupport,
                          UserRoles.LeadPharmacist,
                        ]}
                      >
                        {activePatient && (
                          <StyledActionButton
                            icon={
                              <IconWrapper>
                                <PlusIcon fill="black" />
                              </IconWrapper>
                            }
                            hotKey="n"
                            onClick={() =>
                              usePrescriptionV2
                                ? history.push(
                                    goToCreatePharmacyPrescriptionForPatientV2({ patientId: patient._id, search }),
                                  )
                                : history.push(
                                    goToCreatePharmacyPrescriptionForPatient({ patientId: patient._id, search }),
                                  )
                            }
                            label="New prescription"
                          />
                        )}
                      </AuthLimited>
                    </NewTeamMemberControls>
                  )}
                </StyledActionBar>
                <MedicalHistory patientIds={patient._id} searchTerm={searchTermPresc} />
              </Content>
              <Content>
                <StyledActionBar>
                  <SearchControls>
                    <SearchLabel>Order history</SearchLabel>
                    <StyledSearchInput
                      value={searchTermOrder}
                      iconColor={borderColor}
                      placeholder="Search orders..."
                      onChange={({ currentTarget: { value } }) => setSearchTermOrder(value)}
                      width="100%"
                    />
                  </SearchControls>
                  {activePatient && (
                    <NewTeamMemberControls>
                      {
                        <StyledActionButton
                          icon={
                            <IconWrapper>
                              <PlusIcon fill="black" />
                            </IconWrapper>
                          }
                          hotKey="no"
                          hotKeyLabel="N-O"
                          // onClick={() =>
                          //   history.push()
                          // }
                          label="New order"
                        />
                      }
                    </NewTeamMemberControls>
                  )}
                </StyledActionBar>
                <OrderHistory patientIds={[patient._id]} searchTerm={searchTermOrder} />
              </Content>
              <Content>
                <StyledActionBar>
                  <SearchControls>
                    <SearchLabel>Copay history</SearchLabel>
                    <StyledSearchInput
                      value={searchTermCopay}
                      iconColor={borderColor}
                      placeholder="Search copays..."
                      onChange={({ currentTarget: { value } }) => setSearchTermCopay(value)}
                      width="100%"
                    />
                  </SearchControls>
                </StyledActionBar>
                <CopayHistory patientIds={[patient._id]} searchTerm={searchTermCopay} />
              </Content>
              <Content>
                <StyledActionBar>
                  <SearchControls>
                    <SearchLabel>Prior authorization history</SearchLabel>
                    <StyledSearchInput
                      value={searchTermPA}
                      iconColor={borderColor}
                      placeholder="Search copays..."
                      onChange={({ currentTarget: { value } }) => setSearchTermPA(value)}
                      width="100%"
                    />
                  </SearchControls>
                </StyledActionBar>
                <PriorAuthorizationHistory patientId={patient._id} searchTerm={searchTermPA} />
              </Content>
            </SearchTables>
          </Content>
          <ToolBar>
            <ToolButton label="Edit" icon={EditIcon} tooltipText="Edit (b-e)" clickCallback={goToEditPage} />
            <LogButton logs={logs} patientId={patient._id} />
            <NotesButton
              notesCount={patientNotes?.length}
              showBadge={patientNotes?.length > 0}
              badgeBackgroundColor={badgeBackgroundColor}
            />
            <AttachmentsButton
              attachmentsCount={patientAttachments?.length}
              showBadge={(patientAttachments || []).length > 0}
            />
          </ToolBar>
        </ToolBarContainer>
      </PageContainer>
      <NotesPane
        isLoadingLogs={isLoadingLogs}
        filterOutEncounterRecordsFromOtherThanAllTabs
        patient={patient}
        logs={logs}
        defaultTab={isCustomerSupport() ? 'All' : 'Patient'}
        excludeAllTab={!isCSANotesEnabled}
        tabs={isCSANotesEnabled ? patientNotesTabs : patientNotesTabsOriginal}
        tabsOrder={patientNotesTabsOrder}
      />
      <MessagesPane logs={logs} />
      <AttachmentsPane patientId={patient._id} />
    </>
  )
}

const StyledTitle = styled(Title)`
  font-size: 1.25rem;
  display: flex;
  gap: 0.5rem;
`
const UpdatePatientStatusButton = styled(StyledActionButton)`
  background-color: ${alertRed};
  position: absolute;
  right: 3.5rem;
  color: white;
  border-color: ${errorRed};
`
const StyledChip = styled(Chip)`
  background-color: ${alertRed};
  color: white;
`
export default PatientPage
