import { useMemo } from 'react'
import { useQuery, useParams } from '@truepill/tpos-react-router'
import { ProviderType } from '@truepill/tpos-types'
import { ReactComponent as PrescriptionIcon } from 'assets/icons/prescriptionBottles.svg'
import { ReactComponent as PersonIcon } from 'assets/nav/person.svg'
import BreadCrumb from 'components/BreadCrumb'
import LoadingSpinner from 'components/Loading'
import NotesPane from 'components/NotesPane'
import { PageContainer, StickyPageHeadingContainer } from 'components/PageStructure'
import ToolBar, { NotesButton } from 'components/ToolBar'
import { GET_SYNCED_PRESCRIBER } from 'gql'
import useLogs from 'hooks/navigation/useLogs'
import useSetPageTitle from 'hooks/useSetPageTitle'
import moment from 'moment'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { Prescriber } from 'types'
import { getNotes } from 'utils'
import PrescriberAddresses from './components/PracticeAddresses'
import PrescriberPrescriptions from './components/PrescriberPrescriptions'
import PrescriberProfile from './components/PrescriberProfile'
import PrescriberTabs from './components/PrescriberTabs'
import { Title, LoadingSpinnerContainer, TitleSection, Container, WrapSection, Tab } from './StyledComponents'

const isVeternarian = (prescriber: Prescriber) => {
  return prescriber.suffix === ProviderType.DVM
}

const PrescriberPage = (): JSX.Element => {
  const { prescriberId: id } = useParams<{ prescriberId: string }>()
  const { data, loading, error } = useQuery(GET_SYNCED_PRESCRIBER, {
    variables: { prescriberId: id, npi: id },
  })

  const { logs } = useLogs({
    prescriberId: id,
  })
  const notes = getNotes(logs || [])

  const prescriber = data?.getSyncedPrescriber as Prescriber

  const pageTitle = useMemo(() => {
    return !prescriber ? 'Prescriber' : `Prescriber - ${prescriber.firstName} ${prescriber.lastName}`
  }, [prescriber])

  useSetPageTitle(pageTitle)

  if (error) {
    return (
      <PageContainer>
        <StickyPageHeadingContainer>
          <BreadCrumb />
        </StickyPageHeadingContainer>
        <Title>Error loading Prescriber: {JSON.stringify(error)}</Title>
      </PageContainer>
    )
  }

  if (loading || !prescriber) {
    return (
      <PageContainer>
        <StickyPageHeadingContainer />
        <LoadingSpinnerContainer>
          <LoadingSpinner />
        </LoadingSpinnerContainer>
      </PageContainer>
    )
  }

  return (
    <>
      <PageContainer>
        <BreadCrumb />
        <TitleSection>
          <Title>
            {prescriber.firstName} {prescriber.lastName}
            {prescriber.suffix ? `, ${prescriber.suffix}` : ''}
          </Title>
          {prescriber.createdAt && (
            <p>
              Added on: <span>{moment(prescriber.createdAt).format('MM/DD/YYYY')}</span>
            </p>
          )}
        </TitleSection>
        <PrescriberTabs>
          <Tab icon={<PersonIcon fill={bodyPrimaryColor} />} title="Profile">
            <Container>
              <WrapSection>
                <PrescriberProfile isVet={isVeternarian(prescriber)} prescriber={prescriber} notes={notes} />
                <PrescriberAddresses isVet={isVeternarian(prescriber)} prescriber={prescriber} />
              </WrapSection>
              <ToolBar>
                <NotesButton notesCount={notes.length} showBadge={notes.length > 0} />
              </ToolBar>
            </Container>
          </Tab>
          <Tab icon={<PrescriptionIcon fill={bodyPrimaryColor} />} title="Prescriptions">
            <Container>
              <PrescriberPrescriptions prescriberId={prescriber._id} />
              <ToolBar>
                <NotesButton notesCount={notes.length} showBadge={notes.length > 0} />
              </ToolBar>
            </Container>
          </Tab>
        </PrescriberTabs>
      </PageContainer>
      <NotesPane prescriber={prescriber} logs={notes} showPrescriberNotes={true} />
    </>
  )
}

export default PrescriberPage
