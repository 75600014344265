export enum GenderInitial {
  female = 'F',
  male = 'M',
}

export enum DURStatus {
  NONE = 'none',
  MILD = 'mild',
  MODERATE = 'moderate',
  SEVERE = 'severe',
}

export enum DURType {
  NONE = 'none',
  DUPLICATE_THERAPY = 'duplicateTherapy',
  DDI = 'drugDrugInteraction',
  INFERRED_CONDITION = 'inferredCondition',
  REPORTED_CONDITION = 'reportedCondition',
  ALLERGY = 'allergy',
  AGE_CONTRAINDICATION = 'ageContraindication',
  GENDER_CONTRAINDICATION = 'genderContraindication',
  DOSING_INDICATIONS = 'dosingIndications',
  INGREDIENT_DOSING_INDICATIONS = 'ingredientDosingIndications',
  PREGNANCY_CONTRAINDICATION = 'pregnancyContraindication',
  LACTATION_CONTRAINDICATION = 'lactationContraindication',
}

export interface DUR {
  status: DURStatus
  type: DURType
  subjects: MedispanConcept[]
  directions: string
}

export interface MedispanUnnamedConcept {
  idField: string
  value: string
}

export interface MedispanConcept extends MedispanUnnamedConcept {
  conceptType: string
  name?: string
}

export enum MediSpanErrorType {
  INVALID_PAYLOAD = 'INVALID_PAYLOAD',
}

interface Subject {
  type: string
  value: string
  idField: string
  name: string
  fromHistory?: boolean
  patientSurveyInput?: string
}

export interface Allergy extends Subject {
  type: 'medispan/screenableingredients' | 'medispan/allergicreactions/allergenclasses'
}

export interface Condition extends Subject {
  type: 'medispan/conditions'
  synonyms: string[]
}

export interface Medication extends Subject {
  type: 'medispan/packageddrugs' | 'medispan/drugnames'
}

export type DURSubject = Allergy | Condition | Medication

export interface MedispanEntry {
  conceptType: string
  mediSpanId: number
  id: string
  name: string
  value?: string
  synonyms?: string[]
}

export interface DURSubjectCategories {
  allergies: Allergy[]
  conditions: Condition[]
  medications: Medication[]
}

export enum MedispanPackageDescription {
  AM = 'Ampule',
  AT = 'Atomizer',
  BG = 'Bag',
  BL = 'Blister',
  BO = 'Bottle',
  BX = 'Box',
  CN = 'Can',
  CT = 'Cartridge',
  CP = 'Cup',
  CR = 'Crtrdg-NDL',
  DP = 'Disp Pack',
  DR = 'Drum',
  FC = 'Flex Cont',
  GC = 'Glass Cont',
  IH = 'Inhaler',
  JR = 'Jar',
  PA = 'Pack',
  PB = 'Pump Btl',
  PC = 'Plas Cont',
  PD = 'Punchcard',
  PG = 'Package',
  PK = 'Packet',
  PN = 'Pen',
  RL = 'Roll',
  SB = 'Spray Btl',
  SH = 'Sachet',
  SK = 'Stick',
  ST = 'Strip',
  SR = 'Syringe',
  TB = 'Tube',
  VL = 'Vial',
}

export enum DosageCodeToDescription {
  AERO = 'Aerosol',
  AEPB = 'Aerosol Powder, Breath Activated',
  AERB = 'Aerosol, Breath Activated',
  AERP = 'Aerosol, Powder',
  AERS = 'Aerosol, Solution',
  AUIJ = 'Auto-injector',
  AJKT = 'Auto-injector Kit',
  BAR = 'Bar',
  BEAD = 'Beads',
  CAPS = 'Capsule',
  CAPA = 'Capsule Abuse- Deterrent',
  CPCW = 'Capsule Chewable',
  CPDR = 'Capsule Delayed Release',
  CPEP = 'Capsule Delayed Release Particles',
  CSDR = 'Capsule Delayed Release Sprinkle',
  CDPK = 'Capsule Delayed Release Thereapy Pack',
  C12A = 'Capsule ER 12 Hour Abuse-Deterrent',
  CS12 = 'Capsule ER 12 Hour Sprinkle',
  C2PK = 'Capsule ER 12 Hour Therapy Pack',
  C24A = 'Capsule ER 24 Hour Abuse-Deterrent',
  CS24 = 'Capsule ER 24 Hour Sprinkle',
  C4PK = 'Capsule ER 24 Hour Therapy Pack',
  CP12 = 'Capsule Extended Release 12 Hour',
  CP24 = 'Capsule Extended Release 24 Hour',
  CPEA = 'Capsule Extended Release Abuse-Deterrent',
  CSER = 'Capsule Extened Release Sprinkle',
  CEPK = 'Capsule Extended Release Therapy Pack',
  CPCR = 'Capsule Extended Release*',
  CPSP = 'Capsule Sprinkle',
  CPPK = 'Capsule Therapy Pack',
  CART = 'Cartridge',
  CTKT = 'Cartridge Kit',
  CONC = 'Concentrate',
  CREA = 'Cream',
  CRYS = 'Crystals',
  DEVI = 'Device',
  TEST = 'Diagnostic Test',
  DPRH = 'Diaphragm',
  DISK = 'Disk',
  ELIX = 'Elixir',
  EMUL = 'Emulsion',
  ENEM = 'Enema',
  EXHA = 'Exhaler',
  EXHL = 'Exhaler Liquid',
  EXHP = 'Exhaler Powder',
  EXHS = 'Exhaler Solution',
  EXHU = 'Exhaler Suspension',
  FILM = 'Film',
  FLAK = 'Flakes',
  EXTR = 'Fluid Extract',
  FOAM = 'Foam',
  GAS = 'Gas',
  GEL = 'Gel',
  SOLG = 'Gel Forming Solution',
  GRAN = 'Granules',
  GREF = 'Granules Effervescent',
  GUM = 'Gum',
  IMPL = 'Implant',
  INHA = 'Inhaler',
  INJ = 'Injectable',
  INST = 'Insert',
  IUD = 'Intrauterine Device',
  JTAJ = 'Jet-injector (Needleless)',
  JTKT = 'Jet-injector Kit (Needleless)',
  KIT = 'Kit',
  LEAV = 'Leaves',
  LIQD = 'Liquid',
  LQCR = 'Liquid Extended- Release',
  LQPK = 'Liquid Therapy Pack',
  LOTN = 'Lotion',
  LOZG = 'Lozenge',
  LPOP = 'Lozenge on a Handle',
  MISC = 'Miscellaneous',
  NEBU = 'Nebulization Solution',
  OIL = 'Oil',
  OINT = 'Ointment',
  PACK = 'Packet',
  PADS = 'Pads',
  PSTE = 'Paste',
  PTCH = 'Patch',
  PT24 = 'Patch 24 HR',
  PT72 = 'Patch 72 HR',
  PTTW = 'Patch Twice Weekly',
  PTWK = 'Patch Weekly',
  PLLT = 'Pellet',
  PEN = 'Pen-injector',
  PNKT = 'Pen-injector Kit',
  POWD = 'Powder',
  PDEF = 'Powder Effervescent',
  PRSY = 'Prefilled Syringe',
  PSKT = 'Prefilled Syringe Kit',
  PUDG = 'Pudding',
  RING = 'Ring',
  SHAM = 'Shampoo',
  SHEE = 'Sheet',
  SOLN = 'Solution',
  SOAJ = 'Solution Auto-injector',
  SOCT = 'Solution Cartridge',
  SOTJ = 'Solution Jet-injector',
  SOPN = 'Solution Pen-injector',
  SOSY = 'Solution Prefilled Syringe',
  SOLR = 'Solution Reconstituted',
  SOPK = 'Solution Therapy Pack',
  SPRT = 'Spirit',
  STCK = 'Stick',
  STRP = 'Strip',
  SUPP = 'Suppository',
  SUSP = 'Suspension',
  SUAJ = 'Suspension Autoinjector',
  SUCT = 'Suspension Cartridge',
  SUER = 'Suspension Extended Release',
  SUTJ = 'Suspension Jetinjector',
  SUPN = 'Suspension Peninjector',
  SUSY = 'Suspension Prefilled Syringe',
  SUSR = 'Suspension Reconstituted',
  SRER = 'Suspension Reconstituted ER',
  SUPK = 'Suspension Therapy Pack',
  SWAB = 'Swab',
  SYRP = 'Syrup',
  CHER = 'Table Chewable Extended Release',
  TABS = 'Tablet',
  TABA = 'Tablet Abuse-Deterrent',
  CHEW = 'Tablet Chewable',
  TBEC = 'Tablet Delayed Release',
  TBDD = 'Tablet Delayed Release Disintegrating',
  TDPK = 'Tablet Delayed Release Therapy Pack',
  TBDP = 'Tablet Disintegrating',
  TB3D = 'Tablet Disintegrating Soluble',
  TB3E = 'Tablet Disintegrating Soluble ER',
  TPPK = 'Tablet Disintegrating Therapy Pack',
  TBEF = 'Tablet Effervescent',
  T12A = 'Tablet ER 12 Hour Abuse-Deterrent',
  T2PK = 'Tablet ER 12 Hour Therapy Pack',
  T24A = 'Tablet ER 24 Hour Abuse-Deterrent',
  T4PK = 'Tablet ER 24 Hour Therapy Pack',
  TB12 = 'Tablet Extended Release 12 HR*',
  TB24 = 'Tablet Extended Release 24 HR*',
  TBEA = 'Tablet Extended Release Abuse-Deterrent',
  TBED = 'Tablet Extended Release Disintegrating',
  TEPK = 'Tablet Extended Release Therapy Pack',
  TBCR = 'Tablet Extended-Release',
  TBSO = 'Tablet Soluble',
  SUBL = 'Tablet Sublingual',
  TBPK = 'Tablet Therapy Pack',
  TAMP = 'Tampon',
  TAPE = 'Tape',
  TAR = 'Tar',
  THPK = 'Therapy Pack',
  TINC = 'Tincture',
  TROC = 'Troche',
  WAFR = 'Wafer',
  WAX = 'Wax',
}

export enum RouteOfAdministration {
  BU = 'BUCCAL',
  CO = 'COMBINATION',
  DT = 'DENTAL',
  EP = 'EPIDURAL',
  EX = 'EXTERNAL',
  EC = 'EXTRACORPOREAL',
  HM = 'HEMODIALYSIS',
  IL = 'IMPLANT',
  VI = 'IN VITRO',
  IN = 'INHALATION',
  IJ = 'INJECTION',
  IA = 'INTRA-ARTERIAL',
  IX = 'INTRA-ARTICULAR',
  CA = 'INTRACARDIAC',
  IC = 'INTRACAVERNOSAL',
  CV = 'INTRACAVITARY',
  ID = 'INTRADERMAL',
  LS = 'INTRALESIONAL',
  IM = 'INTRAMUSCULAR',
  IO = 'INTRAOCULAR',
  IP = 'INTRAPERITONEAL',
  PL = 'INTRAPLEURAL',
  SN = 'INSTRASINAL',
  IT = 'INTRATHECAL',
  TR = 'INTRATRACHEAL',
  TP = 'INTRATYMPANIC',
  IU = 'INTRAUTERINE',
  IV = 'INTRAVENOUS',
  IS = 'INTRAVESICAL',
  IZ = 'INTRAVITREAL',
  PH = 'IONTOPHORESIS',
  IR = 'IRRIGATION',
  MT = 'MOUTH/THROAT',
  NA = 'NASAL',
  OP = 'OPHTHALMIC',
  PO = 'ORAL',
  OT = 'OTIC',
  PF = 'PERFUSION',
  PR = 'RECTAL',
  SC = 'SUBCUTANEOUS',
  SL = 'SUBLINGUAL',
  TD = 'TRANSDERMAL',
  TL = 'TRANSLINGUAL',
  UT = 'URETERAL',
  UR = 'URETHRAL',
  VA = 'VAGINAL',
  XX = 'DOES NOT APPLY',
}

export enum PackageSizeUnitOfMeasure {
  EA = 'Each',
  GM = 'Gram',
  ML = 'Milliliter',
}

export enum StorageCondition {
  R = 'Refrigerated',
  F = 'Frozen',
  D = 'DeepFrozen',
  N = 'Normal',
}
