import type { FfsInventoryInfo } from '../prescription'
import type { EntityId } from '../shared'

export enum OtcProductStatus {
  Processing = 'Processing',
  Packing = 'Packing',
  PartialShipped = 'PartialShipped',
  Shipped = 'Shipped',
  Cancelled = 'Cancelled',
}

export interface OtcProductInput {
  rxFillRequestId?: EntityId
  name?: string
  quantity: number
  quantityShipped?: number
  barcode?: string
  sku?: string
  bundleId?: string
  lineId?: number
  bundleItemPartialReturnAllowed?: boolean
  supplier?: string
  otcStatus?: OtcProductStatus
  trackingNumber?: string
  trackingUrl?: string
  pricing?: {
    total?: number
    tax?: number
    cost?: number
    shippingCost?: number
    discount?: number
    promoCodes?: string[]
  }
  signatureRequired?: boolean
  overrideScan?: boolean
  serialNumbers?: string[]
  dispensed?: OtcDispensed
}

export interface OtcDispensed {
  ffsInventoryInfo?: FfsInventoryInfo
}
