import { useMemo } from 'react'
import type { Fill, Order, Prescription } from 'types'
import { NoteSpecialTags, type Log } from 'types'
import type { NoteTab } from '../types'

const createDefaultNotesFilters: () => Record<NoteTab, (allNotes: Log[]) => Log[]> = () => ({
  All: (allNotes: Log[]) => allNotes.filter(note => note.event === 'note'),
  Fill: (allNotes: Log[]) => allNotes.filter(note => !!note.fillId && note.event === 'note'),
  Order: (allNotes: Log[]) => allNotes.filter(note => !!note.orderId && !note.fillId && note.event === 'note'),
  Copay: (allNotes: Log[]) => allNotes.filter(note => !!note.copayRequestId && !note.fillId && note.event === 'note'),
  Rx: (allNotes: Log[]) => allNotes.filter(note => !!note.prescriptionId && note.event === 'note'),
  Patient: (allNotes: Log[]) => allNotes.filter(note => !!note.patientId && note.event === 'note'),
  Prescriber: (allnotes: Log[]) => allnotes.filter(note => !!note.prescriberId && note.event === 'note'),
  Critical: (allNotes: Log[]) =>
    allNotes.filter(note => note.tags.includes(NoteSpecialTags.CRITICAL) && note.event === 'note'),
  External: (allNotes: Log[]) =>
    allNotes.filter(note => note.tags.includes(NoteSpecialTags.EXTERNAL) && note.event === 'note'),
})

const createCopayPageNotesFilters: () => Record<NoteTab, (allNotes: Log[]) => Log[]> = () => ({
  ...createDefaultNotesFilters(),
  Fill: (allNotes: Log[]) => allNotes.filter(note => !!note.fillId && !!note.copayRequestId && note.event === 'note'),
})

interface CreateOrderNotesPageFiltersParams {
  order?: Order
  prescription?: Prescription
  fill?: Fill
  filterByFillId?: string
}

export const createOrderPageNotesFilters = ({
  order,
  prescription,
  fill,
  filterByFillId,
}: CreateOrderNotesPageFiltersParams) => ({
  All: (allNotes: Log[]) => allNotes.filter(note => note.event === 'note'),
  Fill: (allNotes: Log[]) => {
    return allNotes.filter(note => {
      if (note.event !== 'note') return false
      // the order
      if (
        note.orderId === order?._id.toString() &&
        (filterByFillId ? !note.fillId || note.fillId === fill?._id.toString() : true)
      )
        return true
      // the fillId for that specific fill (regardless of whether the log also has an orderId or a copayRequestId)
      if (note.fillId && note.fillId === fill?._id.toString()) return true
      // the prescriptionId tied to that fill
      if (note.prescriptionId && note.prescriptionId === prescription?._id.toString()) return true
      return false
    })
  },
  Order: (allNotes: Log[]) => {
    const filteredRequests =
      order?.rxFillRequests.filter(fr => (filterByFillId ? fr.fillId === filterByFillId : true)) ?? []
    const fillIdsInOrder =
      filteredRequests
        .filter(fr => (filterByFillId ? fr.fillId === filterByFillId : true))
        .map(fr => fr.fillId.toString()) ?? []
    const rxsInOrder = filteredRequests.map(fr => fr.prescriptionId.toString()) ?? []

    return allNotes.filter(note => {
      if (note.event !== 'note') return false
      // the order
      if (note.orderId === order?._id.toString() && (!note.fillId || fillIdsInOrder.includes(note.fillId.toString())))
        return true
      // any of the fillIds tied to the order
      if (note.fillId && fillIdsInOrder.includes(note.fillId.toString())) return true
      // any rx’s linked to the order
      if (note.prescriptionId && rxsInOrder.includes(note.prescriptionId.toString())) return true
      return false
    })
  },
  Copay: (allNotes: Log[]) => allNotes.filter(note => !!note.copayRequestId && !note.fillId && note.event === 'note'),
  Rx: (allNotes: Log[]) => allNotes.filter(note => !!note.prescriptionId && note.event === 'note'),
  Patient: (allNotes: Log[]) => allNotes.filter(note => !!note.patientId && note.event === 'note'),
  Prescriber: (allnotes: Log[]) => allnotes.filter(note => !!note.prescriberId && note.event === 'note'),
  Critical: (allNotes: Log[]) =>
    allNotes.filter(note => note.tags.includes(NoteSpecialTags.CRITICAL) && note.event === 'note'),
  External: (allNotes: Log[]) =>
    allNotes.filter(note => note.tags.includes(NoteSpecialTags.EXTERNAL) && note.event === 'note'),
})

export type NotesFilters = Record<NoteTab, (allNotes: Log[]) => Log[]>

export const useOrderPageNotesFilters = ({
  order,
  prescription,
  fill,
  filterByFillId,
}: CreateOrderNotesPageFiltersParams) => {
  const filters = useMemo(
    () => createOrderPageNotesFilters({ order, prescription, fill, filterByFillId }),
    [order, prescription, fill, filterByFillId],
  )
  return filters
}

export const useCopayPageNotesFilters = () => {
  const filters = useMemo(() => createCopayPageNotesFilters(), [])
  return filters
}

export default createDefaultNotesFilters
