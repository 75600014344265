import { LaunchDarkly } from '@truepill/tpos-types'
import { ReactComponent as HeartIcon } from 'assets/icons/heart.svg'
import { ReactComponent as PeopleIcon } from 'assets/icons/people.svg'
import type { Option } from 'components/Select'
import Select from 'components/Select'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import { usePlusClient } from 'providers/VisionRouter'

interface CustomerSelectorDropDownProps {
  onChange?: (changes: Option[]) => void
  value?: string
  includeAll?: boolean
  noIcon?: boolean
  preferredCustomers?: string[]
}

const CustomerSelectorDropDown = ({
  onChange,
  value,
  includeAll = true,
  preferredCustomers = [],
  noIcon = false,
}: CustomerSelectorDropDownProps): JSX.Element => {
  const {
    currentLocation: { queryMap },
    routeToMergedQuery,
  } = usePlusClient()
  const { customers, getCustomerNameById } = useTPCacheContext()
  const customerIdFromUrl = queryMap.customerIds?.length && queryMap.customerIds[0]

  const customerNameFromUrl = getCustomerNameById(customerIdFromUrl)

  const customerOptions = [...customers]
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((customer): Option => ({ label: customer.name, value: customer._id }))
    .map((customer): Option => {
      const preferred = preferredCustomers.includes(customer.value)
      return Object.assign(customer, preferred ? { icon: HeartIcon } : {})
    })
    .sort((a, b) => {
      const aVal = preferredCustomers.includes(a.value) ? 1 : 0
      const bVal = preferredCustomers.includes(b.value) ? 1 : 0
      return bVal - aVal
    })
  const displayDashboardDesignV2: boolean = useFlag(LaunchDarkly.FeatureFlags.TPOS_DASHBOARD_DESIGN_V2) ?? false
  if (includeAll) {
    customerOptions.unshift({ label: displayDashboardDesignV2 ? 'All Customers' : 'All', value: '' })
  }
  const mergeChangeToQuery = ([option]: Option<any>[]) => {
    const selectedCustomerId = option?.value || undefined

    if (selectedCustomerId !== customerIdFromUrl) {
      routeToMergedQuery({ customerIds: [selectedCustomerId] })
    }
  }

  const getDefaultValue = () => {
    if (value && !includeAll) {
      return { value, label: getCustomerNameById(value) }
    }

    if (includeAll && customerIdFromUrl) {
      return {
        value: customerIdFromUrl,
        label: customerNameFromUrl || (displayDashboardDesignV2 ? 'All Customers' : 'All'),
      }
    }

    // Return undefined if no value is set to allow placeholder display
    return undefined
  }

  return (
    <Select
      fadePlaceholderText={true}
      hotKey="c"
      options={customerOptions}
      value={getDefaultValue()}
      placeholder="Select Customers"
      icon={noIcon ? undefined : <PeopleIcon />}
      onChange={onChange || mergeChangeToQuery}
      showActive={!!customerNameFromUrl}
      disableClear={true}
      showLoadingAnimation={customerOptions.length <= 1}
    />
  )
}

export default CustomerSelectorDropDown
