import { useCallback, useEffect, useMemo, useState } from 'react'
import { floatRound } from '@truepill/tpos-data-util'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import { LaunchDarkly, NdcFullInfoSource } from '@truepill/tpos-types'
import type { StockInfo } from '@truepill/tpos-types'
import CriticalMedicalNoteWarning from 'components/CriticalMedicalNoteWarning'
import DrugImageImprint from 'components/DrugImageImprint'
import { ActionButton } from 'components/PageStructure'
import {
  ImageFilledHeader,
  ListRowLabel,
  ListRowValue,
  RightFilledHeader,
  RXCenterCell,
  RXCenterColumn,
  RXListRowTwoColumns,
  RXRightCellVerticalOverflow,
  RXTitleRow,
  TableContainer,
} from 'components/RXTable'
import { CREATE_LOG, GET_NDC_FULL_INFO } from 'gql'
import { Box } from 'grommet'
import { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import LotQuantityFillModal from 'modals/LotQuantityFillModal'
import ManualEntryFillModal from 'modals/ManualEntryFillModal'
import type { StockInfoWithoutQuantity } from 'modals/ManualEntryFillModal'
import { useFlag, useClient } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { useTaskContext } from 'providers/TaskProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { borderColor, primaryBackgroundColor } from 'styles/styleVariables'
import type { Fill, NdcFullInfo, Order, Prescription } from 'types'
import { getNdcText, getPackageMeasureDescription } from 'utils'
import { getFormattedInventoryGroupName } from 'utils/inventoryGroup'
import ScanTasks from './ScanTasks'
import ScanTasksV2 from './ScanTasksV2'
import useFirstScanType, { BarcodeTypes } from './useFirstScanType'

type DisplayFillPageV2Props = {
  prescription: Prescription
  fill: Fill
  order: Order
  alternateBarCodes: string[]
}

const DisplayFillPageV2 = ({ prescription, fill, order, alternateBarCodes }: DisplayFillPageV2Props): JSX.Element => {
  // This version of Fill Page hides RxImage
  const [stockInfo, setStockInfo] = useState<Array<StockInfo>>([])
  const [requireStockInfoQuantityAdjustment, setRequireStockInfoQuantityAdjustment] = useState(false)
  const [modalQuantityShown, setModalQuantityShown] = useState(false)
  const [manualEntryFilled, setManualEntryFilled] = useState(false)
  const { showModal } = useModalContext()
  const { tasks, completeTask, scanProductsTasksCompleted, nextProductScanTaskIndex } = useTaskContext()
  const { routeTo } = usePlusClient()
  const [createLog] = useMutation(CREATE_LOG, {
    refetchQueries: ['getAllLogs'],
  })

  const tempDisplayPackSizeWithNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_DISPLAY_PACK_SIZE_WITH_NDC)
  const hyphenateNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_HYPHENATE_NDCS)

  const ndcs =
    !!fill?.dispensed.ndc && prescription.ndc !== fill.dispensed.ndc
      ? [prescription.ndc, fill.dispensed.ndc]
      : [prescription.ndc]

  const { data } = useQuery(GET_NDC_FULL_INFO, {
    variables: { ndcs },
  })

  const results: NdcFullInfo[] = data?.getNdcFullInfo || []
  let prescribedNdcFullInfo, dispensedNdcPackage, dispensedPackageSize, dispensedPackageQuantity

  for (const fullInfo of results) {
    for (const ndcPackage of fullInfo.packages) {
      if (ndcPackage.ndc === prescription.ndc) {
        prescribedNdcFullInfo = fullInfo
      }
      if (ndcPackage.ndc === fill?.dispensed.ndc) {
        dispensedNdcPackage = ndcPackage
        dispensedPackageSize = ndcPackage.packageSize
        dispensedPackageQuantity = ndcPackage.packageQuantity
      }
    }
  }

  const dispensedNdcText = getNdcText(dispensedNdcPackage, tempDisplayPackSizeWithNdc, hyphenateNdc)

  const { firstScanType, setFirstScanType, resetFirstScanType } = useFirstScanType()

  const isHumanRX = (prescribedNdcFullInfo?.source || NdcFullInfoSource.Medispan) === NdcFullInfoSource.Medispan

  const handleModalSubmit = ({ lot, expirationDate, serialNumber }: StockInfoWithoutQuantity) => {
    if (
      firstScanType === BarcodeTypes.twoDimensional ||
      (!firstScanType && displayScanTasksV2 && lot && expirationDate)
    ) {
      setFirstScanType(BarcodeTypes.twoDimensional)
      setStockInfo((prevStockInfo: StockInfo[]) => {
        return [
          ...prevStockInfo,
          {
            lot,
            expirationDate,
            serialNumber,
            quantity: scanProductsTasksCompleted ? 0 : quantity,
          },
        ]
      })
    } else setFirstScanType(BarcodeTypes.oneDimensional)

    setManualEntryFilled(true)
    createLog({
      variables: {
        fillId: fill._id,
        message: `NDC manual entry for ${fill.dispensed.ndc} was used${
          displayScanTasksV2 && lot && expirationDate
            ? `, lot number "${lot}", expiration date "${expirationDate}"${
                serialNumber ? `, serial number "${serialNumber}"` : ''
              }`
            : ''
        }`,
        event: 'ndc manual entry',
      },
    })
    nextProductScanTaskIndex >= 0 && completeTask(`scanProduct-${nextProductScanTaskIndex}`, fill.dispensed.ndc)
  }

  const tasksInitialized = useMemo(() => !!tasks.every(({ completed }) => !completed), [tasks])

  const resetStates = useCallback(() => {
    setStockInfo([])
    resetFirstScanType()
    setRequireStockInfoQuantityAdjustment(false)
    setModalQuantityShown(false)
    setManualEntryFilled(false)
  }, [resetFirstScanType])

  useEffect(() => {
    // reset states when fill changes
    resetStates()
  }, [resetStates, fill])

  useEffect(() => {
    // reset states when tasks are initialized
    if (tasksInitialized) {
      resetStates()
    }
  }, [resetStates, tasksInitialized])

  const rxFillRequest = order.rxFillRequests?.find(i => i.fillId === fill._id)
  const inParataWorkflow = rxFillRequest?.status === 'Automation'

  const inventoryGroup = getFormattedInventoryGroupName(fill)

  // Feature flag displayScanTasksV2 depends on customer and location
  // Current rule in development env is set to evaluate true if the customer is Hims and accepts all locations
  const customer = order.customer
  const location = order.location
  const dispensedNdc = fill.dispensed.ndc

  // Set the customer, location and ndc for feature-flag check
  const { client: launchDarklyClient } = useClient()

  useEffect(() => {
    if (launchDarklyClient && customer && location) {
      launchDarklyClient.identify({
        key: customer.legacyId.toString(),
        custom: {
          location: location.legacyId.toString(),
          ndc: dispensedNdc.toString(),
        },
      })
    }
  }, [launchDarklyClient, customer, location, dispensedNdc])

  // If the feature flag displayScanTasksV2 is on, then we will display ScanTasksV2 and be able to scan datamatrix barcodes
  const displayScanTasksV2 = useFlag(LaunchDarkly.FeatureFlags.REQUIRE_USER_TO_SCAN_DATAMATRIX_DURING_FILL)
  const allowLinearScanFlag = useFlag(LaunchDarkly.FeatureFlags.ALLOW_LINEAR_BARCODE_SCANNING)

  const allowLinearScan = allowLinearScanFlag && !isHumanRX
  const fillLabelScanned = useMemo(() => !!tasks.find(({ key }) => key === 'scanFill')?.completed, [tasks])

  const packagesRequired = fill.dispensed.packagesRequired || 1
  const lastProductIndex = packagesRequired - 1
  // this round is to fix where quantity is like 42.599999999999994 and convert it to 42.6
  const dispensedQuantity = floatRound(fill.dispensed.quantity)
  dispensedPackageQuantity = dispensedPackageQuantity || 1
  dispensedPackageSize = dispensedPackageSize || 1

  const isUnbreakable = useMemo(
    () => Boolean(!tasks.find(({ key }) => key === `scanProduct-${lastProductIndex}`)?.subtasks?.length),
    [tasks, lastProductIndex],
  )

  const totalQuantityScanned = stockInfo?.reduce((sum, product) => floatRound(sum + product.quantity || 0), 0)

  const quantity = useMemo(() => {
    // if there is only one single scan required, the total dispensed quantity is assigned to that scan
    if (packagesRequired === 1) {
      return dispensedQuantity
    }
    // this branch should never be reached for required scans if packages required has the expected value
    if (totalQuantityScanned >= dispensedQuantity) {
      return 0
    }
    const isLastProductScan = lastProductIndex === nextProductScanTaskIndex
    if (isLastProductScan) {
      // last scan will always have the ramaining quantity to complete total dispensed quantity
      const quantityPerScan = dispensedPackageQuantity * dispensedPackageSize
      const previousScansTotalQuantity = (packagesRequired - 1) * quantityPerScan
      return floatRound(dispensedQuantity - previousScansTotalQuantity)
    }
    return floatRound(dispensedPackageQuantity * dispensedPackageSize)
  }, [
    lastProductIndex,
    nextProductScanTaskIndex,
    packagesRequired,
    dispensedQuantity,
    dispensedPackageQuantity,
    dispensedPackageSize,
    totalQuantityScanned,
  ])

  const stockInfoHasDifferentLots: boolean = useMemo(() => {
    if (isUnbreakable || stockInfo.length === 0 || !scanProductsTasksCompleted) return false
    const firstLot = stockInfo[0].lot
    return !stockInfo.every(({ lot }) => lot === firstLot)
  }, [isUnbreakable, stockInfo, scanProductsTasksCompleted])

  const stockInfoHasSomeZeroQuantity = stockInfo.some(product => product.quantity === 0)

  useEffect(() => {
    if (
      !modalQuantityShown &&
      (stockInfoHasDifferentLots || stockInfoHasSomeZeroQuantity || totalQuantityScanned > dispensedQuantity)
    ) {
      setRequireStockInfoQuantityAdjustment(true)
    }
  }, [
    stockInfoHasDifferentLots,
    stockInfoHasSomeZeroQuantity,
    totalQuantityScanned,
    dispensedQuantity,
    modalQuantityShown,
  ])

  useEffect(() => {
    if (
      displayScanTasksV2 &&
      !modalQuantityShown &&
      requireStockInfoQuantityAdjustment &&
      tasks.every(({ completed }) => completed)
    ) {
      if (!stockInfoHasDifferentLots) {
        // one single lot has been scanned, so dispensed quantity can be assigned to first scan
        setStockInfo(stockInfo => {
          const stockInfoFixedQuantity = stockInfo.map(product => ({ ...product, quantity: 0 }))
          if (stockInfoFixedQuantity.length) {
            stockInfoFixedQuantity[0].quantity = dispensedQuantity
          }
          return stockInfoFixedQuantity
        })
        setRequireStockInfoQuantityAdjustment(false)
      } else {
        showModal(() => (
          <LotQuantityFillModal
            quantity={dispensedQuantity}
            stockInfo={stockInfo}
            confirmationCallback={(stockInfoWithManualQuantities: StockInfo[]) => {
              setStockInfo(stockInfoWithManualQuantities)
              setRequireStockInfoQuantityAdjustment(false)
            }}
            cancelCallback={() => {
              const fulfillmentQueueName = FulfillmentQueueName.Fill
              routeTo.fulfillment(order._id, fill._id, fulfillmentQueueName).now()
            }}
          />
        ))
      }
      setModalQuantityShown(true)
    }
  }, [
    requireStockInfoQuantityAdjustment,
    modalQuantityShown,
    tasks,
    stockInfo,
    showModal,
    displayScanTasksV2,
    order._id,
    fill._id,
    routeTo,
    dispensedQuantity,
    dispensedPackageSize,
    stockInfoHasDifferentLots,
  ])

  return (
    <TableContainer>
      <RXTitleRow>
        <RXCenterColumn>
          <ImageFilledHeader>Fill Medication</ImageFilledHeader>
        </RXCenterColumn>
        <RXCenterColumn> {!inParataWorkflow && <RightFilledHeader>Tasks</RightFilledHeader>}</RXCenterColumn>
      </RXTitleRow>
      <Box direction="row">
        <Box basis="1/3">
          <RXListRowTwoColumns hideImageColumn>
            <RXCenterCell>
              <ListRowLabel>Medication:</ListRowLabel>
              <ListRowValue>
                <DrugNameWithWarning>
                  {fill.dispensed.name}
                  <CriticalMedicalNoteWarning ndc={fill.dispensed.ndc} />
                </DrugNameWithWarning>
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns hideImageColumn>
            <RXCenterCell>
              <ListRowLabel>NDC:</ListRowLabel>
              <CenteredListRowValue>
                {dispensedNdcText} {inventoryGroup}
                <ManualEntryButton
                  data-testid="manualEntry"
                  disabled={inParataWorkflow || !fillLabelScanned || (scanProductsTasksCompleted && isUnbreakable)}
                  onClick={() =>
                    showModal(() => (
                      <ManualEntryFillModal
                        ndc={fill.dispensed.ndc}
                        stockInfo={stockInfo}
                        requireStockInfo={
                          firstScanType === BarcodeTypes.twoDimensional || (displayScanTasksV2 && !allowLinearScan)
                        }
                        disableStockInfo={firstScanType === BarcodeTypes.oneDimensional}
                        confirmationCallback={handleModalSubmit}
                      />
                    ))
                  }
                >
                  Manual Entry
                </ManualEntryButton>
              </CenteredListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns hideImageColumn>
            <RXCenterCell>
              <ListRowLabel>Mfg:</ListRowLabel>
              <ListRowValue>{fill.dispensed.manufacturer}</ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns hideImageColumn>
            <RXCenterCell>
              <ListRowLabel>Qty:</ListRowLabel>
              <ListRowValue>
                {fill.dispensed.quantity} {getPackageMeasureDescription(fill?.dispensed?.packageSizeUnitOfMeasure)}
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns hideImageColumn>
            <RXCenterCell>
              <ListRowLabel>DS:</ListRowLabel>
              <ListRowValue>{fill.dispensed.daysSupply}</ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns hideImageColumn>
            <RXCenterCell>
              <ListRowLabel>Labels printed:</ListRowLabel>
              <ListRowValue>{fill.labelsPrinted}</ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns hideImageColumn>
            <RXCenterCell>
              <ListRowLabel>Container:</ListRowLabel>
              <ListRowValue>-</ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <DrugImageImprint ndc={fill.dispensed.ndc} hasTwoColumns={true} hideImageColumn />
        </Box>
        {!inParataWorkflow && (
          <RXRightCellVerticalOverflow>
            {displayScanTasksV2 ? (
              <ScanTasksV2
                prescription={prescription}
                fill={fill}
                order={order}
                stockInfo={stockInfo}
                setStockInfo={setStockInfo}
                requireStockInfoQuantityAdjustment={requireStockInfoQuantityAdjustment}
                alternateBarCodes={alternateBarCodes}
                allowLinearScan={allowLinearScan}
                firstScanType={firstScanType}
                setFirstScanType={setFirstScanType}
                quantity={quantity}
                manualEntryFilled={manualEntryFilled}
              />
            ) : (
              <ScanTasks
                prescription={prescription}
                fill={fill}
                order={order}
                alternateBarCodes={alternateBarCodes}
                manualEntryFilled={manualEntryFilled}
              />
            )}
          </RXRightCellVerticalOverflow>
        )}
      </Box>
    </TableContainer>
  )
}

const ManualEntryButton = styled(ActionButton)`
  background-color: ${primaryBackgroundColor};
  border: 0.125rem solid ${borderColor};
  border-radius: 0.25rem;
  height: 2rem;
  text-align: center;
`

const CenteredListRowValue = styled(ListRowValue)`
  align-items: center;
`

const DrugNameWithWarning = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.25rem;
`

export default DisplayFillPageV2
