import { LaunchDarkly } from '@truepill/tpos-types'
import config from 'config'
import { useFlags as useLDFlags, LDProvider, useLDClient, useLDClientError } from 'launchdarkly-react-client-sdk'
import type { ProviderConfig } from 'launchdarkly-react-client-sdk'
import type { ChildProps } from 'types'

export const useFlag = (flag: LaunchDarkly.FeatureFlags) => {
  const flags = useLDFlags()
  return flag in flags && flags[flag]
}

export const useFlags = useLDFlags

export const useClient = () => {
  const client = useLDClient()
  const error = useLDClientError()
  return { client, error }
}

const { clientSideID } = config.launchDarkly
const providerConfig: ProviderConfig = {
  clientSideID,
  options: {
    bootstrap: 'localStorage',
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
  flags: {
    [LaunchDarkly.FeatureFlags.ALLOW_ERX_REJECTION_FROM_CUSTOMER_UNASSIGNED]: false,
    [LaunchDarkly.FeatureFlags.ALLOW_ERX_REJECTION_FROM_PATIENT_MATCH]: false,
    [LaunchDarkly.FeatureFlags.ALLOW_LINEAR_BARCODE_SCANNING]: false,
    [LaunchDarkly.FeatureFlags.COVER_MY_MEDS_PRIOR_AUTH]: false,
    [LaunchDarkly.FeatureFlags.DECOUPLE_CRITICAL_NOTES]: false,
    [LaunchDarkly.FeatureFlags.DISPLAY_COLLECTED_COPAY_IN_FRONTEND]: false,
    [LaunchDarkly.FeatureFlags.DISPLAY_NEW_COPAY_CHECK_BUTTON]: false,
    [LaunchDarkly.FeatureFlags.ENABLE_LOT_ACCURACY_MODAL_GTIN_SCANNING]: false,
    [LaunchDarkly.FeatureFlags.ENABLE_NEW_PRESCRIPTION_CREATE_V2]: false,
    [LaunchDarkly.FeatureFlags.ENABLE_PACKING_ACCURACY_SCANNING]: false,
    [LaunchDarkly.FeatureFlags.ENABLE_PACKING_MATERIALS_ACCURACY_SCANNING]: false,
    [LaunchDarkly.FeatureFlags.ENABLE_REQUEST_CLARIFICATION_BUTTON]: false,
    [LaunchDarkly.FeatureFlags.REMOVE_PRESCRIPTION_AND_HARDCOPY_FROM_FILL_PAGE]: false,
    [LaunchDarkly.FeatureFlags.REQUIRE_USER_TO_OUTBOUND_SCAN_DURING_PACKING]: false,
    [LaunchDarkly.FeatureFlags.REQUIRE_USER_TO_SCAN_DATAMATRIX_DURING_FILL]: false,
    [LaunchDarkly.FeatureFlags.STREAMLINE_TRIAGE_AND_REJECTION_REASONS]: false,
    [LaunchDarkly.FeatureFlags.TEMP_ADD_OTC_RX_LABEL_TO_QUEUES]: false,
    [LaunchDarkly.FeatureFlags.TEMP_DISPLAY_PACK_SIZE_WITH_NDC]: false,
    [LaunchDarkly.FeatureFlags.TEMP_DUPLICATE_RX_MODAL]: false,
    [LaunchDarkly.FeatureFlags.TEMP_DUR_TOKEN_MATCHING]: false,
    [LaunchDarkly.FeatureFlags.TEMP_ENHANCE_PATIENT_SEARCH]: false,
    [LaunchDarkly.FeatureFlags.TEMP_FILTERS_RESET_PAGINATION]: false,
    [LaunchDarkly.FeatureFlags.TEMP_GET_FULFILLMENT_ORDER_HINT]: false,
    [LaunchDarkly.FeatureFlags.TEMP_HIDE_CONSENT_ON_PV1]: false,
    [LaunchDarkly.FeatureFlags.TEMP_HIDE_REFILLS_WRITEEN_FIELD_RX_REVIEW]: false,
    [LaunchDarkly.FeatureFlags.TEMP_HIGHLIGHT_PV1_DIFFERENCES]: false,
    [LaunchDarkly.FeatureFlags.TEMP_HYPHENATE_NDCS]: false,
    [LaunchDarkly.FeatureFlags.TEMP_OTC_ADJUDICATION_HIDE_RX_COMPONENT]: false,
    [LaunchDarkly.FeatureFlags.TEMP_SUPPORT_CSA_NOTES]: false,
    [LaunchDarkly.FeatureFlags.TPOS_DASHBOARD_DESIGN_V2]: false,
    [LaunchDarkly.FeatureFlags.TEMP_USE_GRAPHQL_QUERY_IN_COMPONENT_FILE]: false,
    [LaunchDarkly.FeatureFlags.USE_COPAY_PAYMENT_VALIDATION]: false,
  },
}

const LaunchDarklyProvider = ({ children }: ChildProps): JSX.Element => (
  <LDProvider {...providerConfig}>{children}</LDProvider>
)
export default LaunchDarklyProvider
