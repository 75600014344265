import type { FC } from 'react'
import { useHistory, useQuery } from '@truepill/tpos-react-router'
import { OrderStatus, RxFillRequestStatus, UserRoles, LaunchDarkly } from '@truepill/tpos-types'
import AuthLimited from 'components/AuthLimited'
import {
  GET_PATIENT_MATCH_COUNT,
  GET_ESCRIPT_INTAKE_ISSUES_COUNT,
  GET_ESCRIPT_UNASSIGNED_COUNT,
  GET_COPAY_COUNT,
  GET_ORDER_PRIOR_AUTHORIZATION_COUNT,
} from 'gql'
import { Box } from 'grommet'
import type { PartialRxFillRequestStatus } from 'hooks/useFulfillmentQueue'
import {
  FulfillmentQueueName,
  RXFillRequestStatusToFulfillmentQueueName,
  OrderStatusToFulfillmentQueueName,
} from 'hooks/useFulfillmentQueue'
import useSetPageTitle from 'hooks/useSetPageTitle'
import { TaskQueueName, TASK_QUEUES } from 'hooks/useTaskQueue'
import TruncatedUserList from 'pages/fulfillment/components/TruncatedUserList'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { goToViewPharmacyEscripts, goToViewRxIntakeIssues, goToTaskQueue } from 'routes'
import styled, { css } from 'styled-components'
import {
  alertRed,
  contrastBackgroundColor,
  primaryBackgroundColor,
  primaryColor,
  subduedColor,
  warningYellow,
} from 'styles/styleVariables'

interface StatWithAging {
  total: number | null
  between24And48Hours: number | null
  greaterThan48Hours: number | null
}

interface Props {
  lastFetchTime: string
  fillsByStatus: {
    PV1: StatWithAging | null
    Fill: StatWithAging | null
    Automation: StatWithAging | null
    PV2: StatWithAging | null
    Adjudication: StatWithAging | null
    PriorAuthorization: StatWithAging | null
  } | null
  triage: StatWithAging | null
  packing: StatWithAging | null
  otcOnly: StatWithAging | null
}

const QueuesSummary: FC<Props> = ({ lastFetchTime, fillsByStatus, packing, otcOnly, triage }) => {
  useSetPageTitle('Fulfillment Summary')
  const displayDashboardDesignV2: boolean = useFlag(LaunchDarkly.FeatureFlags.TPOS_DASHBOARD_DESIGN_V2) ?? false

  return (
    <StyledQueuesSummary margin={{ top: '-1rem' }} pad="0rem 1.875rem 1.875rem" width={{ max: '80rem' }}>
      <StyledH2>Queues</StyledH2>
      <TimeStamp>Since {lastFetchTime}</TimeStamp>
      <StyledFillsSummary direction="row" height="11.25rem" width="100%">
        <TriageQueueStatusCard stats={triage} />
        <QueueStatusCard status={RxFillRequestStatus.PV1} stats={fillsByStatus?.PV1 ?? null} />
        <QueueStatusCard status={RxFillRequestStatus.Automation} stats={fillsByStatus?.Automation ?? null} />
        <QueueStatusCard status={RxFillRequestStatus.Fill} stats={fillsByStatus?.Fill ?? null} />
        <QueueStatusCard status={RxFillRequestStatus.PV2} stats={fillsByStatus?.PV2 ?? null} />
        <PackingStatusCard stats={packing} />
      </StyledFillsSummary>
      {/*Display new dashboard design for fulfillment tasks if flag is on */}
      {displayDashboardDesignV2 ? (
        <>
          <StyledH2>Fulfillment tasks</StyledH2>
          <TimeStamp>Since {lastFetchTime}</TimeStamp>
          <StyledFillsSummary direction="row" height="11.25rem" width="100%">
            <QueueStatusCard status={RxFillRequestStatus.Adjudication} stats={fillsByStatus?.Adjudication ?? null} />
            <PriorAuthorizationStatusCard />
            <CopayCard />
            <AuthLimited
              roles={[
                UserRoles.Pharmacist,
                UserRoles.LeadPharmacist,
                UserRoles.Admin,
                UserRoles.Developer,
                UserRoles.Technician,
                UserRoles.Warehouse,
              ]}
            >
              <OtcOnlyStatusCard stats={otcOnly} />
            </AuthLimited>
          </StyledFillsSummary>
        </>
      ) : (
        <>
          <StyledH2>Triage</StyledH2>
          <TimeStamp>Since {lastFetchTime}</TimeStamp>
          <StyledFillsSummary direction="row" height="11.25rem" width="100%">
            <QueueStatusCard status={RxFillRequestStatus.Adjudication} stats={fillsByStatus?.Adjudication ?? null} />
            <PriorAuthorizationStatusCard />
            <QueueStatusCard
              status={RxFillRequestStatus.PriorAuthorization}
              display={'PA (Orders)'}
              stats={fillsByStatus?.PriorAuthorization ?? null}
            />
            <EscriptStatusCard />
            <RxIntakeExceptionsStatusCard />
          </StyledFillsSummary>
        </>
      )}
      {/*Display new dashboard design for Rx tasks if flag is on */}
      {displayDashboardDesignV2 ? (
        <AuthLimited
          roles={[
            UserRoles.Pharmacist,
            UserRoles.LeadPharmacist,
            UserRoles.Admin,
            UserRoles.Developer,
            UserRoles.Technician,
            UserRoles.Warehouse,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
        >
          <StyledH2>Rx tasks</StyledH2>
          <TimeStamp>Since {lastFetchTime}</TimeStamp>
          <StyledFillsSummary direction="row" height="11.25rem" width="100%">
            <IntakeExceptionsStatusCard stats={otcOnly} />
            <RxRenewalsStatusCard />
            <TransferOutRxStatusCard />
          </StyledFillsSummary>
        </AuthLimited>
      ) : (
        <AuthLimited
          roles={[
            UserRoles.Pharmacist,
            UserRoles.LeadPharmacist,
            UserRoles.Admin,
            UserRoles.Developer,
            UserRoles.Technician,
            UserRoles.Warehouse,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
        >
          <StyledH2>Tasks</StyledH2>
          <TimeStamp>Since {lastFetchTime}</TimeStamp>
          <StyledFillsSummary direction="row" height="11.25rem" width="100%">
            <AuthLimited
              roles={[
                UserRoles.Pharmacist,
                UserRoles.LeadPharmacist,
                UserRoles.Admin,
                UserRoles.Developer,
                UserRoles.Technician,
                UserRoles.Warehouse,
              ]}
            >
              <PatientMatchStatusCard />
              <OtcOnlyStatusCard stats={otcOnly} />
            </AuthLimited>
            <CopayCard />
          </StyledFillsSummary>
        </AuthLimited>
      )}
    </StyledQueuesSummary>
  )
}

const StyledQueuesSummary = styled(Box)`
  margin-top: -1rem;
  padding: 0rem 1.875rem 1.875rem;
  max-width: 80rem;
`

const StyledFillsSummary = styled(Box)`
  flex-direction: row;
  height: 11.25rem;
  width: 100%;
`

const PatientMatchStatusCard = (): JSX.Element => {
  const history = useHistory()

  const { data: patientMatchCountData } = useQuery(GET_PATIENT_MATCH_COUNT)
  const patientMatchCount = patientMatchCountData?.getPatientMatchCount
  const displayDashboardDesignV2: boolean = useFlag(LaunchDarkly.FeatureFlags.TPOS_DASHBOARD_DESIGN_V2) ?? false

  return displayDashboardDesignV2 ? (
    <InnerStatusCard onClick={() => history.push(goToTaskQueue({ taskQueueName: TaskQueueName.PatientMatch }))}>
      <TitleRow>
        <h4 style={{ textTransform: 'initial' }}>PT MATCH</h4>
        <FillStatusSummaryData isNewDesign>{patientMatchCount ?? 0}</FillStatusSummaryData>
      </TitleRow>
    </InnerStatusCard>
  ) : (
    <StatusCard onClick={() => history.push(goToTaskQueue({ taskQueueName: TaskQueueName.PatientMatch }))}>
      <TitleRow>
        <h4 style={{ textTransform: 'initial' }}>{TASK_QUEUES[TaskQueueName.PatientMatch].title}</h4>
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{patientMatchCount ?? 0}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </StatusCard>
  )
}

const IntakeExceptionsStatusCard: FC<{ stats: StatWithAging | null }> = ({ stats }): JSX.Element => {
  return (
    <StatusCard data-testid="IntakeException" isNewDesign>
      <TitleRow>
        <h4>Intake exceptions</h4>
      </TitleRow>
      <FillStatusSummaryList>
        <AuthLimited
          roles={[
            UserRoles.Pharmacist,
            UserRoles.LeadPharmacist,
            UserRoles.Admin,
            UserRoles.Developer,
            UserRoles.Technician,
            UserRoles.Warehouse,
          ]}
        >
          <FillStatusSummary isNewDesign>
            <PatientMatchStatusCard />
          </FillStatusSummary>
        </AuthLimited>
        <FillStatusSummary isNewDesign>
          <EscriptStatusCard />
        </FillStatusSummary>
        <FillStatusSummary isNewDesign>
          <RxIntakeExceptionsStatusCard />
        </FillStatusSummary>
      </FillStatusSummaryList>
    </StatusCard>
  )
}

const OtcOnlyStatusCard: FC<{ stats: StatWithAging | null }> = ({ stats }): JSX.Element => {
  const {
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()

  const total = stats?.total ?? '-'
  const nearlyOverdue = stats?.between24And48Hours ?? '-'
  const overdue = stats?.greaterThan48Hours ?? '-'

  return (
    <StatusCard
      data-testid="OtcOnly"
      onClick={() => {
        routeTo
          .fulfillmentQueue(FulfillmentQueueName.Packing, {
            searchMap: {
              otcOnly: true,
              ...getSearchMapVars(queryMap, tokenContext?.locationId),
            },
          })
          .now()
      }}
    >
      <TitleRow>
        <h4>OTC only</h4>
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{total}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={alertRed} />
          <FillStatusSummaryData>{overdue}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={warningYellow} />
          <FillStatusSummaryData>{nearlyOverdue}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </StatusCard>
  )
}

const EscriptStatusCard = (): JSX.Element => {
  const history = useHistory()

  const { data: escriptCountData } = useQuery(GET_ESCRIPT_UNASSIGNED_COUNT)
  const escriptUnnasignedCount = escriptCountData?.getEscriptUnassignedCount
  const displayDashboardDesignV2: boolean = useFlag(LaunchDarkly.FeatureFlags.TPOS_DASHBOARD_DESIGN_V2) ?? false

  return displayDashboardDesignV2 ? (
    <InnerStatusCard onClick={() => history.push(goToViewPharmacyEscripts({ search: '?inTriage=true' }))}>
      <TitleRow>
        <h4 style={{ textTransform: 'initial' }}>UNASSIGNED</h4>
        <FillStatusSummaryData isNewDesign>{escriptUnnasignedCount}</FillStatusSummaryData>
      </TitleRow>
    </InnerStatusCard>
  ) : (
    <SingleStatusCard onClick={() => history.push(goToViewPharmacyEscripts({ search: '?inTriage=true' }))}>
      <TitleRow>
        <h4 style={{ textTransform: 'initial' }}>Unassigned eRx</h4>
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{escriptUnnasignedCount}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </SingleStatusCard>
  )
}

const RxIntakeExceptionsStatusCard = (): JSX.Element => {
  const history = useHistory()

  const { data: escriptCountData } = useQuery(GET_ESCRIPT_INTAKE_ISSUES_COUNT)
  const escriptIntakeIssuesCount = escriptCountData?.getEscriptIntakeIssuesCount
  const displayDashboardDesignV2: boolean = useFlag(LaunchDarkly.FeatureFlags.TPOS_DASHBOARD_DESIGN_V2) ?? false

  return displayDashboardDesignV2 ? (
    <InnerStatusCard onClick={() => history.push(goToViewRxIntakeIssues({ search: '' }))}>
      <TitleRow>
        <h4 style={{ textTransform: 'initial' }}>MISC</h4>
        <FillStatusSummaryData isNewDesign>{escriptIntakeIssuesCount}</FillStatusSummaryData>
      </TitleRow>
    </InnerStatusCard>
  ) : (
    <SingleStatusCard onClick={() => history.push(goToViewRxIntakeIssues({ search: '' }))}>
      <TitleRow>
        <h4 style={{ textTransform: 'initial' }}>Rx Intake Exceptions</h4>
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{escriptIntakeIssuesCount}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </SingleStatusCard>
  )
}

//JR-17175: Create placeholder in design for Transfer out and Rx renewals cards under ‘Rx tasks’ third row
//Implementation of the logic for these cards will be done in a separate ticket
const TransferOutRxStatusCard = (): JSX.Element => {
  return (
    <SingleStatusCard onClick={() => console.debug('this will navigate to Transfer out page')}>
      <TitleRow>
        <h4 style={{ textTransform: 'initial' }}>Transfer Out</h4>
      </TitleRow>
      {/* TODO: Add remaining tags and text for this component */}
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>-</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </SingleStatusCard>
  )
}

const RxRenewalsStatusCard = (): JSX.Element => {
  return (
    <SingleStatusCard onClick={() => console.debug('this will navigate to Rx renewals page')}>
      <TitleRow>
        <h4 style={{ textTransform: 'initial' }}>Rx Renewals</h4>
      </TitleRow>
      {/* TODO: Add remaining tags and text for this component */}
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>-</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </SingleStatusCard>
  )
}

const PackingStatusCard: FC<{ stats: StatWithAging | null }> = ({ stats }) => {
  const {
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()

  const total = stats?.total ?? '-'
  const nearlyOverdue = stats?.between24And48Hours ?? '-'
  const overdue = stats?.greaterThan48Hours ?? '-'

  return (
    <StatusCard
      onClick={() => {
        routeTo
          .fulfillmentQueue(OrderStatusToFulfillmentQueueName[OrderStatus.Packing], {
            searchMap: getSearchMapVars(queryMap, tokenContext?.locationId),
          })
          .now()
      }}
    >
      <TitleRow>
        <h4>Packing</h4>
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{total}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={alertRed} />
          <FillStatusSummaryData>{overdue}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={warningYellow} />
          <FillStatusSummaryData>{nearlyOverdue}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </StatusCard>
  )
}

const CopayCard = (): JSX.Element => {
  const {
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()

  const queueName = 'Copay'

  const { data: copayCountData } = useQuery(GET_COPAY_COUNT, {
    variables: {
      locationId: queryMap.locationId,
    },
  })

  const copayCount = copayCountData?.getCopayCount

  return (
    <StatusCard
      data-testid={queueName}
      onClick={() => {
        routeTo
          .fulfillmentQueue(queueName, {
            searchMap: getSearchMapVars(queryMap, tokenContext?.locationId),
          })
          .now()
      }}
    >
      <TitleRow>
        <NoWrapH4>Copay Check</NoWrapH4>
        <TruncatedUserList items={[]} />
      </TitleRow>
      {/* TODO: Add remaining tags and text for this component */}
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{copayCount}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </StatusCard>
  )
}

const QueueStatusCard: FC<{ status: PartialRxFillRequestStatus; display?: string; stats: StatWithAging | null }> = ({
  status,
  display,
  stats,
}) => {
  const {
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()

  const total = stats?.total ?? '-'
  const nearlyOverdue = stats?.between24And48Hours ?? '-'
  const overdue = stats?.greaterThan48Hours ?? '-'

  const queueName = RXFillRequestStatusToFulfillmentQueueName[status]

  return (
    <StatusCard
      data-testid={queueName}
      onClick={() => {
        routeTo
          .fulfillmentQueue(queueName, {
            searchMap: getSearchMapVars(queryMap, tokenContext?.locationId),
          })
          .now()
      }}
    >
      <TitleRow>
        <NoWrapH4>{display ?? queueName}</NoWrapH4>
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{total}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={alertRed} />
          <FillStatusSummaryData>{overdue}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={warningYellow} />
          <FillStatusSummaryData>{nearlyOverdue}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </StatusCard>
  )
}

const TriageQueueStatusCard: FC<{ stats: StatWithAging | null }> = ({ stats }) => {
  const {
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()

  const total = stats?.total ?? '-'
  const nearlyOverdue = stats?.between24And48Hours ?? '-'
  const overdue = stats?.greaterThan48Hours ?? '-'

  return (
    <StatusCard
      onClick={() => {
        routeTo
          .fulfillmentQueue(FulfillmentQueueName.Triage, {
            searchMap: getSearchMapVars(queryMap, tokenContext?.locationId),
          })
          .now()
      }}
    >
      <TitleRow>
        <h4>Triage</h4>
        <TruncatedUserList items={[]} />
      </TitleRow>
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{total}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={alertRed} />
          <FillStatusSummaryData>{overdue}</FillStatusSummaryData>
        </FillStatusSummary>
        <FillStatusSummary>
          <FillStatusTag tagColor={warningYellow} />
          <FillStatusSummaryData>{nearlyOverdue}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </StatusCard>
  )
}

const PriorAuthorizationStatusCard = (): JSX.Element => {
  const {
    routeTo,
    tokenContext,
    currentLocation: { queryMap },
  } = usePlusClient()

  const { data } = useQuery(GET_ORDER_PRIOR_AUTHORIZATION_COUNT, {
    variables: {
      locationId: queryMap.locationId,
    },
  })

  const priorAuthorizationCount = data?.getOrderPriorAuthorizationCount

  return (
    <SingleStatusCard
      onClick={() => {
        routeTo
          .fulfillmentQueue(FulfillmentQueueName.PriorAuthorizationNew, {
            searchMap: getSearchMapVars(queryMap, tokenContext?.locationId),
          })
          .now()
      }}
    >
      <TitleRow>
        <h4>Prior Auth</h4>
        <TruncatedUserList items={[]} />
      </TitleRow>
      {/* TODO: Add remaining tags and text for this component */}
      <FillStatusSummaryList>
        <FillStatusSummary>
          <FillStatusTag tagColor={primaryColor} />
          <FillStatusSummaryData>{priorAuthorizationCount}</FillStatusSummaryData>
        </FillStatusSummary>
      </FillStatusSummaryList>
    </SingleStatusCard>
  )
}

interface NewCardDesignProps {
  isNewDesign?: boolean
}

const TitleRow = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  > h4 {
    font-size: 0.875rem;
    margin-top: 0rem;
    text-transform: capitalize;
  }
`

const BaseFillStatusSummary = styled.ul`
  margin-top: 0.3125rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`

const FillStatusSummary = styled(BaseFillStatusSummary)<NewCardDesignProps>`
  margin-bottom: ${props => (props.isNewDesign ? '1rem' : '0.3125rem')};
  justify-content: ${props => (props.isNewDesign ? 'center' : 'flex-start')};
  height: ${props => (props.isNewDesign ? '1.5rem' : '2.25rem')};
`

const BaseFillStatusSummaryData = styled.li`
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  align-items: center;
  font-weight: 500;
`

const FillStatusSummaryData = styled(BaseFillStatusSummaryData)<NewCardDesignProps>`
  justify-content: ${props => (props.isNewDesign ? 'flex-end' : 'center')};
  border: 0.25rem solid;
  border-radius: 0rem 0.25rem 0.25rem 0rem;
  border-color: ${primaryBackgroundColor};
  background-color: ${primaryBackgroundColor};
  font-size: ${props => (props.isNewDesign ? '1.2rem' : '1.3125rem')};
`

const FillStatusTag = styled.li<{ tagColor: string }>`
  border-left: 0.25rem solid;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
  border-left-color: ${({ tagColor }) => tagColor};
  width: 0rem;
`

const FillStatusSummaryList = styled.ul`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-grow: 1;
  margin-top: 0.5rem;
`

const StyledH2 = styled.h2`
  margin-top: 1.75rem;
`

const Card = styled.div`
  position: relative;
  width: 100%;
  height: 11.25rem;
  display: grid;
  grid-template-rows: 0.5fr 0.5fr;
  grid-template-columns: 1fr;
  padding: 0.625rem;
  background-color: ${contrastBackgroundColor};
  border-radius: 0.25rem;
  :not(:first-of-type) {
    margin-left: 0.65rem;
  }
  :not(:last-of-type) {
    margin-right: 0.625rem;
  }
  h4 {
    font-weight: 500;
  }
  > h4 {
    grid-row: 1;
    grid-column: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-size: 1.125rem;
  }
`

const StatusCard = styled(Card)<NewCardDesignProps>`
  display: flex;
  width: ${props => (props.isNewDesign ? '13rem' : '20rem')};
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: 1rem;
  padding-bottom: 1rem;
  height: 12rem;
`

const InnerStatusCard = styled(Card)`
  border-color: ${primaryBackgroundColor};
  background-color: ${primaryBackgroundColor};
  display: flex;
  width: 13rem;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: 2rem;
`

const SingleStatusCard = styled(StatusCard)`
  height: fit-content;
`

const TimeStamp = styled.p`
  color: ${subduedColor};
  margin-bottom: 0.625rem;
`

const NoWrapH4 = styled.h4`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const getSearchMapVars = (
  queryMap: { states?: string[]; customerIds?: string[]; noStates?: boolean; locationId?: string },
  defaultLocationId?: string,
) => {
  return {
    ...(queryMap.states?.length && { states: queryMap?.states }),
    ...(queryMap.customerIds?.length && { customerIds: queryMap.customerIds }),
    ...(queryMap.noStates && { states: [] }),
    locationId: queryMap.locationId || defaultLocationId,
  }
}

export default QueuesSummary
