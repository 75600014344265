import { useCallback, useEffect, useState } from 'react'
import { Button } from '@truepill/react-capsule'
import { useMutation, useQuery } from '@truepill/tpos-react-router'
import { PrinterPurpose, RxFillRequestStatus, LaunchDarkly, OutboundScanType } from '@truepill/tpos-types'
import { StyledTaskEntry } from 'components/TaskList'
import { Badge } from 'components/UserInitialsBadge'
import { GET_OTC_BY_SKUS, GET_PACKING_MATERIALS_BY_SKUS, CREATE_LOG } from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useInfoToast from 'hooks/toast/useInfoToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import useAutoUpdatingRef from 'hooks/useAutoUpdatingRef'
import useBarcodeScanner, { REGEXES } from 'hooks/useBarcodeScanner'
import useDevToolBarcode from 'hooks/useDevtoolBarcode'
import PrintShippingLabelModal from 'modals/PrintShippingLabelModal'
import { SEND_OUTBOUND_SCAN } from 'pages/PharmacyPage/PharmacyOutboundScan/PharmacyOutboundScanPage'
import { useClient, useFlag } from 'providers/LaunchDarklyProvider'
import { usePrintProvider } from 'providers/PrintProvider'
import type { Task } from 'providers/TaskProvider'
import { useTaskContext } from 'providers/TaskProvider'
import { useTPCacheContext } from 'providers/TPCacheProvider'
import styled from 'styled-components'
import { capsulePrimaryColorDark } from 'styles/styleVariables'
import type { OTCProduct, Order, RXFillRequest, Otc, PackingMaterial, PackingMaterialItem } from 'types'
import { formatDate } from 'utils/dates'
import { isLabelPrinted } from '../../utils'

type Instruction = {
  instructionText: string
  rightButtonText?: string
  rightButtonHandler?: () => void
  scannedLabel?: string
  printerStatuses?: { purpose: string; selection: string | null; ok: boolean }[]
}

const generateTasks = ({
  rxFillRequests,
  otcProducts,
  otcs,
  packingMaterialItems,
  packingMaterials,
  isLabelPrinted,
  existingTasks,
}: {
  rxFillRequests: RXFillRequest[]
  otcProducts?: OTCProduct[]
  otcs?: Otc[]
  packingMaterialItems?: PackingMaterialItem[]
  packingMaterials?: PackingMaterial[]
  isLabelPrinted?: boolean
  existingTasks?: Task[]
}): Task[] => {
  const tasks: Task[] = []

  if (otcProducts && otcProducts.length > 0) {
    otcProducts.forEach(otcProduct => {
      if (!otcProduct.overrideScan) {
        const otc = otcs?.find(otc => otc.sku === otcProduct.sku && otc.upc.length > 0)
        if (otc) {
          const scansNeeded = otcProduct.quantity / otc.quantity
          const scansNumberNotValid = !Number.isInteger(scansNeeded)

          if (scansNumberNotValid) return

          for (let index = 0; index < scansNeeded; index++) {
            tasks.push({
              label: `${otcProduct.sku}`,
              key: `${otcProduct._id}-${index}`,
              completed: existingTasks?.find(({ key }) => key === `${otcProduct._id}-${index}`)?.completed || false,
              code: otcProduct.sku,
              productType: 'OTC',
              additionalData: { upcOptions: otc.upc },
            })
          }
        }
      }
    })
  }

  if (rxFillRequests && rxFillRequests.some(({ fill }) => fill.status === 'Complete')) {
    rxFillRequests
      .filter(fillRequest => fillRequest.status !== RxFillRequestStatus.Cancelled)
      .forEach(({ fill }) =>
        // Can't have an rxFillRequest on an order in Packing without an active Fill
        tasks.push({
          label: `Scan Rx label bar code for ${fill.rxFillCode}`,
          key: fill.shortFillCode,
          completed: isLabelPrinted ?? false,
          code: fill.shortFillCode,
          productType: 'RX',
        }),
      )
  }

  // Create tasks based on the quantiy for each packing material item that has a UPC
  if (packingMaterials && packingMaterials.length > 0 && packingMaterialItems && packingMaterialItems.length > 0) {
    packingMaterialItems.forEach(packingMaterialItem => {
      const packingMaterial = packingMaterials?.find(pm => pm.sku === packingMaterialItem.sku && pm.upc.length > 0)
      // If the packing material item has an overrideScan flag, skip it
      if (packingMaterial && !packingMaterialItem.overrideScan) {
        const scansNeeded = packingMaterialItem.quantity / packingMaterial.quantity
        const scansNumberNotValid = !Number.isInteger(scansNeeded)

        if (scansNumberNotValid) return

        for (let index = 0; index < scansNeeded; index++) {
          tasks.push({
            label: `${packingMaterial.itemName}`,
            key: `${packingMaterialItem._id}-${index}`,
            completed:
              existingTasks?.find(({ key }) => key === `${packingMaterialItem._id}-${index}`)?.completed || false,
            code: packingMaterialItem.sku,
            productType: 'PM',
            additionalData: { upcOptions: packingMaterial.upc },
          })
        }
      }
    })
  }
  return tasks
}

type PackingTasksProps = { order: Order; trackingNumber?: string }

const PackingTasks = ({ order, trackingNumber }: PackingTasksProps): JSX.Element => {
  const { initTasks, completeTask, tasks } = useTaskContext()
  const [showPrintModal, setShowPrintModal] = useState(false)
  const [instructions, setInstructions] = useState<Instruction[]>([])
  const initTasksRef = useAutoUpdatingRef(initTasks)
  const tasksRef = useAutoUpdatingRef(tasks)
  const { registerListener, deregisterListener } = useBarcodeScanner()
  const showSuccessToast = useSuccessToast(true)
  const showInfoToast = useInfoToast(true)
  const showErrorToast = useErrorToast(true)
  const hasOtcItems = !!order?.otcProducts?.length
  const hasPrescriptions = !!order?.rxFillRequests?.length
  const { getSavedPrinterId } = usePrintProvider()
  const { getPrinterById, getLocationNameById } = useTPCacheContext()
  const isOtcAdjudicationOrder = order?.rxFillRequests.every(r => !!r.otcProductId) ?? false
  const [accuracyScanning, setAccuracyScanning] = useState(
    useFlag(LaunchDarkly.FeatureFlags.ENABLE_PACKING_ACCURACY_SCANNING),
  )
  // If the feature flag shouldOutboundScan is on, then there will be a last packing task requiring outbound scan
  const [shouldOutboundScan, setShouldOutboundScan] = useState(
    useFlag(LaunchDarkly.FeatureFlags.REQUIRE_USER_TO_OUTBOUND_SCAN_DURING_PACKING),
  )
  const [createLog] = useMutation(CREATE_LOG)
  const [sendOutboundScan] = useMutation(SEND_OUTBOUND_SCAN, {
    onCompleted: data => {
      const outboundScan = data?.sendOutboundScan
      showSuccessToast(
        `Patient: ${order.patient?.firstName ?? order.guestPatient?.firstName ?? ''} ${
          order.patient?.lastName ?? order.patient?.lastName ?? ''
        }. Scan at: ${formatDate(outboundScan?.scanAt, 'MM/DD/YYYY hh:mmA')}`,
      )

      // set the scanned tracking number to display at the last instruction
      setInstructions(prevInstructions => {
        const lastInstruction = prevInstructions[prevInstructions.length - 1]
        return [
          ...prevInstructions.slice(0, prevInstructions.length - 1),
          {
            ...lastInstruction,
            scannedLabel: outboundScan?.trackingNumber,
          },
        ]
      })
    },
    onError: error => {
      showErrorToast(error?.message)
    },
  })

  // Set the customer id for upcoming feature-flag calls
  const { client: ldClient } = useClient()

  const customerLegacyId = order.customer?.legacyId
  const locationLegacyId = order.location?.legacyId

  useEffect(() => {
    if (ldClient && customerLegacyId && locationLegacyId) {
      ldClient.identify(
        {
          key: customerLegacyId.toString(),
          custom: { locationLegacyId: locationLegacyId.toString() },
        },
        undefined,
        (err, flags) => {
          if (flags) {
            setAccuracyScanning(flags[LaunchDarkly.FeatureFlags.ENABLE_PACKING_ACCURACY_SCANNING])
            setShouldOutboundScan(flags[LaunchDarkly.FeatureFlags.REQUIRE_USER_TO_OUTBOUND_SCAN_DURING_PACKING])
          }
        },
      )
    }
  }, [ldClient, customerLegacyId, locationLegacyId])

  const skus = order.otcProducts?.map(otcProduct => otcProduct.sku) || []
  const queryOTCs = accuracyScanning && order?.otcProducts?.length && order?.otcProducts?.length > 0
  const { data: otcs } = useQuery(GET_OTC_BY_SKUS, {
    variables: { customerId: order.customerId, skus },
    skip: !queryOTCs,
  })

  const enablePackingMaterialsScanning = useFlag(LaunchDarkly.FeatureFlags.ENABLE_PACKING_MATERIALS_ACCURACY_SCANNING)
  const packingSkus = order.packingMaterials?.map(packingProduct => packingProduct.sku) || []
  const queryPackingMaterials =
    enablePackingMaterialsScanning && order?.packingMaterials?.length && order?.packingMaterials?.length > 0
  const { data: packingMaterials } = useQuery(GET_PACKING_MATERIALS_BY_SKUS, {
    variables: { skus: packingSkus },
    skip: !queryPackingMaterials,
  })

  useEffect(() => {
    const ordersTasks = generateTasks({
      rxFillRequests: order.rxFillRequests,
      otcProducts: order.otcProducts,
      otcs: otcs?.getOtcBySKUS,
      packingMaterialItems: order.packingMaterials,
      packingMaterials: packingMaterials?.getPackingMaterialsBySKUS,
      isLabelPrinted: isLabelPrinted(order),
      existingTasks: tasks,
    })
    initTasksRef.current(ordersTasks)
  }, [order._id, packingMaterials, initTasksRef, otcs])

  useDevToolBarcode(tasks.map(({ label, code }) => ({ label, code })))

  const handleShortCodeScan = useCallback(
    (value: string) => {
      const rxFillRequest = order.rxFillRequests.find(rxFillRequest => rxFillRequest.fill.shortFillCode === value)
      if (rxFillRequest) {
        const fillTask = tasksRef.current.find(({ key }) => key === value)
        if (!fillTask) {
          showInfoToast('Navigating to ' + value + '.')
          return
        }
        completeTask(rxFillRequest.fill.shortFillCode)
        showSuccessToast('Scanned RX barcode')
      }
    },
    [tasksRef, completeTask, showInfoToast, showSuccessToast, order.rxFillRequests],
  )

  const handleSKUScan = useCallback(
    (value: string) => {
      try {
        const otcTask = tasksRef.current.find(({ code, completed }) => value === code && !completed)
        if (!otcTask) {
          return
        }
        showSuccessToast('Scanned product: ' + value)
        completeTask(otcTask.key)
      } catch (e: any) {
        showErrorToast(e.message)
        console.error(e)
      }
    },
    [tasksRef, completeTask, showSuccessToast, showErrorToast],
  )

  const handleUPCScan = useCallback(
    (value: string) => {
      if (!accuracyScanning && !enablePackingMaterialsScanning) {
        return
      }

      try {
        if (!value) {
          return
        }

        const task = tasksRef.current.find(
          ({ additionalData, completed }) => additionalData?.upcOptions?.includes(value) && completed === false,
        )
        if (!task) {
          showErrorToast('Incorrect product/stock (UPC) barcode scanned.')
          return
        }
        showSuccessToast('Scanned product: ' + task.label + ', UPC:' + value)
        task.additionalData = {
          upcScanned: value,
          upcOptions: task.additionalData?.upcOptions || [],
        }
        completeTask(task.key)

        if (task.productType === 'PM') {
          createLog({
            variables: {
              orderId: order._id,
              message: `Packing item ${task.code} scanned with UPC ${value}`,
              event: 'scannedPackingMaterial',
            },
          })
        }
      } catch (e: any) {
        showErrorToast(e.message)
      }
    },
    [accuracyScanning, enablePackingMaterialsScanning, tasksRef, showSuccessToast, completeTask, showErrorToast],
  )

  const handleShippingLabelScan = useCallback(
    (value: string) => {
      if (!trackingNumber) {
        showErrorToast('No shipping label available to scan.')
        return
      }

      // the scanned value could contain the tracking number at the end
      if (value !== trackingNumber && !value.endsWith(trackingNumber)) {
        showErrorToast('Incorrect shipping label scanned, check order.')
        return
      }

      showSuccessToast('Scanned shipping label: ' + value)

      sendOutboundScan({
        variables: { trackingNumber, outboundScanType: OutboundScanType.fulfillment },
        refetchQueries: ['getFullOrder'],
      })
    },
    [showSuccessToast, showErrorToast, sendOutboundScan, trackingNumber],
  )

  const hasIncompleteTasks = tasksRef.current.some(({ completed }) => !completed)

  useEffect(() => {
    registerListener(REGEXES.SKU, handleSKUScan)
    registerListener(REGEXES.ShortFillCode, handleShortCodeScan)
    registerListener(REGEXES.UPC, handleUPCScan)
    if (shouldOutboundScan && !hasIncompleteTasks) registerListener(REGEXES.ShippingLabel, handleShippingLabelScan)
    return () => {
      deregisterListener(REGEXES.SKU, handleSKUScan)
      deregisterListener(REGEXES.ShortFillCode, handleShortCodeScan)
      deregisterListener(REGEXES.UPC, handleUPCScan)
      if (shouldOutboundScan) deregisterListener(REGEXES.ShippingLabel, handleShippingLabelScan)
    }
  }, [accuracyScanning, enablePackingMaterialsScanning, shouldOutboundScan, hasIncompleteTasks])

  const shippingLabelPrinterId = getSavedPrinterId(PrinterPurpose.ShippingLabel)
  const shippingLabelPrinter = shippingLabelPrinterId ? getPrinterById(shippingLabelPrinterId) : null

  useEffect(() => {
    const newInstructions = [
      ...(hasPrescriptions && !isOtcAdjudicationOrder
        ? [
            {
              instructionText: 'Pack prescription items',
            },
          ]
        : []),
      ...(hasOtcItems
        ? [
            {
              instructionText: 'Pack OTC items',
            },
          ]
        : []),
      {
        instructionText: 'Pack any inserts',
      },
      {
        instructionText: 'Print shipping label',
        rightButtonText: 'Print setting',
        rightButtonHandler: () => setShowPrintModal(true),
        printerStatuses: [
          {
            purpose: 'Shipping label',
            selection: shippingLabelPrinter
              ? `${shippingLabelPrinter.printerName} - ${getLocationNameById(shippingLabelPrinter.locationId)}`
              : null,
            ok: !!shippingLabelPrinterId,
          },
        ],
      },
      ...(shouldOutboundScan
        ? [
            {
              instructionText: 'Outbound scan',
              scannedLabel: '',
            },
          ]
        : []),
    ]
    setInstructions(newInstructions)
  }, [shouldOutboundScan, hasPrescriptions, hasOtcItems, isOtcAdjudicationOrder, shippingLabelPrinterId])

  return (
    <>
      <PrintShippingLabelModal isOpen={showPrintModal} setIsOpen={setShowPrintModal} order={order} />
      <TaskListContainer>
        {instructions.map((instruction, index) => (
          <>
            <PresetTask
              key={index}
              lineExtension={instructions[index - 1] ? instructions[index - 1].printerStatuses?.length || 0 : 0}
            >
              <BaseBadge label={`${index + 1}`} />
              <p>{instruction.instructionText}</p>
              {instruction.rightButtonHandler && instruction.rightButtonText && (
                <Button variant="primary-text" onClick={instruction.rightButtonHandler}>
                  {instruction.rightButtonText}
                </Button>
              )}
            </PresetTask>
            {instruction.printerStatuses?.map(({ purpose, selection, ok }) => {
              return (
                <PrinterStatus>
                  <p>
                    {`${purpose}: `}
                    <PrinterStatusSelection ok={ok}>{selection || 'Needs selection'}</PrinterStatusSelection>
                  </p>
                </PrinterStatus>
              )
            })}
            {instruction?.scannedLabel && (
              <PrinterStatus>
                <p>{`Label: ${instruction.scannedLabel}`}</p>
              </PrinterStatus>
            )}
          </>
        ))}
      </TaskListContainer>
    </>
  )
}

export default PackingTasks

const PresetTask = styled(StyledTaskEntry)<{ lineExtension: number }>`
  margin-top: 0.625rem;
  position: relative;
  > span {
    font-size: 0.875rem;
  }
  > p {
    margin-left: 0.5rem;
  }
  > button {
    font-size: 14px;
    text-decoration: underline;
    margin-left: 1rem;
    padding: 0;
  }
  :not(:first-child) {
    :after {
      content: '';
      position: absolute;
      left: 15px;
      bottom: 32px;
      height: calc(0.75em + ${props => (props.lineExtension * 1.5).toString()}rem);
      border: 1px solid ${capsulePrimaryColorDark};
    }
  }
`

const PrinterStatus = styled.p`
  color: #535762;
  font-size: 14px;
  margin-left: calc(32px + 0.5rem);
`
const PrinterStatusSelection = styled.span<{ ok: boolean }>`
  color: ${props => (props.ok ? '#535762' : '#E81A33')};
`

const TaskListContainer = styled.div`
  margin-top: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
`

const BaseBadge = styled(Badge)`
  margin-left: -0.0225rem;
  font-size: 16px;
  border: 2px solid ${capsulePrimaryColorDark};
  color: ${capsulePrimaryColorDark};
  width: 32px;
  background: white;
  height: 32px;
  padding: 0;
`
