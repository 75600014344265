import type { Types } from 'mongoose'
import type { PatientSurvey } from '../../truefill/fill-request'
import type {
  RxFillRequestStatus,
  RxFillRequestSpecialHandlingTag,
  RxFillRequestSpecialHandlingTagAllOrNoOptions,
} from './RxFillRequest'
import type { TriageReason } from './Triage'

export enum OrderStatus {
  PV1 = 'PV1',
  Adjudication = 'Adjudication',
  PriorAuthorization = 'PriorAuthorization',
  Fill = 'Fill',
  Automation = 'Automation',
  PV2 = 'PV2',
  Packing = 'Packing',
  PartialShipped = 'PartialShipped',
  Shipped = 'Shipped',
  Complete = 'Complete',
  Returned = 'Returned',
  Cancelled = 'Cancelled',
  Reverted = 'Reverted',
}

export enum OrderType {
  Single = 'Single',
  Batch = 'Batch',
  MultiPet = 'MultiPet',
}

export const terminalOrderStatuses = [
  OrderStatus.Shipped,
  OrderStatus.Complete,
  OrderStatus.Returned,
  OrderStatus.Cancelled,
  OrderStatus.Reverted,
]

export enum CoreOrderStatus {
  ToFill = '01',
  ToPrint = '02',
  Rejected = '03',
  Completed = '04',
  Triage = 'T',
  TPOS = 'TPOS',
}

export enum PaymentType {
  Cash = 'cash',
  Insurance = 'insurance',
  Split = 'split',
}

export interface OrderTriageQuery {
  orderStages?: Types.Array<OrderStatus>
  fillStages?: Types.Array<RxFillRequestStatus>
  locationId?: string
  locationIds?: Types.Array<string>
  reasons?: TriageReason[]
  customerIds?: Types.Array<string>
  medications?: Types.Array<string>
  isOtcOnly?: boolean
  paymentType?: string
  batchOrders?: boolean
  multiPetOrders?: boolean
  specialHandlingTags?: (RxFillRequestSpecialHandlingTag | RxFillRequestSpecialHandlingTagAllOrNoOptions)[]
  states?: string[]
}

export interface OrderFilters {
  selectedLocationIds?: Types.Array<string>
  customerIds?: Types.Array<string>
  ndcs?: Types.Array<string>
  medicationNames?: Types.Array<string>
  locationId?: string
  paymentType?: PaymentType
  rejectCodes?: Types.Array<string>
  rejectCodeCategories?: Types.Array<string>
  batchOrders?: boolean
  multiPetOrders?: boolean
  includeTriage?: boolean
  specialHandlingTags?: (RxFillRequestSpecialHandlingTag | RxFillRequestSpecialHandlingTagAllOrNoOptions)[]
  states?: string[]
}

export interface CoreOrderFixtureOptions {
  patientSurvey: PatientSurvey
}

export interface FixtureOrderOptions {
  coreOrderFixtureOptions?: CoreOrderFixtureOptions
  customerId?: string
  daw?: number
  hasOtcs?: boolean
  hasRxs?: boolean
  isBatchOrder?: boolean
  isMultiPetOrder?: boolean
  isReplacementOrder?: boolean
  langPreference?: string
  locationId?: string
  newPatient?: boolean
  orderType?: FixtureOrderType
  patientId?: string
  prescriptionNdc?: string
  rxNumbers?: number[]
  shipToState?: string
  totalFills?: number
  totalOtcs?: number
}

export interface PatientSurveyInput {
  allergies?: string
  conditions?: string
  medications?: string
  events?: string[]
  drinkingFrequency?: string
  smokingFrequency?: string
  weight?: {
    unit?: string
    value?: number
  }
}

export enum FixtureOrderType {
  PV1 = 'Cash',
  Adjudication = 'Adjudication',
}

type OrderSortFields =
  | 'createdAt'
  | 'dateOkToFill'
  | 'autobotSortVal'
  | 'rxFillRequests.dispensed.name'
  | 'rxFillRequests.dispensed.strength.value'
  | 'rxFillRequests.isReAttempt'
  | 'priority'

export type OrderSortByFields = Partial<Record<OrderSortFields, 1 | -1>>

export type StatBankQueryArgs = { locationId?: string; customerId?: string; states?: string[] }
