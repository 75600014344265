import { useEffect } from 'react'
// import AttachmentsPane from 'components/AttachmentsPane'
import { LaunchDarkly } from '@truepill/tpos-types'
import MessagesPane from 'components/MessagesPane'
import NotesPane from 'components/NotesPane'
import { useCopayPageNotesFilters } from 'components/NotesPane/NotesList/filters'
import type { NoteTab } from 'components/NotesPane/NotesList/types'
import { UnPaddedPageContainer } from 'components/PageStructure'
// import UserEventTimer from 'components/UserEventTimer'
import { useSetPageTitle, useCopayLock, useUnlockCopay } from 'hooks'
import useCopayRequestFill from 'hooks/navigation/useCopayRequestFill'
import useLogs from 'hooks/navigation/useLogs'
import useErrorToast from 'hooks/toast/useErrorToast'
import useLogPageView from 'hooks/useLogPageView'
import useShouldIncludePatientRelatedLogs from 'hooks/useShouldIncludePatientRelatedLogs'
import useCriticalNotesModal from 'modals/useCriticalNotesModal'
import { useFlag } from 'providers/LaunchDarklyProvider'
import type { Fill, Log, CopayRequest, CopayRequestFill, Patient, Prescription } from 'types'
import { LoadingOrder } from '../../orders/rx'
import ClaimsView from '../../orders/rx/screens/claims'

type CopayPageProps = { copayRequestId: string; fillId: string }
const notesPaneTabs: NoteTab[] = ['Fill', 'Copay', 'Rx', 'Patient']
const overridenTabNames: Partial<Record<NoteTab, string>> = { Fill: 'Copay-Fill' }

const RXPage = ({ copayRequestId, fillId }: CopayPageProps): JSX.Element => {
  const { copayRequest, prescription, fill, patient, copayRequestFill, loading, error } = useCopayRequestFill({
    copayRequestId,
    fillId,
  })

  const decoupleCriticalNotes = useFlag(LaunchDarkly.FeatureFlags.DECOUPLE_CRITICAL_NOTES)
  const notesFilters = useCopayPageNotesFilters()
  const shouldIncludePatientRelatedLogs = useShouldIncludePatientRelatedLogs()
  const { logs, loading: isLoadingLogs } = useLogs(
    {
      copayRequestId: copayRequestId,
      fillId: fillId,
      patientId: patient?._id || prescription?.patientId || prescription?.patient._id,
      prescriptionId: prescription?._id,
      prescriberId: prescription?.prescriber._id,
    },
    { shouldIncludePatientRelatedLogs: shouldIncludePatientRelatedLogs || decoupleCriticalNotes },
  )
  useLogPageView(
    {
      page: `copayRequestFill/${copayRequestFill?.status}`,
      copayRequestId: copayRequestId,
      fillId: fillId,
    },
    Boolean(!loading && copayRequestFill?.status),
  )

  useCriticalNotesModal({
    prescription,
    logs,
    copayRequests: [copayRequest],
    copayRequestFillId: fillId,
    showPatientNotes: true,
    showPrescriberNotes: true,
  })

  const { setCopayLock, copayLockedBy } = useCopayLock(copayRequestId)
  const showErrorToast = useErrorToast()

  useUnlockCopay(copayRequestId)

  useEffect(() => {
    const tryLock = async () => {
      try {
        await setCopayLock()
      } catch (e) {
        showErrorToast(`Failed to lock copay ${(e as Record<string, string>).message}`)
      }
    }
    if (!copayLockedBy) {
      tryLock()
    }
  }, [copayRequestId, copayLockedBy, setCopayLock])

  useSetPageTitle('Copay Fill')

  if (error) {
    return (
      <UnPaddedPageContainer>
        <p>Error loading Rx {JSON.stringify(error)}</p>
      </UnPaddedPageContainer>
    )
  }

  if (loading || !copayRequest || !prescription) {
    return <LoadingOrder />
  }

  return (
    <>
      <UnPaddedPageContainer>
        <RXView
          copayRequest={copayRequest}
          fill={fill}
          prescription={prescription}
          copayRequestFill={copayRequestFill}
          patient={patient}
          loading={loading}
          logs={logs || []}
        />
      </UnPaddedPageContainer>
      <MessagesPane logs={logs} />
      <NotesPane
        isLoadingLogs={isLoadingLogs}
        filterOutEncounterRecordsFromOtherThanAllTabs
        logs={logs}
        item={copayRequest}
        fill={fill}
        prescription={prescription}
        patient={patient}
        tabs={notesPaneTabs}
        overrideTabNames={overridenTabNames}
        defaultTab="Fill"
        filters={notesFilters}
      />
      {/* // TODO - make attachments work with copay */}
      {/* <AttachmentsPane orderId={orderId} fillId={fillId} prescriptionId={prescription._id} patientId={patient._id} /> */}
    </>
  )
}
export default RXPage

type CopayRXViewProps = {
  copayRequest: CopayRequest
  fill: Fill
  prescription: Prescription
  copayRequestFill: CopayRequestFill
  patient: Patient
  logs: Log[]
  loading: boolean
}

const RXView = ({ copayRequest, fill, prescription, copayRequestFill, patient, logs, loading }: CopayRXViewProps) => {
  if (loading || !copayRequest || !copayRequestFill) {
    return <></>
  }

  // let event = 'fulfillment:'
  // if (copayRequest.status === CopayStatus.Triage) event += 'triage:'
  // event += copayRequestFill.status

  return (
    <>
      {/* // TODO - make user events work with copays */}
      {/* <UserEventTimer orderId={order._id} rxFillRequestId={rxFillRequest._id} event={event} /> */}
      <ClaimsView item={copayRequest} fill={fill} prescription={prescription} patient={patient} logs={logs} />
    </>
  )
}
