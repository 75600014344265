import { useMutation, useHistory } from '@truepill/tpos-react-router'
import { ClaimDataPaidStatus, CopayStatus, FillStatus, RxFillRequestStatus } from '@truepill/tpos-types'
import { ReactComponent as TimeIcon } from 'assets/icons/time.svg'
import ActionButton from 'components/ActionButton'
import IconWrapper from 'components/IconWrapper'
import { CONFIRM_COPAY_REQUEST_FILL, CONFIRM_FILL_ADJUDICATION } from 'gql'
import { mapSubmissionResponse, updateClaimsRequest } from 'hooks/navigation/useClaim'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import ClaimsSuccessModal from 'modals/ClaimsSuccessModal'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import { goToFulfillmentFill, goToFulfillmentCopayRequestFill } from 'routes'
import { bodyPrimaryColor } from 'styles/styleVariables'
import type { CopayRequestFill, Fill, PriorAuthorization, QueueItem, RXFillRequest } from 'types'
import { getApolloErrorMessage, getItemFill, isCopayRequest, isOrder } from 'utils'

type SubmitClaimActionButtonProps = {
  fill: Fill
  item: QueueItem
  priorAuthorization?: PriorAuthorization
  isPriorAuthorization?: boolean
  isRejectedOrder: boolean
  setRunningClaim?: (val: boolean) => void
  disabled?: boolean
}

export const SubmitClaimActionButton = ({
  item,
  fill,
  priorAuthorization,
  isPriorAuthorization = false,
  isRejectedOrder,
  setRunningClaim,
  disabled = false,
}: SubmitClaimActionButtonProps): JSX.Element => {
  const history = useHistory()
  const showErrorToast = useErrorToast()
  const showSuccess = useSuccessToast()
  const { showModal } = useModalContext()

  const [confirmFillAdjudication, { loading }] = useMutation(CONFIRM_FILL_ADJUDICATION, {
    refetchQueries: ['getBasicOrder'],
    awaitRefetchQueries: true,
    onError: e => {
      const errorMessage = getApolloErrorMessage(e, 'Error running claims:')
      updateClaimsRequest({
        claimsSubmissionResponse: {
          rejectionMessages: [],
          additionalInfo: errorMessage,
          successfulClaim: false,
        },
        claimsSubmitting: false,
      })
    },
    onCompleted(data) {
      const { confirmFillAdjudication } = data
      const { claims } = confirmFillAdjudication
      const lastClaim = claims[claims.length - 1]

      if (ClaimDataPaidStatus.includes(lastClaim.status)) {
        showSuccess(`Successfully ran claims!`)
      } else {
        showErrorToast('Claim rejected')
      }

      updateClaimsRequest({
        claimsSubmissionResponse: mapSubmissionResponse(lastClaim),
        claimsSubmitting: false,
      })

      if (isOrder(item) && isComplete) {
        showModal(() => (
          <ClaimsSuccessModal
            fill={fill}
            itemFill={getItemFill(item, fill._id) as RXFillRequest}
            item={item}
            showReverse
            updatedDispensed={{
              ndc: fill.dispensed.ndc,
              name: fill.dispensed.name,
            }}
            claim={lastClaim}
          />
        ))
      }

      if (isPriorAuthorization) {
        history.push(
          goToFulfillmentFill({
            orderId: item._id,
            fillId: fill._id,
          }),
        )
      }
    },
  })

  const [confirmCopayRequestFill, { loading: loadingCopayRequest }] = useMutation(CONFIRM_COPAY_REQUEST_FILL, {
    refetchQueries: ['getBasicCopay'],
    awaitRefetchQueries: true,
    onError: e => {
      const errorMessage = getApolloErrorMessage(e, 'Error running claims:')
      updateClaimsRequest({
        claimsSubmissionResponse: {
          rejectionMessages: [],
          additionalInfo: errorMessage,
          successfulClaim: false,
        },
        claimsSubmitting: false,
      })
    },
    onCompleted(data) {
      const { confirmCopayRequestFill } = data
      const { claims } = confirmCopayRequestFill
      const lastClaim = claims[claims.length - 1]

      if (ClaimDataPaidStatus.includes(lastClaim.status)) {
        showSuccess(`Successfully ran claims!`)
      } else {
        showErrorToast('Claim rejected')
      }

      updateClaimsRequest({
        claimsSubmissionResponse: mapSubmissionResponse(lastClaim),
        claimsSubmitting: false,
      })

      const hasMedicarePaymentPlanTriage = item.triages.some(
        triage => !triage.endDate && triage.reason === 'MedicarePaymentPlan',
      )

      if (ClaimDataPaidStatus.includes(lastClaim.status) && isCopayRequest(item) && !hasMedicarePaymentPlanTriage) {
        showModal(() => (
          <ClaimsSuccessModal
            fill={fill}
            itemFill={getItemFill(item, fill._id) as unknown as CopayRequestFill}
            item={item}
            showReverse
            updatedDispensed={{
              ndc: fill.dispensed.ndc,
              name: fill.dispensed.name,
            }}
            claim={lastClaim}
            showActions={true}
          />
        ))
      }

      if (isPriorAuthorization) {
        history.push(
          goToFulfillmentCopayRequestFill({
            copayRequestId: item._id,
            fillId: fill._id,
          }),
        )
      }
    },
  })

  const isComplete = isOrder(item)
    ? getItemFill(item, fill._id)?.status === RxFillRequestStatus.Complete
    : getItemFill(item, fill._id)?.status === CopayStatus.Complete
  const isCompleteCopay = isCopayRequest(item) && isComplete
  if ((!isPriorAuthorization && isCompleteCopay) || isRejectedOrder) {
    return <></>
  }

  if (setRunningClaim) {
    setRunningClaim(loading)
  }

  const priorAuthOpenStatus = priorAuthorization?.prescription?.fills.some(fill =>
    [FillStatus.InFulfillment, FillStatus.CopayCheck].includes(fill.status),
  )

  return (
    <ActionButton
      data-testid="submitClaim"
      disabled={disabled || loading}
      icon={
        <IconWrapper>
          <TimeIcon fill={bodyPrimaryColor} />
        </IconWrapper>
      }
      label={loading || loadingCopayRequest ? 'Submitting...' : 'Submit Claim'}
      onClick={() => {
        if (isPriorAuthorization && !priorAuthOpenStatus) {
          showErrorToast('Original copay/order no longer active')
        } else {
          updateClaimsRequest({
            claimsSubmissionResponse: {
              rejectionMessages: [],
              additionalInfo: '',
              successfulClaim: false,
            },
            claimsSubmitting: true,
          })

          if (isOrder(item)) {
            confirmFillAdjudication({
              variables: {
                orderId: item._id,
                fillId: fill._id,
              },
            })
          } else if (isCopayRequest(item)) {
            confirmCopayRequestFill({
              variables: {
                copayRequestId: item._id,
                fillId: fill._id,
              },
            })
          }
        }
      }}
      hotKey={'A'}
      hotKeyMeta={{ ctrl: true, shift: true }}
      hotKeyLabel={'CTRL-SHIFT-A'}
    />
  )
}
