import { useMemo, useState, memo } from 'react'
import { dehyphenate } from '@truepill/tpos-data-util'
import { useQuery } from '@truepill/tpos-react-router'
import { ReactComponent as CheckIcon } from 'assets/icons/circle-checkmark.svg'
import { ReactComponent as DangerIcon } from 'assets/icons/danger.svg'
import { ReactComponent as OutOfIcon } from 'assets/icons/out-of.svg'
import Drop from 'components/DropDownContainer'
import LoadingSpinner from 'components/Loading'
import SearchAutoComplete from 'components/SearchAutoComplete'
import FuzzySearch from 'fuzzy-search'
import {
  GET_ALLOWED_SUBS,
  SEARCH_NDC_FULL_INFO,
  GET_INVENTORY_GROUP_BY_CUSTOMER_ID,
  GET_DEFAULT_INVENTORY_GROUP,
} from 'gql'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import useOrderLock from 'hooks/useOrderLock'
import useStateWithDebouncedValue from 'hooks/useStateWithDebouncedValue'
import { usePlusClient } from 'providers/VisionRouter'
import { darkInfo } from 'styles/styleVariables'
import type { NdcSubstitutionPackage, NdcSubstitutionPackagePreferred, NdcFullInfo, InventoryGroup } from 'types'
import { nameFromNdcSubstitution } from 'utils'
import Warning from '../Warning'
import AutoCompleteOption from './components/AutoCompleteOption'
import { FormularySubstitutionLabel } from './components/FormularySubstitutionLabel'
import { InventoryGroupSelector } from './components/InventoryGroupSelector'
import { NoSubstitutionsInInventoryGroup, RowItemContainer } from './components/StyledComponents'
import type { SubstitutionSelectProps, SubstitutionSuggestionItem, SubstitutionSuggestionOption } from './types'

enum FormularyNdc {
  PreferredNdcs = 'Preferred NDCs',
  NonPreferredNdcs = 'Non-Preferred NDCs',
  OutsideOfFormulary = 'Outside of formulary',
}

function isNdcSubstitutionPackagePreferredBOH(
  ndcSubPackage: Partial<NdcSubstitutionPackage>,
): ndcSubPackage is NdcSubstitutionPackagePreferred {
  return 'inventoryGroup' in ndcSubPackage
}

/**
 *  When mounted, it will search for substitutions for the given location and prescription,
 * If the response has articles from Janus, then a custom list will be displayed, which can have preferred, non-preferred, and off-form articles.
 * In case the response has no janus elements, a simple list of ndcs will be displayed.
 * If the user is an admin, they can search for any drug by NDC or name regardless of whether the answer is from janus or not,
 * and in that case the simple list of ndcs will be displayed.
 **/
const SubstitutionSelect = ({
  dispensedNdc,
  dispensedInventoryGroupId,
  prescription,
  orderId,
  fill,
  onSelect,
  onBlur,
  onChange,
  hotKey = 'd',
  isModal = false,
  preventScrolling,
}: SubstitutionSelectProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState('')
  const { routeToHash } = usePlusClient()
  const {
    tokenContext: { isAdmin },
  } = usePlusClient()
  const canBeSubstituted = prescription.daw === 0
  const { orderEditable } = useOrderLock(orderId)
  const [showNotPreferredWarning, setShowNotPreferredWarning] = useState(false)

  const [additionalNdc, setAdditionalNdc] = useState<string | null>(null)
  const [additionalMedicationName, debouncedAdditionalMedicationName, setAdditionalMedicationName] =
    useStateWithDebouncedValue<string | null>(null)

  const [inventoryGroupTab, setInventoryGroupTab] = useState<InventoryGroup | null>(null)

  const {
    data: dataDefaultInventoryGroup = { getDefaultInventoryGroup: undefined },
    loading: loadingDefaultInventoryGroup,
    error: errorDefaultInventoryGroup,
  } = useQuery<{
    getDefaultInventoryGroup: InventoryGroup
  }>(GET_DEFAULT_INVENTORY_GROUP)

  const {
    data: dataCustomerInventoryGroup = { getInventoryGroupByCustomerId: undefined },
    loading: loadingCustomerInventoryGroup,
    error: errorCustomerInventoryGroup,
  } = useQuery<{
    getInventoryGroupByCustomerId: InventoryGroup
  }>(GET_INVENTORY_GROUP_BY_CUSTOMER_ID, {
    variables: { customerId: prescription.customerId },
  })

  const {
    data: dataNdcFullInfo = { searchNdcFullInfo: [] },
    loading: loadingNdcPackage,
    error: errorNdcPackage,
  } = useQuery<{
    searchNdcFullInfo: NdcFullInfo[]
  }>(SEARCH_NDC_FULL_INFO, {
    skip: additionalNdc === null && debouncedAdditionalMedicationName === null,
    variables: { ndc: additionalNdc, name: debouncedAdditionalMedicationName, locationId: fill.locationId },
  })

  const {
    data: dataAllowedSubs = { getAllowedSubstitutions: { ndcs: [], formularyStatus: undefined } },
    loading: allowedSubsLoading,
    error: errorAllowedSubs,
  } = useQuery(GET_ALLOWED_SUBS, {
    skip: !canBeSubstituted,
    variables: { prescriptionId: prescription._id, locationId: fill.locationId },
    fetchPolicy: 'network-only',
  })

  useHotKey(hotKey, isModal ? HotKeyLevel.modal : HotKeyLevel.normal, () => {
    routeToHash(`#StyledSelect-${hotKey}`)
  })

  // Results from getAllowedSubstitutions request sorted by BOH
  const subsWithBalanceOnHand: NdcSubstitutionPackagePreferred[] = useMemo(() => {
    return dataAllowedSubs.getAllowedSubstitutions.ndcs.map((ndc: NdcSubstitutionPackagePreferred) => {
      return {
        ...ndc,
        balanceOnHand: ndc.balanceOnHand ?? 0,
      }
    })
  }, [dataAllowedSubs.getAllowedSubstitutions.ndcs])

  // Results from searchNdcFullInfo request (ndc free text search)
  const additionalPackages: NdcSubstitutionPackage[] = useMemo(() => {
    return dataNdcFullInfo.searchNdcFullInfo
      .flatMap(fullNdc => {
        // When searching an ndc using digits
        if (additionalNdc) {
          const ndcPackage = fullNdc.packages.find(pkg => additionalNdc === pkg.ndc)
          if (ndcPackage) {
            return [
              {
                ...ndcPackage,
                balanceOnHand: ndcPackage.balanceOnHand ?? 0,
                labeler: fullNdc.labeler.name,
                drugName: ndcPackage.drugName,
                dosageForm: fullNdc.dosageForm,
                strength: fullNdc.strength,
                strengthUnitOfMeasure: fullNdc.strengthUnitOfMeasure,
                preferred: undefined,
              },
            ]
          }
        }
        // When searching an ndc by name
        if (additionalMedicationName) {
          return fullNdc.packages.map(ndcPackage => {
            return {
              ...ndcPackage,
              balanceOnHand: ndcPackage.balanceOnHand ?? 0,
              labeler: fullNdc.labeler.name,
              drugName: ndcPackage.drugName,
              dosageForm: fullNdc.dosageForm,
              strength: fullNdc.strength,
              strengthUnitOfMeasure: fullNdc.strengthUnitOfMeasure,
              preferred: undefined,
            }
          })
        }
        return []
      })
      .filter(i => !!i)
  }, [additionalMedicationName, additionalNdc, dataNdcFullInfo.searchNdcFullInfo])

  const inventoryGroups: InventoryGroup[] = useMemo(() => {
    if (!dataDefaultInventoryGroup.getDefaultInventoryGroup) return []
    if (!dataCustomerInventoryGroup.getInventoryGroupByCustomerId)
      return [dataDefaultInventoryGroup.getDefaultInventoryGroup]
    return [
      dataCustomerInventoryGroup.getInventoryGroupByCustomerId,
      dataDefaultInventoryGroup.getDefaultInventoryGroup,
    ]
  }, [dataDefaultInventoryGroup.getDefaultInventoryGroup, dataCustomerInventoryGroup.getInventoryGroupByCustomerId])

  const formularyStatus = dataAllowedSubs?.getAllowedSubstitutions.formularyStatus
  const responseIsFromJanus = formularyStatus !== null
  const useRawView = !responseIsFromJanus || (isAdmin() && searchTerm !== '')
  const useGroupedView = responseIsFromJanus && !useRawView
  // only show inventory group tabs if there's more than the default inventory group
  const useInventoryGroups = inventoryGroups.length > 1

  const options: { label: string; value: NdcSubstitutionPackage | NdcSubstitutionPackagePreferred }[] = useMemo(() => {
    return [
      ...subsWithBalanceOnHand.map(ndcInfo => ({
        label: nameFromNdcSubstitution(ndcInfo),
        value: ndcInfo,
      })),
      ...additionalPackages
        .slice()
        .sort((a: NdcSubstitutionPackage, b: NdcSubstitutionPackage) => (b.balanceOnHand ?? 0) - (a.balanceOnHand ?? 0))
        .map(ndcInfo => ({
          label: nameFromNdcSubstitution(ndcInfo),
          value: ndcInfo,
        })),
    ]
  }, [subsWithBalanceOnHand, additionalPackages])

  const searcher = useMemo(() => new FuzzySearch(options, ['label', 'value.drugName'], { sort: true }), [options])

  const NDCSearcher = useMemo(() => new FuzzySearch(options, ['value.ndc'], { sort: true }), [options])

  let autoCompleteSuggestions = searcher.search(searchTerm).concat(NDCSearcher.search(dehyphenate(searchTerm)))
  let inventoryGroupHasFilteredSuggestions = false

  // has default settings for when there isn't more than one inv group, but will be overwritten
  // if the customer has a group to select from
  let selectedInventoryGroup = inventoryGroups.find(ig => ig._id === dispensedInventoryGroupId) || inventoryGroups[0]

  if (useInventoryGroups) {
    let inventoryGroupToFilterBy = inventoryGroupTab
    // if no inventory group tab is selected, then it must be determined what group to show first
    if (!inventoryGroupToFilterBy) {
      // go through and check what the boh of all the NDCs are for the selected group
      const customerGroupSuggestionsCheck = autoCompleteSuggestions.filter(
        suggestion =>
          suggestion.value &&
          isNdcSubstitutionPackagePreferredBOH(suggestion.value) &&
          suggestion.value.inventoryGroup?._id === inventoryGroups[0]._id &&
          suggestion.value.balanceOnHand > 0,
      )
      if (customerGroupSuggestionsCheck.length > 0) {
        // only show the customer inventory group if it has inventory records and at least one has a balance above
        inventoryGroupToFilterBy = inventoryGroups[0]
        selectedInventoryGroup = inventoryGroups[0]
      } else {
        // otherwise, default to showing the Rx inventory group
        inventoryGroupToFilterBy = inventoryGroups[1]
        selectedInventoryGroup = inventoryGroups[1]
      }
    }
    const lengthBeforeGroupFilter = autoCompleteSuggestions.length
    autoCompleteSuggestions = autoCompleteSuggestions.filter(
      suggestion =>
        suggestion.value &&
        isNdcSubstitutionPackagePreferredBOH(suggestion.value) &&
        suggestion.value.inventoryGroup?._id === inventoryGroupToFilterBy?._id,
    )
    inventoryGroupHasFilteredSuggestions = autoCompleteSuggestions.length !== lengthBeforeGroupFilter
  }

  let suggestionsToShow,
    preferredGroup: { value: Partial<NdcSubstitutionPackage>; label: string }[] = [],
    nonPreferredGroup: { value: Partial<NdcSubstitutionPackage>; label: string }[] = [],
    outOfFormularyGroup: { value: Partial<NdcSubstitutionPackage>; label: string }[] = []

  // Split the substitutions into groups
  if (autoCompleteSuggestions.length) {
    preferredGroup = autoCompleteSuggestions.filter(suggestion => suggestion.value.preferred)
    nonPreferredGroup = autoCompleteSuggestions.filter(suggestion => suggestion.value.preferred === false)
    outOfFormularyGroup = autoCompleteSuggestions.filter(suggestion => suggestion.value.preferred === null)

    suggestionsToShow = useGroupedView
      ? [...preferredGroup, ...nonPreferredGroup, ...outOfFormularyGroup]
      : autoCompleteSuggestions
  } else if (loadingNdcPackage) {
    suggestionsToShow = [{ label: 'Loading...', value: null }]
  } else if (isAdmin()) {
    suggestionsToShow = [{ label: 'No medications found', value: null }]
  } else {
    suggestionsToShow = [{ label: 'No therapeutically equivalent drugs found', value: null }]
  }

  const handleOnSelect = (option: { value: NdcSubstitutionPackagePreferred }) => {
    const isFromManualSearch = dataNdcFullInfo?.searchNdcFullInfo?.some(ndcInfo =>
      ndcInfo.packages.some(ndcInfoPackage => ndcInfoPackage.ndc === option.value.ndc),
    )
    const formularyStatus = dataAllowedSubs?.getAllowedSubstitutions.formularyStatus

    onSelect(
      option.value,
      isFromManualSearch,
      formularyStatus !== undefined && formularyStatus !== null ? formularyStatus : undefined,
      dataAllowedSubs?.getAllowedSubstitutions?.meta.nonTherapeuticEquivalentNdcs.includes(
        (option.value as NdcSubstitutionPackage)?.ndc,
      ) ?? false,
    )

    setSearchTerm('')

    // If formulary status is null (no janus call) dont show the preferred warning message
    if (formularyStatus !== null) {
      setShowNotPreferredWarning(!option.value.preferred)
    }
  }

  const handleInventoryGroupSelect = (inventoryGroup: InventoryGroup) => {
    setInventoryGroupTab(inventoryGroup)
  }

  const handleOnChange = (searchTerm: string) => {
    setSearchTerm(searchTerm)

    // An admin (and only an admin) can manually select any valid NDC
    const searchTermIsAString = isNaN(parseInt(searchTerm))

    const searchTermCouldBeAnNdc =
      isAdmin() &&
      searchTerm.length === 11 &&
      !searchTermIsAString &&
      !options.find(({ value }) => value?.ndc === searchTerm)

    const searchTermCouldBeMedicationName =
      isAdmin() &&
      searchTermIsAString &&
      !options.find(({ value }) => value?.drugName === searchTerm) &&
      autoCompleteSuggestions.length === 0 &&
      !inventoryGroupHasFilteredSuggestions

    if (searchTermCouldBeAnNdc) {
      setAdditionalNdc(searchTerm)
      setAdditionalMedicationName(null)
    } else if (searchTermCouldBeMedicationName) {
      setAdditionalNdc(null)
      setAdditionalMedicationName(searchTerm)
    }

    onChange?.()
  }

  const error = errorAllowedSubs || errorNdcPackage || errorDefaultInventoryGroup || errorCustomerInventoryGroup

  const janusGroups = responseIsFromJanus
    ? [
        {
          values: preferredGroup,
          label: FormularyNdc.PreferredNdcs,
          icon: <CheckIcon fill="transparent" stroke="black" strokeWidth="2.5" width="1rem" height="1rem" />,
          indexOffset: 0,
        },
        {
          values: nonPreferredGroup,
          label: FormularyNdc.NonPreferredNdcs,
          icon: <DangerIcon fill="inherit" width="1rem" height="1rem" />,
          indexOffset: preferredGroup.length,
        },
        {
          values: outOfFormularyGroup,
          label: FormularyNdc.OutsideOfFormulary,
          icon: <OutOfIcon width="1rem" height="1rem" />,
          indexOffset: preferredGroup.length + nonPreferredGroup.length,
        },
      ]
    : []

  if ((error && error.message.includes('can not be substituted')) || !orderEditable || !canBeSubstituted) {
    return <RowItemContainer>{fill.dispensed.name}</RowItemContainer>
  }

  if (allowedSubsLoading || loadingDefaultInventoryGroup || loadingCustomerInventoryGroup) {
    return (
      <div
        style={{
          display: 'flex',
          height: '50px',
        }}
      >
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <>
      <SearchAutoComplete
        withCapsule
        data-testid="substitution"
        autoCompleteSuggestions={suggestionsToShow}
        value={searchTerm}
        css={showNotPreferredWarning ? { boxShadow: `inset 0px 0px 0px 3px ${darkInfo}` } : undefined}
        suggestionComponent={props => (
          <AutoCompleteOption
            nonTherapeuticEquivalent={dataAllowedSubs?.getAllowedSubstitutions?.meta.nonTherapeuticEquivalentNdcs.includes(
              (props.value as NdcSubstitutionPackage)?.ndc,
            )}
            ndc={prescription.ndc}
            ndcsFromSearch={dataNdcFullInfo?.searchNdcFullInfo}
            {...(props as SubstitutionSuggestionOption)}
          />
        )}
        useCustomSuggestionsWrapper={useGroupedView || useInventoryGroups}
        suggestionsWrapperComponent={({
          testId,
          showOptionsOnFocus,
          maximumHeight,
          containerWidth,
          highlightedIndex,
          onSelect,
        }) => {
          return (
            <Drop
              data-testid={testId ? `${testId}-suggestions` : 'suggestions'}
              forceDisplay={showOptionsOnFocus}
              preventScrolling={preventScrolling}
              maximumHeight={maximumHeight}
              containerWidth={containerWidth}
            >
              <>
                <InventoryGroupSelector
                  inventoryGroups={inventoryGroups}
                  selectedInventoryGroup={inventoryGroupTab ? inventoryGroupTab : selectedInventoryGroup}
                  onSelectInventoryGroup={handleInventoryGroupSelect}
                />
                {janusGroups.map(({ values, label, indexOffset, icon: Icon }) => (
                  <>
                    {values.length > 0 && <FormularySubstitutionLabel text={label}>{Icon}</FormularySubstitutionLabel>}
                    {values.map((val, i) => {
                      const index = i + indexOffset

                      return (
                        <AutoCompleteOption
                          nonTherapeuticEquivalent={dataAllowedSubs?.getAllowedSubstitutions?.meta.nonTherapeuticEquivalentNdcs.includes(
                            (val.value as NdcSubstitutionPackage)?.ndc,
                          )}
                          data-testid="suggestion-item"
                          key={index}
                          highlighted={index === highlightedIndex}
                          onClick={() => onSelect(index)}
                          label={val.label}
                          value={val.value as SubstitutionSuggestionItem}
                          ndc={prescription.ndc}
                          ndcsFromSearch={dataNdcFullInfo?.searchNdcFullInfo}
                        />
                      )
                    })}
                  </>
                ))}
                {/* when there's suggetions in another group, but not the one currently displayed */}
                {useInventoryGroups && autoCompleteSuggestions.length === 0 && (
                  <NoSubstitutionsInInventoryGroup>
                    {' '}
                    No therapeutically equivalent drugs found for inventory group {inventoryGroupTab?.pioneerAlias}{' '}
                  </NoSubstitutionsInInventoryGroup>
                )}
                {/* when there's no janus groups, but there's suggestions in another inventory group aside from the default */}
                {!useGroupedView && useInventoryGroups && autoCompleteSuggestions.length > 0 && (
                  <>
                    {autoCompleteSuggestions.map((val, i) => {
                      return (
                        <AutoCompleteOption
                          nonTherapeuticEquivalent={dataAllowedSubs?.getAllowedSubstitutions?.meta.nonTherapeuticEquivalentNdcs.includes(
                            (val.value as NdcSubstitutionPackage)?.ndc,
                          )}
                          data-testid="suggestion-item"
                          key={i}
                          highlighted={i === highlightedIndex}
                          onClick={() => onSelect(i)}
                          label={val.label}
                          value={val.value as SubstitutionSuggestionItem}
                          ndc={prescription.ndc}
                          ndcsFromSearch={dataNdcFullInfo?.searchNdcFullInfo}
                        />
                      )
                    })}
                  </>
                )}
              </>
            </Drop>
          )
        }}
        showOptionsOnFocus={true}
        onChange={handleOnChange}
        placeholder={options.find(opt => opt?.value?.ndc === dispensedNdc)?.label ?? 'No substitutions available'}
        clearSearchTerm={() => setSearchTerm('')}
        onSelect={handleOnSelect}
        onBlur={onBlur}
        preventScrolling={preventScrolling}
        retainFocusAfterSelection={false}
        containerWidth="100%"
      />
      {showNotPreferredWarning && <Warning text="Select a preferred NDC if possible" />}
    </>
  )
}

export const MemoizedSubstitutionSelect = memo(SubstitutionSelect)

export default SubstitutionSelect
