import { useCallback } from 'react'
import { GET_NEXT_TRIAGE } from 'gql'
import useFulfillmentQueue, { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import { usePlusClient } from 'providers/VisionRouter'
import type { Order, Fill, RXFillRequest, FillInfoType } from 'types'
import { useGetNextOrder } from './useGetNextOrder'
import type { UseGetNextOrderType, GetNextOrderFilters } from './useGetNextOrder'

const useGetNextTriage = (): UseGetNextOrderType => {
  const {
    client,
    currentLocation: { queryMap },
    QueryToolkit: { StringBoolean },
  } = usePlusClient()
  const fulfillmentQueue = useFulfillmentQueue()
  const { fillStages, orderStages } = fulfillmentQueue || { fillStages: [], orderStages: [] }

  const getData = async (variables: GetNextOrderFilters) => {
    const { data } = await client.query({
      query: GET_NEXT_TRIAGE,
      fetchPolicy: 'no-cache',
      variables,
    })

    return data.getNextTriage as Order
  }

  const triageReasonsFromQueryParams = queryMap?.triageReasons ?? queryMap?.reasons

  const customFilters = {
    ...(queryMap?.customerIds && { customerIds: queryMap?.customerIds }),
    ...(queryMap?.locationIds && { locationIds: queryMap?.locationIds }),
    ...(!queryMap?.locationIds && queryMap?.locationId && { locationId: queryMap?.locationId }),
    ...(queryMap?.medications && { medications: queryMap?.medications }),
    ...{ otcOnly: StringBoolean(queryMap?.otcOnly) || false },
    ...(queryMap?.paymentType && { paymentType: queryMap?.paymentType }),
    ...(triageReasonsFromQueryParams && { reasons: triageReasonsFromQueryParams }),
    ...(orderStages?.length && { orderStages }),
    ...(fillStages?.length && { fillStages }),
    ...(queryMap.batchOrders && { batchOrders: StringBoolean(queryMap.batchOrders) || false }),
    ...(queryMap.multiPetOrders && { multiPetOrders: StringBoolean(queryMap.multiPetOrders) || false }),
    ...(queryMap?.specialHandlingTags?.length && { specialHandlingTags: queryMap.specialHandlingTags }),
    // If the handling tags select was cleared then we send an empty array for the query
    ...(queryMap?.noHandlingTags && { specialHandlingTags: queryMap.specialHandlingTags }),
    ...(queryMap?.states?.length && { states: queryMap.states }),
    ...(queryMap?.noStates && { states: queryMap.states }),
  } as GetNextOrderFilters

  // If we're currently filtering by triage, we will only consider
  // the triages that match that reason
  const getFilteredOrderTriages = useCallback(
    (order: Order) => {
      const triagesToSearch = order.triages
      if (triageReasonsFromQueryParams?.length > 0) {
        return triagesToSearch.filter(triage => {
          return triageReasonsFromQueryParams.includes(triage.reason)
        })
      }

      return triagesToSearch
    },
    [triageReasonsFromQueryParams],
  )

  const getNextFillFromOrder = useCallback(
    (
      order: Order,
      currentFill: Fill,
      fillStage?: string,
      lastSolvedTriageId?: string,
    ): { nextFill?: RXFillRequest; stopNavigation?: boolean } => {
      const triagesToSearch = getFilteredOrderTriages(order)
      // If we still have order-level triages that haven't been solved, we don't want to navigate to the next fill
      const stillHasUnsolvedOrderLevelTriages = triagesToSearch.some(
        triage => triage._id !== lastSolvedTriageId && !triage.rxFillRequestId && !triage.endDate,
      )

      if (stillHasUnsolvedOrderLevelTriages) {
        return { stopNavigation: true }
      }

      // If the user solved the rest of the triages, then we go to the next fill
      // that has a triaged associated with it
      const fillRequestFound = order.rxFillRequests?.find(rxFillRequest => {
        const fillHasDifferentId = rxFillRequest.fill._id !== currentFill._id
        const hasUnsolvedTriageAssociatedWithThisFill = triagesToSearch.some(
          triage => triage.rxFillRequestId === rxFillRequest._id && !triage.endDate,
        )

        return fillHasDifferentId && hasUnsolvedTriageAssociatedWithThisFill
      })

      return { nextFill: fillRequestFound }
    },
    [getFilteredOrderTriages],
  )

  const getNextFillInfo = useCallback(
    (order?: Order): FillInfoType | undefined => {
      if (!order || !order?.rxFillRequests) {
        return
      }

      const triagesToSearch = getFilteredOrderTriages(order)

      // we first check if there's any fill with fill level triages need to be solved
      const unsolvedFillLevelTriages = triagesToSearch.filter(triage => triage.rxFillRequestId && !triage.endDate)
      const fillWithTriagesToBeSolved = order.rxFillRequests.find(rxFillRequest => {
        return unsolvedFillLevelTriages.find(unsolvedTriage => unsolvedTriage.rxFillRequestId === rxFillRequest._id)
      })

      // If there's one that has a fill-level triage to be solved, we return that one
      if (fillWithTriagesToBeSolved) {
        const { fill } = fillWithTriagesToBeSolved
        return {
          fillId: fill._id,
          rxId: fillWithTriagesToBeSolved.prescription._id,
          orderId: order._id,
        }
      }

      // If there's no unsolved triage to be solved at fill level, we just return the first rxFillRequest
      // this way the user can solve order-level triages there
      const [firstRxFillRequest] = order.rxFillRequests
      if (!firstRxFillRequest) {
        return
      }

      const { fill } = firstRxFillRequest
      return {
        fillId: fill._id,
        rxId: firstRxFillRequest.prescription._id,
        orderId: order._id,
      }
    },
    [getFilteredOrderTriages],
  )

  return useGetNextOrder(
    customFilters,
    getData,
    FulfillmentQueueName.Triage,
    undefined,
    undefined,
    getNextFillFromOrder,
    getNextFillInfo,
  )
}

export default useGetNextTriage
