import type { Log } from 'types'

export default function getNonPrescriberNotes(logs: Log[]): Log[] {
  if (!logs || !logs.length) return []
  return logs?.filter(
    note =>
      note.event === 'note' &&
      !note.isArchived &&
      (!note.prescriberId || note.fillId || note.prescriptionId || note.orderId || note.copayRequestId),
  )
}
