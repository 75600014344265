import { useState, useEffect } from 'react'
import { Spacer, Text, Tooltip } from '@truepill/react-capsule'
import { isCashPaymentType } from '@truepill/tpos-data-util'
import { useQuery } from '@truepill/tpos-react-router'
import { FillStatus, LaunchDarkly } from '@truepill/tpos-types'
import AccordionContainer from 'components/AccordionContainer'
import CriticalMedicalNoteWarning from 'components/CriticalMedicalNoteWarning'
import DrugImageImprint from 'components/DrugImageImprint'
import FillRequestDetails from 'components/FillRequestDetails'
import { GET_NDC_PACKAGE } from 'components/ReviewPrescription'
import {
  ImageFilledHeader,
  ListRowLabel,
  ListRowValue,
  RightFilledHeader,
  RXCenterCell,
  RXCenterColumn,
  RXListRowTwoColumns,
  RXTitleRow,
  TableContainer,
} from 'components/RXTable'
import { Box } from 'grommet'
import useHotKey, { HotKeyLevel } from 'hooks/useHotKey'
import useRxImageIsPic from 'hooks/useRxImageIsPic'
import { useClient, useFlag } from 'providers/LaunchDarklyProvider'
import { AlertOctagon } from 'react-feather'
import styled from 'styled-components'
import { capsuleDarkBlue } from 'styles/styleVariables'
import type { Fill, NdcFullInfoPackage, Order, Prescription, RXFillRequest } from 'types'
import { getNdcText, getPackageMeasureDescription } from 'utils'
import { getFormattedInventoryGroupName } from 'utils/inventoryGroup'
import ReviewSafety from '../../../components/ReviewSafety'

type MainPv2SectionProps = {
  fill: Fill
  prescription: Prescription
  order: Order
  rxFillRequest: RXFillRequest
}

const RobotImageSection = ({ rxFillRequest, css }: { rxFillRequest: RXFillRequest; css?: React.CSSProperties }) => {
  return (
    <>
      {rxFillRequest.machine?.vials && (
        <AccordionContainer css={css} title="Robot image">
          <Spacer desktop="sm" />
          {rxFillRequest.machine?.vials.map((vial, index) => (
            <>
              <VialHeader key={index}>Vial {vial.number}</VialHeader>
              <VialImageWrapper>
                <img alt={'drug'} src={`data:image/jpeg;base64, ${vial.image}`} />
              </VialImageWrapper>
            </>
          ))}
        </AccordionContainer>
      )}
    </>
  )
}

const NdcWarnings = ({
  dispensedLastFill,
  manualEntryAtFill,
}: {
  dispensedLastFill: { changed: boolean; ndc: string; medication: string }
  manualEntryAtFill: boolean
}) => {
  return (
    <WarningIconWrapper>
      <Tooltip
        css={{ boxShadow: '0px 6px 8px -6px #18274bbf', display: 'flex', flexDirection: 'column', gap: '1.25rem' }}
        variant="white"
        label={
          <>
            {dispensedLastFill.changed && (
              <Box>
                <Text>
                  <strong>NDC has changed from last fill</strong>
                </Text>
                <Text>{`Previous dispensedLastFill medication: ${dispensedLastFill.medication} (${dispensedLastFill.ndc})`}</Text>
              </Box>
            )}
            {manualEntryAtFill && (
              <Box>
                <Text>
                  <strong>NDC captured via Manual Entry at Fill</strong>
                </Text>
              </Box>
            )}
          </>
        }
      >
        <AlertOctagon size="1rem" color={capsuleDarkBlue} />
      </Tooltip>
    </WarningIconWrapper>
  )
}

const MainPv2Section = ({ fill, order, prescription, rxFillRequest }: MainPv2SectionProps): JSX.Element => {
  const [rxImageIsPic, setRxImageIsPic] = useRxImageIsPic()

  const inventoryGroup = getFormattedInventoryGroupName(fill)

  const { customer, patient } = prescription

  useHotKey('x', HotKeyLevel.normal, () => setRxImageIsPic(!rxImageIsPic))

  const lastFill = prescription.fills.filter(f => f.status === FillStatus.Complete).slice(-1)[0]
  const changedDispensedNdc = Boolean(
    fill.verifications?.pv1?.skipped && lastFill?.dispensed.ndc !== fill.dispensed.ndc,
  )

  const ndcs =
    !!fill?.dispensed.ndc && prescription.ndc !== fill.dispensed.ndc
      ? [prescription.ndc, fill.dispensed.ndc]
      : [prescription.ndc]

  const { data } = useQuery(GET_NDC_PACKAGE, {
    variables: { ndcs },
  })

  // Feature flags
  const { client: launchDarklyClient } = useClient()
  const [showCopayCollected, setShowCopayCollected] = useState(false)
  const displayCopayCollected = useFlag(LaunchDarkly.FeatureFlags.DISPLAY_COLLECTED_COPAY_IN_FRONTEND)
  const tempDisplayPackSizeWithNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_DISPLAY_PACK_SIZE_WITH_NDC)
  const hyphenateNdc = useFlag(LaunchDarkly.FeatureFlags.TEMP_HYPHENATE_NDCS)

  const ndcPackages = data?.getNdcPackage as NdcFullInfoPackage[]
  const dispensedNdcPackage = ndcPackages?.find(ndcPackage => ndcPackage?.ndc === fill?.dispensed?.ndc)

  const dispensedNdcText = getNdcText(dispensedNdcPackage, tempDisplayPackSizeWithNdc, hyphenateNdc)

  const manualEntryAtFill = Boolean(fill.dispensed.manualEntryFilled)

  useEffect(() => {
    if (customer?.legacyId && launchDarklyClient) {
      launchDarklyClient.identify({ key: customer.legacyId.toString() })

      setShowCopayCollected(displayCopayCollected)
    }
  }, [launchDarklyClient, customer, displayCopayCollected])

  return (
    <TableContainer>
      <RXTitleRow>
        <RXCenterColumn>
          <ImageFilledHeader>Dispensed medication</ImageFilledHeader>
        </RXCenterColumn>
        <RXCenterColumn>
          <RightFilledHeader>Fill request</RightFilledHeader>
        </RXCenterColumn>
      </RXTitleRow>
      <Box direction="row">
        <Box basis="1/3">
          <RXListRowTwoColumns hideImageColumn>
            <RXCenterCell>
              <ListRowLabel>Name:</ListRowLabel>
              <ListRowValue>{`${patient?.firstName} ${patient?.lastName}`}</ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns hideImageColumn>
            <RXCenterCell>
              <ListRowLabel>Medication:</ListRowLabel>
              <ListRowValue style={{ flexFlow: 'column', justifyContent: 'center' }}>
                <span>{fill.dispensed.name}</span>
                <span>{fill.dispensed.alternateLabelName}</span>
                <CriticalMedicalNoteWarning ndc={fill.dispensed.ndc} />
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns hideImageColumn>
            <RXCenterCell>
              <ListRowLabel>NDC:</ListRowLabel>
              <ListRowValue>
                {dispensedNdcText} {inventoryGroup}
                {(changedDispensedNdc || manualEntryAtFill) && (
                  <NdcWarnings
                    dispensedLastFill={{
                      changed: changedDispensedNdc,
                      medication: lastFill?.dispensed.name,
                      ndc: lastFill?.dispensed.ndc,
                    }}
                    manualEntryAtFill={manualEntryAtFill}
                  />
                )}
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns hideImageColumn>
            <RXCenterCell>
              <ListRowLabel>Mfg:</ListRowLabel>
              <ListRowValue>{fill.dispensed.manufacturer}</ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <RXListRowTwoColumns hideImageColumn>
            <RXCenterCell>
              <ListRowLabel>Qty:</ListRowLabel>
              <ListRowValue>
                {fill.dispensed.quantity} {getPackageMeasureDescription(fill.dispensed.packageSizeUnitOfMeasure)}
              </ListRowValue>
            </RXCenterCell>
          </RXListRowTwoColumns>
          <DrugImageImprint ndc={fill.dispensed.ndc} hasTwoColumns={true} hideImageColumn />
        </Box>
        <Box basis="1/3" overflow="auto">
          <FillRequestDetails
            fill={fill}
            order={order}
            prescription={prescription}
            rxFillRequest={rxFillRequest}
            showCopayCollected={showCopayCollected && !isCashPaymentType(rxFillRequest?.paymentType ?? '')}
          />
          <RobotImageSection rxFillRequest={rxFillRequest} css={{ marginLeft: '1.25rem' }} />
        </Box>
      </Box>
      <ReviewSafety order={order} fill={fill} rxFillRequest={rxFillRequest} prescription={prescription} />
    </TableContainer>
  )
}

const WarningIconWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`

const VialHeader = styled(ListRowValue)`
  padding-left: 1.2rem;
  padding-top: 5px;
`

const VialImageWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  margin-left: 1.2rem;
  height: 300px;
  > img {
    height: 300px;
    width: 100%;
    margin: 5px;
    border-radius: 4px;
  }
`

export default MainPv2Section
