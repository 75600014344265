import { LaunchDarkly } from '@truepill/tpos-types'
import useShouldIncludePatientRelatedLogs from 'hooks/useShouldIncludePatientRelatedLogs'
import { useFlag } from 'providers/LaunchDarklyProvider'
import NotesListAlt from './NotesListAlt'
import NotesListOriginal from './NotesListOriginal'
import NotesListSkeleton from './NotesListSkeleton'
import type { NoteListProps } from './types'

const NotesList = (props: Omit<NoteListProps, 'includeAllPatientRelatedLogsInAlltab'>): JSX.Element => {
  const supportCSANotes = useFlag(LaunchDarkly.FeatureFlags.TEMP_SUPPORT_CSA_NOTES)
  const shouldIncludePatientRelatedLogs = useShouldIncludePatientRelatedLogs()
  const decoupleCriticalNotes = useFlag(LaunchDarkly.FeatureFlags.DECOUPLE_CRITICAL_NOTES)

  if (props.isLoadingLogs) {
    return <NotesListSkeleton />
  }

  if (supportCSANotes) {
    return (
      <NotesListAlt
        {...props}
        includeAllPatientRelatedLogsInAlltab={decoupleCriticalNotes || shouldIncludePatientRelatedLogs}
      />
    )
  }
  return (
    <NotesListOriginal
      {...props}
      includeAllPatientRelatedLogsInAlltab={decoupleCriticalNotes || shouldIncludePatientRelatedLogs}
    />
  )
}

export default NotesList
export { NoteEntry } from './NotesListOriginal'
