import { Checkbox, Text } from '@truepill/react-capsule'
import { CopayStatus } from '@truepill/tpos-types'
import { ReactComponent as FilterIcon } from 'assets/icons/filter.svg'
import { CompressedFormColumn, FormItem, FormItems } from 'components/ColumnForm'
import type { CustomerIdFilterSetting } from 'components/CustomerSearch'
import CustomerSearch from 'components/CustomerSearch'
import EscriptMedicationSearch from 'components/EscriptMedicationSearch'
import EscriptShowRejectedCheckbox from 'components/EscriptShowRejectedCheckbox'
import IntakeIssueStatusSearch from 'components/IntakeIssueStatusSearch'
import OrderMedicationSearch from 'components/OrderMedicationSearch'
import PaymentTypeSelect from 'components/PaymentTypeSelect'
import PodSelect from 'components/PodSelect'
import PrescriptionMedicationSearch from 'components/PrescriptionMedicationSearch'
import { PriorAuthorizationStatusMultiSelect } from 'components/PriorAuthorization'
import RejectCodeCategorySearch from 'components/RejectCodeCategorySearch'
import RejectCodeSearch from 'components/RejectCodeSearch'
import ShippingSelect from 'components/ShippingSelect'
import SortDropdown from 'components/SortDropdown'
import SpecialHandlingSelect from 'components/SpecialHandlingSelect'
import StatesSelect from 'components/StatesSelect'
import StatusSearch from 'components/StatusSearch'
import TriageSearch from 'components/TriageSearch'
import useFulfillmentQueue, { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import useSetFormDataValue from 'hooks/useSetFormDataValue'
import { FILTER_NAMESPACE } from 'NameSpaces'
import { useFormDataContext } from 'providers/FormDataProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import { borderColor, capsuleLightGreyColor, primaryBackgroundColor, primaryColor } from 'styles/styleVariables'
import type { Medication, PaymentType, ShippingMethod } from 'types'

const ResetButton = styled.button`
  display: flex;
  padding: 8px 10px;
  font-weight: 600;
  margin-top: 1rem;
  background: ${primaryBackgroundColor};
  border-radius: 4px;
  justify-content: center;
  border: 2px solid ${borderColor};
  width: 100%;
  font-size: 1em;
  :active {
    background: ${primaryColor};
    color: ${primaryBackgroundColor};
  }
  :focus,
  :hover {
    outline-color: ${primaryColor};
    border: 2px solid ${primaryColor};
  }
`

const FiltersHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  h3 {
    margin-left: 0.4em;
  }
`

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 0.5rem;
`

const DisableCheckboxContainer = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
    label {
      color: ${capsuleLightGreyColor};
    }
  `}
`

const Reset = (): JSX.Element => {
  const { routeToQuery, tokenContext } = usePlusClient()
  const { clearValues } = useFormDataContext()
  return (
    <ResetButton
      onClick={() => {
        clearValues(FILTER_NAMESPACE)
        routeToQuery({ locationId: tokenContext?.locationId })
      }}
    >
      Reset
    </ResetButton>
  )
}

const Header = (): JSX.Element => (
  <FiltersHeading>
    <FilterIcon fill={'black'} />
    <h3>Filters</h3>
  </FiltersHeading>
)

export interface QueuesFilterState {
  medications?: Medication[]
  medication?: string
  customerIds: CustomerIdFilterSetting
  showRobot?: boolean
  showTriage?: boolean
  overdue?: boolean
  batchOrders?: boolean
  reservedForInvestigation?: boolean
  showRejected?: boolean
}

type PrescriptionsFilterProps = {
  isVisible?: boolean
  shippingMethod?: ShippingMethod
}

export const PrescriptionsFilter = ({ isVisible }: PrescriptionsFilterProps): JSX.Element => {
  return (
    <CompressedFormColumn>
      <Header />
      <FormItems>
        <FormItem>
          <p>Medications</p>
          <PrescriptionMedicationSearch isVisible={isVisible} />
        </FormItem>
        <FormItem>
          <CustomerSearch isVisible={isVisible} />
        </FormItem>
        <FormItem>
          <p>Status</p>
          <StatusSearch isVisible={isVisible} />
        </FormItem>
        <Reset />
      </FormItems>
    </CompressedFormColumn>
  )
}

export const EscriptsFilter = ({ isVisible }: PrescriptionsFilterProps): JSX.Element => {
  return (
    <CompressedFormColumn>
      <Header />
      <FormItems>
        <FormItem>
          <p>Medications</p>
          <EscriptMedicationSearch isVisible={isVisible} />
        </FormItem>
        <FormItem>
          <CustomerSearch isVisible={isVisible} />
        </FormItem>
        <FormItem>
          <p>Additional Options</p>
          <EscriptShowRejectedCheckbox />
        </FormItem>
        <Reset />
      </FormItems>
    </CompressedFormColumn>
  )
}

export const IntakeIssuesFilter = ({ isVisible }: PrescriptionsFilterProps): JSX.Element => {
  return (
    <CompressedFormColumn>
      <Header />
      <FormItems>
        <FormItem>
          <CustomerSearch isVisible={isVisible} />
        </FormItem>
        <FormItem>
          <p>Status</p>
          <IntakeIssueStatusSearch isVisible={isVisible} />
        </FormItem>
        <Reset />
      </FormItems>
    </CompressedFormColumn>
  )
}

export const OrdersFilter = ({ isVisible }: PrescriptionsFilterProps): JSX.Element => {
  const { routeToMergedQuery, currentLocation } = usePlusClient()
  const paymentType = currentLocation?.queryMap?.paymentType || undefined

  return (
    <CompressedFormColumn>
      <Header />
      <FormItems>
        <FormItem>
          <OrderMedicationSearch isVisible={isVisible} />
        </FormItem>
        <FormItem>
          <CustomerSearch isVisible={isVisible} />
        </FormItem>
        <FormItem>
          <StatesSelect label="Ship-to State" />
        </FormItem>
        <FormItem>
          <p>Status</p>
          <StatusSearch isVisible={isVisible} statusType="order" />
        </FormItem>
        <FormItem>
          <p>Triage Reasons</p>
          <TriageSearch isVisible={isVisible} />
        </FormItem>
        <FormItem>
          <p>Payment Type</p>
          <PaymentTypeSelect
            clearable
            value={paymentType}
            onSelect={(paymentType: PaymentType) => {
              routeToMergedQuery({ paymentType })
            }}
          />
        </FormItem>
        <FormItem>
          <p>Sort:</p>
          <SortDropdown />
        </FormItem>
        <Reset />
      </FormItems>
    </CompressedFormColumn>
  )
}

export const CopaysFilter = ({ isVisible }: PrescriptionsFilterProps): JSX.Element => {
  return (
    <CompressedFormColumn>
      <Header />
      <FormItems>
        <FormItem>
          <CustomerSearch isVisible={isVisible} />
        </FormItem>
        <FormItem>
          <StatesSelect label="Ship-to State" />
        </FormItem>
        <FormItem>
          <p>Status</p>
          <StatusSearch isVisible={isVisible} statusType="copay" />
        </FormItem>
        <FormItem>
          <p>Triage Reasons</p>
          <TriageSearch isVisible={isVisible} isCopay={true} />
        </FormItem>
        <Reset />
      </FormItems>
    </CompressedFormColumn>
  )
}

export const QueuesFilter = ({ isVisible }: PrescriptionsFilterProps): JSX.Element => {
  const {
    routeToMergedQuery,
    currentLocation,
    QueryToolkit: { StringBoolean },
  } = usePlusClient()
  const { formData } = useFormDataContext()
  const filters = formData[FILTER_NAMESPACE] as unknown as QueuesFilterState
  const setFilterValue = useSetFormDataValue(FILTER_NAMESPACE)
  const fulfillmentQueue = useFulfillmentQueue()
  const showShowRobotFilter =
    fulfillmentQueue &&
    [FulfillmentQueueName.PV2, FulfillmentQueueName.Packing, FulfillmentQueueName.Complete].includes(
      fulfillmentQueue.name,
    )
  const showMultiPetOrdersFilter =
    fulfillmentQueue &&
    ![
      FulfillmentQueueName.Copay,
      FulfillmentQueueName.PriorAuthorization,
      FulfillmentQueueName.PriorAuthorizationNew,
      FulfillmentQueueName.Adjudication,
    ].includes(fulfillmentQueue.name)
  const queryMap = currentLocation?.queryMap || {}
  const paymentType = currentLocation?.queryMap?.paymentType || undefined
  const copayStatus = currentLocation?.queryMap?.copayStatus
  const shippingMethod = currentLocation?.queryMap?.shippingMethod || undefined
  const podName = currentLocation?.queryMap?.podName || undefined

  const isCopay = fulfillmentQueue && fulfillmentQueue.name === FulfillmentQueueName.Copay
  const isPriorAuthorizationOrder =
    fulfillmentQueue && fulfillmentQueue.name === FulfillmentQueueName.PriorAuthorizationNew
  const isPriorAuthorizationCopay = isCopay && copayStatus === CopayStatus.PriorAuthorization
  const isPriorAuthorization = isPriorAuthorizationOrder || isPriorAuthorizationCopay

  const showTriageReasonFilter =
    fulfillmentQueue &&
    (fulfillmentQueue.name === FulfillmentQueueName.Triage || (isCopay && copayStatus === CopayStatus.Triage))
  // duplicated logic see QueuesSection.tsx, getOrderVariables
  const triageChecked = StringBoolean(queryMap.showTriage) || fulfillmentQueue?.name === FulfillmentQueueName.Triage
  return (
    <CompressedFormColumn>
      <Header />
      <FormItems>
        {!isPriorAuthorization && (
          <FormItem>
            <OrderMedicationSearch isVisible={isVisible} />
          </FormItem>
        )}
        <FormItem>
          <CustomerSearch isVisible={isVisible} />
        </FormItem>
        {isPriorAuthorization && (
          <>
            <FormItem>
              <p>Status</p>
              <PriorAuthorizationStatusMultiSelect />
            </FormItem>
            <FormItem>
              <StyledCheckbox
                label="Manual Prior Authorizations"
                checked={!!StringBoolean(currentLocation.queryMap?.manualPriorAuthorizations)}
                onCheckedChange={checked => {
                  routeToMergedQuery({ manualPriorAuthorizations: checked ? true : undefined, page: 0 })
                }}
              />
            </FormItem>
          </>
        )}
        {fulfillmentQueue && !isPriorAuthorization && (
          <FormItem>
            <StatesSelect label="Ship-to State" />
          </FormItem>
        )}
        {fulfillmentQueue && !isPriorAuthorization && !isCopay && (
          <FormItem>
            <SpecialHandlingSelect />
          </FormItem>
        )}
        {fulfillmentQueue &&
          [
            FulfillmentQueueName.PV1,
            FulfillmentQueueName.Triage,
            FulfillmentQueueName.Fill,
            FulfillmentQueueName.PV2,
            FulfillmentQueueName.Packing,
            FulfillmentQueueName.Complete,
          ].includes(fulfillmentQueue.name) && (
            <FormItem>
              <PaymentTypeSelect
                withCapsule
                clearable
                multipleFills
                value={paymentType}
                onSelect={(paymentType: PaymentType) => {
                  routeToMergedQuery({ paymentType })
                }}
              />
            </FormItem>
          )}
        {fulfillmentQueue && !isPriorAuthorization && !isCopay && (
          <FormItem>
            <ShippingSelect
              withCapsule
              value={shippingMethod}
              onSelect={(shippingMethod: ShippingMethod) => {
                routeToMergedQuery({ shippingMethod })
              }}
            />
          </FormItem>
        )}
        {fulfillmentQueue && ( // check location is within LD flag
          <FormItem>
            <PodSelect
              value={podName}
              onSelect={(podName?: string) => {
                // set the podName, set the batchOrders filter to filter out batch orders if podname is set
                // (or unset the batch filter if this is removing the pod filters), set page to 0
                routeToMergedQuery({ podName, batchOrders: !!podName ? false : undefined, page: 0 })
              }}
            />
          </FormItem>
        )}
        {fulfillmentQueue && [FulfillmentQueueName.Adjudication].includes(fulfillmentQueue.name) && (
          <FormItem>
            <p>Reject code</p>
            <RejectCodeSearch isVisible={isVisible} />
          </FormItem>
        )}
        {fulfillmentQueue && [FulfillmentQueueName.Adjudication].includes(fulfillmentQueue.name) && (
          <FormItem>
            <p>Reject Category</p>
            <RejectCodeCategorySearch isVisible={isVisible} />
          </FormItem>
        )}
        {showTriageReasonFilter && (
          <FormItem>
            <p>Triage Reasons</p>
            <TriageSearch isVisible={isVisible} isCopay={isCopay} />
          </FormItem>
        )}
        {!isPriorAuthorization && (
          <FormItem>
            <Text as="h4" bold>
              Additional options
            </Text>
            {!isCopay && (
              <StyledCheckbox
                label="Only show near due and overdue fills"
                checked={Boolean(filters.overdue)}
                onCheckedChange={checked => {
                  setFilterValue('overdue', !!checked)
                }}
              />
            )}
            {!isCopay && (
              <StyledCheckbox
                label="Reserved for investigation"
                checked={Boolean(currentLocation.queryMap?.reservedForInvestigation)}
                onCheckedChange={checked => {
                  routeToMergedQuery({ reservedForInvestigation: checked ? true : undefined, page: 0 })
                }}
              />
            )}
            <DisableCheckboxContainer
              disabled={
                StringBoolean(currentLocation.queryMap?.batchOrders) === false ||
                StringBoolean(currentLocation.queryMap?.multiPetOrders)
              }
            >
              <StyledCheckbox
                label="Only show batch orders"
                checked={!!StringBoolean(currentLocation.queryMap?.batchOrders)}
                disabled={
                  StringBoolean(currentLocation.queryMap?.batchOrders) === false ||
                  StringBoolean(currentLocation.queryMap?.multiPetOrders)
                }
                onCheckedChange={checked => {
                  routeToMergedQuery({ batchOrders: checked ? true : undefined, page: 0 })
                }}
              />
            </DisableCheckboxContainer>

            {showMultiPetOrdersFilter && (
              <DisableCheckboxContainer
                disabled={!!(StringBoolean(currentLocation.queryMap?.batchOrders) !== undefined)}
              >
                <StyledCheckbox
                  label="Only show multi pet orders"
                  checked={!!StringBoolean(currentLocation.queryMap?.multiPetOrders)}
                  disabled={!!(StringBoolean(currentLocation.queryMap?.batchOrders) !== undefined)}
                  onCheckedChange={checked => {
                    routeToMergedQuery({ multiPetOrders: checked ? true : undefined, page: 0 })
                  }}
                />
              </DisableCheckboxContainer>
            )}

            <DisableCheckboxContainer
              disabled={
                !!StringBoolean(currentLocation.queryMap?.batchOrders) ||
                !!StringBoolean(currentLocation.queryMap?.multiPetOrders)
              }
            >
              <StyledCheckbox
                label="Only show non-batch orders"
                checked={StringBoolean(currentLocation.queryMap?.batchOrders) === false}
                disabled={
                  !!StringBoolean(currentLocation.queryMap?.batchOrders) ||
                  !!StringBoolean(currentLocation.queryMap?.multiPetOrders)
                }
                onCheckedChange={checked => {
                  routeToMergedQuery({ batchOrders: checked ? false : undefined, page: 0 })
                }}
              />
            </DisableCheckboxContainer>

            {showShowRobotFilter && (
              <StyledCheckbox
                label="Show robot"
                checked={Boolean(currentLocation.queryMap?.showRobot)}
                onCheckedChange={checked => {
                  routeToMergedQuery({ showRobot: checked ? true : undefined, page: 0 })
                }}
              />
            )}
            {fulfillmentQueue && ![FulfillmentQueueName.Triage].includes(fulfillmentQueue.name) && !isCopay && (
              <StyledCheckbox
                label="Show triage"
                checked={triageChecked}
                onCheckedChange={checked => {
                  routeToMergedQuery({ showTriage: checked !== undefined ? checked : false, page: 0 })
                }}
              />
            )}
            {fulfillmentQueue && [FulfillmentQueueName.Complete].includes(fulfillmentQueue.name) && (
              <StyledCheckbox
                label="OTC Only"
                checked={StringBoolean(currentLocation.queryMap?.otcOnly)}
                onCheckedChange={checked => {
                  routeToMergedQuery({ otcOnly: checked !== undefined ? checked : false, page: 0 })
                }}
              />
            )}
          </FormItem>
        )}
        <Reset />
      </FormItems>
    </CompressedFormColumn>
  )
}

export const ExternalTransfersFilter = ({ isVisible }: PrescriptionsFilterProps): JSX.Element => {
  return (
    <CompressedFormColumn>
      <Header />
      <FormItems>
        <FormItem>
          <CustomerSearch isVisible={isVisible} />
        </FormItem>
        <Reset />
      </FormItems>
    </CompressedFormColumn>
  )
}
