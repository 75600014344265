import { useQuery } from '@truepill/tpos-react-router'
import BreadCrumb from 'components/BreadCrumb'
import { PageContent, PageHeading, StickyPageHeadingContainer } from 'components/PageStructure'
import RxHeading from 'components/RxHeading'
import { RXTable, RXSegment, RXRowTable } from 'components/RXTable'
import RXTopBanner from 'components/RXTopBanner'
import ToolBar, {
  OrderButton,
  LogButton,
  NotesButton,
  MedicalHistoryButton,
  DataButton,
  MonographButton,
  AttachmentsButton,
} from 'components/ToolBar'
import { SEARCH_PARATA_NDCS, GET_NDC_ALTERNATE_BAR_CODES } from 'gql'
import { uniqueLogEntries } from 'hooks/navigation/useLogs'
import useCriticalNotesModal from 'modals/useCriticalNotesModal'
import styled from 'styled-components'
import type { TPOSDocument, Fill, Order, Patient, Prescription, Log, RXFillRequest } from 'types'
import { getOrderAttachmentsCount, getParentAttachments } from 'utils'
import ActionButtons from './ActionButtons'
import PrimaryTableSection from './TableSections/Primary'

type FillViewProps = {
  order: Order
  fill: Fill
  prescription: Prescription
  patient: Patient
  rxFillRequest: RXFillRequest
  docs?: TPOSDocument[]
  logs: Log[]
  notesBadgeCount: number
}

const FillView = ({
  fill,
  notesBadgeCount,
  prescription,
  order,
  patient,
  rxFillRequest,
  docs,
  logs,
}: FillViewProps): JSX.Element => {
  useCriticalNotesModal({ order, prescription, logs, showOrderFillNotes: true, showPatientNotes: true })

  const { data } = useQuery(SEARCH_PARATA_NDCS, {
    variables: {
      locationId: fill.locationId,
      customerId: prescription.customerId,
      ndc: fill.dispensed.ndc,
    },
    // we know that there is nothing to render if this condition is true, so we skip te GraphQL call
    skip: !order || !fill || !prescription || !patient,
  })

  const { data: alternateBarCodesData } = useQuery(GET_NDC_ALTERNATE_BAR_CODES, {
    variables: {
      ndcs: [fill.dispensed.ndc],
    },
    skip: !fill,
  })

  const alternateBarCodes = alternateBarCodesData?.getNdcAlternateBarCodes?.[0]?.barCodes

  //since we skipped the GraphQL call and we know we dont have data, return null
  //(this fixed the conditional execution of the useQuery hook)
  if (!order || !fill || !prescription || !patient) {
    return <></>
  }

  const parataNdcs = data?.getParataNdcs

  const orderAttachments = getOrderAttachmentsCount(docs)
  const parentAttachments = getParentAttachments(docs)

  return (
    <>
      <StickyPageHeadingContainer>
        <RXTopBanner order={order} rxFillRequest={rxFillRequest} />
        <StyledBreadCrumb />
        <PageHeading>
          <RxHeading item={order} prescription={prescription} fill={fill} patient={patient} />
          <ActionButtons
            order={order}
            fill={fill}
            prescription={prescription}
            rxFillRequest={rxFillRequest}
            hasParataNdcs={!!parataNdcs?.length}
          />
        </PageHeading>
      </StickyPageHeadingContainer>
      <PageContent>
        <RXViewContainer>
          <RXTable>
            <RXSegment>
              <RXRowTable>
                <PrimaryTableSection
                  order={order}
                  fill={fill}
                  prescription={prescription}
                  alternateBarCodes={alternateBarCodes}
                />
              </RXRowTable>
            </RXSegment>
          </RXTable>
        </RXViewContainer>
        <ToolBar>
          <LogButton logs={logs ? uniqueLogEntries(logs) : []} fillId={fill._id} />
          <NotesButton notesCount={notesBadgeCount} showBadge={notesBadgeCount > 0} />
          <AttachmentsButton
            attachmentsCount={orderAttachments}
            showBadge={parentAttachments || orderAttachments > 0}
          />
          <MedicalHistoryButton fillId={fill._id} patientId={patient._id} />
          <OrderButton order={order} />
          <DataButton prescription={prescription} />
          <MonographButton ndc={fill.dispensed.ndc} />
        </ToolBar>
      </PageContent>
    </>
  )
}

const RXViewContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: 1.25rem;
  padding-left: 1.875rem;
`

const StyledBreadCrumb = styled(BreadCrumb)`
  margin-top: 1.25rem;
`

export default FillView
