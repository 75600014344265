import { useEffect, useRef } from 'react'
import { AutobotType, LaunchDarkly } from '@truepill/tpos-types'
import Paging from 'components/Paging'
import type { GetFulfillmentOrdersParams } from 'hooks/navigation/useFulfillmentOrders'
import useFulfillmentOrders from 'hooks/navigation/useFulfillmentOrders'
import useErrorToast from 'hooks/toast/useErrorToast'
import type { FulfillmentQueue } from 'hooks/useFulfillmentQueue'
import useFulfillmentQueue, { FulfillmentQueueName } from 'hooks/useFulfillmentQueue'
import isEqual from 'lodash.isequal'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { usePlusClient } from 'providers/VisionRouter'
import styled from 'styled-components'
import type { Order, OrderSearchVariables } from 'types'
import { updateOrder, updatePrescriptionOnOrder } from 'utils/subscribeToolkit'
import { useSelectionContext } from '../../../../../providers/SelectionProvider'
import PrescriptionQueue from './PrescriptionQueue'

type ExpandedQueueSectionProps = { orderSearchVariables: OrderSearchVariables }

const ExpandedQueueSection = ({ orderSearchVariables }: ExpandedQueueSectionProps): JSX.Element => {
  const {
    routeToMergedQuery,
    currentLocation,
    QueryToolkit: { StringBoolean },
  } = usePlusClient()
  const filtersResetPagination = useFlag(LaunchDarkly.FeatureFlags.TEMP_FILTERS_RESET_PAGINATION)

  const showErrorToast = useErrorToast()
  const fulfillmentQueue = useFulfillmentQueue()
  const lastQuery = useRef<GetFulfillmentOrdersParams | undefined>(undefined)

  const pageSize = currentLocation.queryMap.pageSize ? parseInt(currentLocation.queryMap.pageSize, 10) : 10

  const pagingVariables = {
    pageNumber: Number(currentLocation.queryMap?.page) || 0,
    pageSize: pageSize,
  }

  const queryMap = currentLocation?.queryMap || {}
  const currentAutobotType: AutobotType = queryMap.autobot || AutobotType.mini
  const getOrdersVariables: GetFulfillmentOrdersParams = {
    // ...getGraphqlQueryVars(filters, fulfillmentQueue?.name),
    ...(fulfillmentQueue?.filters ?? {}),
    ...(queryMap.locationIds && { selectedLocationIds: queryMap.locationIds }),
    ...(!queryMap.locationIds && queryMap.locationId && { locationId: queryMap.locationId }),
    ...(queryMap.customerIds && { customerIds: queryMap.customerIds }),
    ...(queryMap.paymentType && { paymentType: queryMap.paymentType }),
    ...(queryMap.rejectCodes && { rejectCodes: queryMap.rejectCodes }),
    ...(queryMap.rejectCodeCategories && { rejectCodeCategories: queryMap.rejectCodeCategories }),
    ...(queryMap.triageReasons && { triageReasons: queryMap.triageReasons }),
    fillStages: fulfillmentQueue?.fillStages ?? [],
    orderStages: fulfillmentQueue?.orderStages ?? [],
    includeTriage: StringBoolean(queryMap.showTriage) || fulfillmentQueue?.name === FulfillmentQueueName.Triage,
    ...(queryMap.hasNoPrints && { hasNoPrints: StringBoolean(queryMap.hasNoPrints) }),
    ...(queryMap.completeAutomation && { completeAutomation: StringBoolean(queryMap.completeAutomation) }),
    ...(queryMap.ndcs && { ndcs: queryMap.ndcs }),
    ...(queryMap.medications && { medicationNames: queryMap.medications }),
    ...(queryMap.batchOrders && { isBatch: StringBoolean(queryMap.batchOrders) }),
    ...(queryMap.multiPetOrders && { isMultiPet: StringBoolean(queryMap.multiPetOrders) }),
    ...(queryMap.reservedForInvestigation && {
      isReservedForInvestigation: StringBoolean(queryMap.reservedForInvestigation),
    }),
    ...(queryMap.sortField && { sortField: queryMap.sortField }),
    ...(queryMap.showRobot && { hasRobotFills: StringBoolean(queryMap.showRobot) }),
    ...(fulfillmentQueue?.name === 'Automation' && { autobotType: currentAutobotType }),
    ...(orderSearchVariables.rxFillCode && { rxFillCode: orderSearchVariables.rxFillCode }),
    ...(orderSearchVariables.customerRef && { customerRef: orderSearchVariables.customerRef }),
    ...(orderSearchVariables.drugName && { drugName: orderSearchVariables.drugName }),
    ...(orderSearchVariables.patientName && { patientName: orderSearchVariables.patientName }),
    ...(orderSearchVariables.coreOrderToken && { coreOrderToken: orderSearchVariables.coreOrderToken }),
    ...(orderSearchVariables.orderNumber && { orderNumber: parseInt(orderSearchVariables.orderNumber, 10) }),
    ...{ isOtcOnly: StringBoolean(queryMap.otcOnly) || false },
    ...{ isRxOtcOnly: StringBoolean(queryMap.rxOtcOnly) || false },
    ...(orderSearchVariables.coreFillRequestToken && {
      coreFillRequestToken: orderSearchVariables.coreFillRequestToken,
    }),
    ...(queryMap.specialHandlingTags?.length && { specialHandlingTags: queryMap.specialHandlingTags }),
    // If the handling tags select was cleared then we send an empty array for the query
    ...(queryMap.noHandlingTags && { specialHandlingTags: [] }),
    ...(queryMap.states?.length && { states: queryMap.states }),
    ...(queryMap.noStates && { states: [] }),
    ...(queryMap.shippingMethod && { shippingMethod: queryMap.shippingMethod }),
    ...(queryMap.podNdcs && { ndcs: queryMap.podNdcs }),
  }

  // if the filter values have changed, reset the page to zero
  if (filtersResetPagination && lastQuery.current !== undefined && !isEqual(getOrdersVariables, lastQuery.current)) {
    // query for page number 0
    pagingVariables.pageNumber = 0
    // set the url query string
    routeToMergedQuery({ page: 0 })
  }

  // store the last filters
  lastQuery.current = getOrdersVariables

  const orderQueryVariables: GetFulfillmentOrdersParams = { ...getOrdersVariables, ...pagingVariables, sort: 1 }

  const { data, loading, error, subscribeToMore } = useFulfillmentOrders(orderQueryVariables)
  const { setTotalSelectableItems, setCurrentSelections, initSelections } = useSelectionContext()

  useEffect(() => {
    if (!data?.getFulfillmentOrders?.orders) return

    const orderIds = data?.getFulfillmentOrders?.orders.map(o => o._id)

    if (subscribeToMore && orderIds?.length) {
      return updateOrder(subscribeToMore, { orderIds })
    }
  }, [subscribeToMore, data])

  useEffect(() => {
    // Reset selection when queue changes or OTC filters are applied within the Packing queue
    initSelections([])
  }, [fulfillmentQueue?.name, initSelections, orderQueryVariables.isOtcOnly, orderQueryVariables.isRxOtcOnly])

  useEffect(() => {
    if (data?.getFulfillmentOrders?.orders?.length && subscribeToMore) {
      const prescriptionIds = data.getFulfillmentOrders.orders
        .map(order => order.rxFillRequests.map(fr => fr.prescriptionId))
        .flat(1)

      if (!prescriptionIds.length) return

      return updatePrescriptionOnOrder(subscribeToMore, { prescriptionIds }, true)
    }
  }, [subscribeToMore, data?.getFulfillmentOrders?.orders])

  useEffect(() => {
    // Reset selection when queue changes or OTC filters are applied within the Packing queue
    initSelections([])
  }, [fulfillmentQueue?.name, initSelections, orderQueryVariables.isOtcOnly, orderQueryVariables.isRxOtcOnly])

  useEffect(
    () => setTotalSelectableItems(data?.getFulfillmentOrders?.totalRecords ?? -1),
    [setTotalSelectableItems, data?.getFulfillmentOrders?.totalRecords],
  )

  useEffect(() => {
    if (fulfillmentQueue?.name === 'Packing') {
      return
    }

    const totalFills =
      data?.getFulfillmentOrders?.orders?.reduce((totalFills, order) => {
        const numOfFills = order?.rxFillRequests?.length ?? 0
        return totalFills + numOfFills
      }, 0) ?? -1

    setTotalSelectableItems(totalFills)
  }, [setTotalSelectableItems, data, fulfillmentQueue?.name])

  useEffect(() => {
    if (error) {
      showErrorToast(error?.message)
    }
  }, [error, showErrorToast])

  const orders: Order[] = data?.getFulfillmentOrders?.orders || []

  const totalOrders = data?.getFulfillmentOrders?.totalRecords ?? 0
  const currentPage = data?.getFulfillmentOrders?.currentPage ?? 0
  const totalPages = Math.ceil(totalOrders / pageSize)

  return (
    <SingleQueue>
      <PrescriptionQueue
        items={orders}
        totalItems={totalOrders}
        loading={loading}
        queue={fulfillmentQueue as FulfillmentQueue}
        selected
        locationId={queryMap.locationId}
      />

      {!loading && (
        <Paging
          totalPages={totalPages}
          currentPage={currentPage}
          setPage={pageNumber => {
            routeToMergedQuery({ page: pageNumber })
            setCurrentSelections([])
          }}
          totalRecords={totalOrders}
          pageSize={pageSize}
        />
      )}
    </SingleQueue>
  )
}

export default ExpandedQueueSection

const SingleQueue = styled.div`
  background-color: white;
  grid-row: content;
  grid-column: fills;
  margin-left: 1.25rem;
  height: calc(100vh - 13rem);
  margin-right: 1.875rem;
  position: relative;
`
