import { useCallback, useEffect } from 'react'
import { LaunchDarkly } from '@truepill/tpos-types'
import CriticalNotesModal from 'components/CriticalNotesModal'
import ControlledSubstanceNote from 'pages/fulfillment/orders/rx/screens/pv1/ControlledSubstanceNote'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { useModalContext } from 'providers/Overlays/ModalProvider'
import type { CopayRequest, Log, Order, Prescription } from 'types'
import { getCriticalLogs } from 'utils'

type useCriticalNotesModalProps = {
  order?: Order
  prescription?: Prescription
  copayRequests?: CopayRequest[]
  copayRequestFillId?: string
  logs?: Log[]
  orderOnlyNotes?: boolean
  showPrescriberNotes?: boolean
  showPatientNotes?: boolean
  showOrderFillNotes?: boolean
}

const useCriticalNotesModal = ({
  order,
  prescription,
  copayRequests,
  copayRequestFillId,
  logs = [],
  orderOnlyNotes = false,
  showPrescriberNotes = false,
  showPatientNotes = false,
  showOrderFillNotes = false,
}: useCriticalNotesModalProps): void => {
  const decoupleCriticalNotes = useFlag(LaunchDarkly.FeatureFlags.DECOUPLE_CRITICAL_NOTES)

  const criticalNotes: Log[] = []
  interface noteMap {
    [key: string]: boolean
  }
  const currentNoteMap: noteMap = {}

  if (order?.logs) {
    let orderCriticalNotes = getCriticalLogs(order.logs)
    if (prescription) {
      // In batch orders there could be critical notes from different prescriptions
      // so filter to the current prescription
      orderCriticalNotes = orderCriticalNotes.filter(note =>
        note.prescriptionId ? note.prescriptionId === prescription._id : true,
      )

      // On fill pages if there are any order logs for a specific fill make sure we filter out the others
      const rxFillRequest = order.rxFillRequests.find(
        rxFillRequest => rxFillRequest.prescriptionId === prescription._id,
      )
      orderCriticalNotes = orderCriticalNotes.filter(note =>
        note.fillId ? note.fillId === rxFillRequest?.fillId : true,
      )
    }

    if (orderOnlyNotes) {
      orderCriticalNotes = orderCriticalNotes.filter(note => !note.prescriptionId && !note.fillId && !note.patientId)
    }

    criticalNotes.push(...orderCriticalNotes)
    orderCriticalNotes.forEach(note => (currentNoteMap[note._id.toString()] = true))
  }

  if (prescription?.logs) {
    const prescriptionCriticalNotes = getCriticalLogs(prescription.logs)
    // Some of the order logs might contain the prescription logs so we need to make sure we don't push duplicates
    prescriptionCriticalNotes.forEach(note => {
      if (!currentNoteMap[note._id.toString()]) {
        criticalNotes.push(note)
        currentNoteMap[note._id.toString()] = true
      }
    })
  }

  const criticalLogs = getCriticalLogs(logs)
  if (decoupleCriticalNotes && criticalLogs.length) {
    if (copayRequests?.length) {
      const copayRequestIds: any = {}
      copayRequests.forEach(copayRequest => (copayRequestIds[copayRequest?._id] = true))
      const copayCriticalNotes = criticalLogs.filter(
        note => !!note.copayRequestId && !note.fillId && !!copayRequestIds[note.copayRequestId],
      )
      criticalNotes.push(...copayCriticalNotes)
    }

    if (copayRequestFillId) {
      const copayRequestFillCriticalNotes = criticalLogs.filter(
        note => !!note.fillId && !!note.copayRequestId && copayRequestFillId === note.fillId,
      )
      criticalNotes.push(...copayRequestFillCriticalNotes)
    }

    if (showPatientNotes) {
      const patientCriticalNotes = criticalLogs.filter(log => !!log.patientId)
      criticalNotes.push(...patientCriticalNotes)
    }

    if (showOrderFillNotes) {
      const patientCriticalNotes = criticalLogs.filter(log => !!log.orderId && !!log.fillId)
      criticalNotes.push(...patientCriticalNotes)
    }

    if (showPrescriberNotes) {
      const prescriberCriticalNotes = criticalLogs.filter(log => !!log.prescriberId)
      criticalNotes.push(...prescriberCriticalNotes)
    }
  } else {
    if (logs) {
      const currentNoteIds = criticalNotes.map(note => note._id)
      criticalLogs.forEach(log => {
        if (!currentNoteIds.includes(log._id)) {
          criticalNotes.push(log)
        }
      })
    }
  }

  const criticalNotesToShow = criticalNotes
    .filter(note => !note.isArchived)
    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())

  const useCriticalNotesPopModal = (prescription?: Prescription, notes?: Log[]) => {
    const { showModal } = useModalContext()
    return useCallback(() => {
      showModal(() => (
        <CriticalNotesModal notes={notes}>
          {prescription?.deaSchedule && <ControlledSubstanceNote />}
        </CriticalNotesModal>
      ))
    }, [prescription?.deaSchedule, notes, showModal])
  }

  const popCriticalNotesModal = useCriticalNotesPopModal(prescription, criticalNotesToShow)

  useEffect(() => {
    if (criticalNotesToShow.length || prescription?.deaSchedule) {
      return popCriticalNotesModal()
    }
    return undefined
  }, [criticalNotesToShow.length])
}

export default useCriticalNotesModal
