import type { EntityId } from '../shared'

export enum CoreCopayStatus {
  Pending = 'pending',
  Triage = 'triage',
  Rejected = 'rejected',
  Complete = 'completed',
}

export enum CopayStatus {
  Pending = 'Pending',
  Triage = 'Triage',
  PriorAuthorization = 'PriorAuthorization',
  Rejected = 'Rejected',
  Complete = 'Complete',
  Confirmed = 'Confirmed',
}

export enum CopayTriageReasons {
  BadInsuranceDetails = 'Bad Insurance Details',
  ClinicOutreach = 'Clinic Outreach',
  CoverageNotEffective = 'Coverage Not Effective',
  CoverageTerminated = 'Coverage Terminated',
  CustomerClarificationNeeded = 'Customer Clarification Needed',
  DOBMismatch = 'DOB Mismatch',
  DuplicateRequest = 'Duplicate Request',
  MedicarePaymentPlan = 'Medicare Payment Plan',
  PendingInternalTransfer = 'Pending Internal Transfer',
  PlanLimitationsExceeded = 'Plan Limitations Exceeded',
  PriorAuthorizationNeeded = 'Prior Authorization Needed',
  RefillTooSoon = 'Refill Too Soon',
  ReviewRequired = 'Review Required',
  Other = 'Other',
}

export enum CopayTriageOtherReasonMessages {
  FailedReversalTriageReason = 'Failed to reverse claim, manual reversal and rejection required',
  FailedReversalAfterMinutesTriageReason = 'Failed to reverse claim after \\d minutes, manual reversal and rejection required',
}

export enum CopayTriageCodes {
  BadInsuranceDetails = 'CT010',
  ClinicOutreach = 'CT001',
  CoverageNotEffective = 'CT008',
  CoverageTerminated = 'CT007',
  CustomerClarificationNeeded = 'CT002',
  DOBMismatch = 'CT009',
  DuplicateRequest = 'CT013',
  MedicarePaymentPlan = 'CT014',
  PendingInternalTransfer = 'CT005',
  PlanLimitationsExceeded = 'CT012',
  PriorAuthorizationNeeded = 'CT003',
  RefillTooSoon = 'CT004',
  ReviewRequired = 'CT011',
  Other = 'CT006',
}

export interface ApprovedCopay {
  approvedDate: Date
  copayAmount: number
}

export type CancelOpenCopayRequestReason = 'OTHER' | 'DUPLICATE'

export interface CopayTriage {
  _id: EntityId
  code: string
  copayRequestFillId?: EntityId
  endDate?: Date
  fillId?: EntityId
  message?: string
  reason: string
  startDate: Date
  userPlacedInTriage: EntityId
  userRemovedFromTriage?: EntityId
}

export interface CopayRequestStatusUpdate {
  'copayRequestFills.$.status': CopayStatus
  'copayRequestFills.$.triages'?: CopayTriage[]
}
