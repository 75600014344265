import { Tooltip } from '@truepill/react-capsule'
import { couriers, findTracking } from '@truepill/tpos-data-util'
import { useMutation } from '@truepill/tpos-react-router'
import { OrderStatus, RxFillRequestStatus, UserRoles } from '@truepill/tpos-types'
import { SaveButton } from 'components/ActionButton'
import { ActionButtonContainer, CancelButton } from 'components/PageStructure'
import TriageOrResolveTriageButton from 'components/TriageOrResolveTriageButton'
import {
  CONFIRM_PACKING,
  UPDATE_OTC_SKUS,
  UPDATE_ORDER_IS_REPLACEMENT,
  UPDATE_ORDER_IS_RESERVED_FOR_INVESTIGATION,
  UPDATE_PAYMENT_TYPE,
  UPDATE_TRACKING_NUMBER,
  UPDATE_SHIPPING_ADDRESS,
  UPDATE_SHIPPING_METHOD,
} from 'gql'
import useErrorToast from 'hooks/toast/useErrorToast'
import useSuccessToast from 'hooks/toast/useSuccessToast'
import useEditMode from 'hooks/useEditMode'
import useEditValue from 'hooks/useEditValue'
import useUpdatePatient from 'hooks/useUpdatePatient'
import { isEmpty } from 'lodash'
import { usePlusClient } from 'providers/VisionRouter'
import type { Address, Order, Patient, PaymentType, ShippingMethod } from 'types'
import { diffObjects } from 'utils'
import AuthLimited from '../AuthLimited'
import {
  RejectOrderButton,
  IssueButton,
  PrintShippingLabelButton,
  RerouteButton,
  RevertToCoreButton,
  ReturnOrderButton,
  NextOrderButton,
} from './components'
import { ForceStatusToCompleteButton } from './components/ForceStatusToCompleteButton'
import ReplacementButton from './components/ReplacementButton'
import ResetOrderButton from './components/ResetOrderButton'
import OrderViewMode from './OrderViewMode'

function showReroute(order: Order) {
  // replacement orders go straight to Fill and have previous prints on the RX, this allows a reroute for
  // replacements specifically
  if (order.status === OrderStatus.Fill && order.isReplacement) {
    return true
  }

  // TODO filter by current order's fills only, or implement barcode invalidation
  // reroute = (order.status === OrderStatus.pv1 ||
  //   order.status === OrderStatus.fill ||
  //   order.status === OrderStatus.pv2) &&
  //   !order?.rxFillRequests.some(({ prescription }) =>
  //   prescription?.fills.some(({ lastPrintedAt }) => !!lastPrintedAt)
  //   )

  return true
}

type OrderViewButtonsType = {
  disableOrder: boolean | undefined
  mode: OrderViewMode
  onCancel: () => void
  order: Order
  patientValues?: Patient
  shippingAddressValues?: Address
  shippingMethod?: ShippingMethod
  trackingNumber?: string
  setTrackingNumber: (trackingNumber: string) => void
  otcSkuValues?: { [id: string]: string | null }
  isReplacement?: boolean
  isReservedForInvestigation?: boolean
  paymentType?: PaymentType
}

const BEFORE_FILL = new Set([OrderStatus.PV1, OrderStatus.Adjudication, OrderStatus.Fill])

const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean
  wrapper: (chidren: JSX.Element) => JSX.Element
  children: JSX.Element
}) => (condition ? wrapper(children) : children)

const ActionButtons = ({
  disableOrder,
  mode,
  onCancel,
  order,
  otcSkuValues = {},
  patientValues,
  shippingAddressValues,
  shippingMethod,
  trackingNumber = '',
  setTrackingNumber,
  isReplacement = false,
  isReservedForInvestigation = false,
  paymentType,
}: OrderViewButtonsType): JSX.Element => {
  const {
    currentLocation: { path },
  } = usePlusClient()
  const showErrorToast = useErrorToast()
  const showSuccessToast = useSuccessToast(true)
  const [editMode, setEditMode] = useEditMode()
  const [editValue, setEditValue] = useEditValue()

  const [updatePatient] = useUpdatePatient({
    onError: (e: Error) => showErrorToast('Failed to update patient: ' + e.message),
    onCompleted: () => showSuccessToast('Patient updated successfully'),
  })

  const [updatePaymentType] = useMutation<
    { _id: string; paymentType: PaymentType },
    { orderId: string; paymentType: PaymentType }
  >(UPDATE_PAYMENT_TYPE, {
    onError: (e: Error) => showErrorToast('Failed to update payment type: ' + e.message),
    onCompleted: () => showSuccessToast('Payment type updated successfully'),
  })

  const [updateTrackingNumber] = useMutation<
    { _id: string; trackingNumber: string },
    { orderId: string; trackingNumber: string }
  >(UPDATE_TRACKING_NUMBER, {
    onCompleted: () => showSuccessToast('Tracking number updated successfully'),
  })

  const [updateShippingAddress] = useMutation<{ _id: string; shippingAddress: Address }, { orderId: string } & Address>(
    UPDATE_SHIPPING_ADDRESS,
    {
      onError: (e: Error) => showErrorToast('Failed to update order: ' + e.message),
      onCompleted: () => showSuccessToast('Shipping address updated successfully'),
    },
  )

  const [updateShippingMethod] = useMutation<
    { _id: string; shippingMethod: ShippingMethod },
    { orderId: string; shippingMethod: ShippingMethod }
  >(UPDATE_SHIPPING_METHOD, {
    onError: (e: Error) => showErrorToast('Failed to update order: ' + e.message),
    onCompleted: () => showSuccessToast('Shipping method updated successfully'),
  })
  const [updateIsReservedForInvestigation] = useMutation<
    { updateOrder: { _id: string; isReservedForInvestigation: boolean } },
    { orderId: string; isReservedForInvestigation: boolean }
  >(UPDATE_ORDER_IS_RESERVED_FOR_INVESTIGATION, {
    onError: (e: Error) => {
      showErrorToast(`Failed to update field : ${e.message}`)
    },
    onCompleted: data => {
      const action = data.updateOrder.isReservedForInvestigation ? 'marked' : 'unmarked'
      showSuccessToast(`Successfully ${action} the order as reserved for investigation`)
    },
  })

  const [updateIsReplacementOrder] = useMutation<
    { _id: string; isReplacement: boolean },
    { orderId: string; isReplacement: boolean }
  >(UPDATE_ORDER_IS_REPLACEMENT, {
    onError: (e: Error) => showErrorToast(`Failed to mark the order as replacement: ${e.message}`),
    onCompleted: () => showSuccessToast('Successfully marked the order as a replacement'),
  })

  const [confirmPacking] = useMutation(CONFIRM_PACKING)

  const addressUpdates = order.patient
    ? diffObjects<Partial<Address>>(order.patient.address?.home ?? {}, patientValues?.address?.home ?? {})
    : {}
  const contactsUpdates = order.patient
    ? diffObjects<NonNullable<Patient['contacts']>>(order.patient.contacts ?? {}, patientValues?.contacts ?? {})
    : {}

  const [updateOtcSkusMutation] = useMutation(UPDATE_OTC_SKUS, {
    onError: (e: Error) => showErrorToast('Failed to update otc skus: ' + e.message),
    onCompleted: () => showSuccessToast('Otc skus updated successfully'),
  })

  const otcSkuUpdates = diffObjects(
    Object.fromEntries(order?.otcProducts?.map(({ _id, sku }) => [_id, sku]) ?? []),
    otcSkuValues,
  )

  const shouldShowButtons = order.status !== OrderStatus.Reverted
  const isAfterFill =
    !BEFORE_FILL.has(order.status) ||
    order.rxFillRequests.some(
      rfr =>
        ![RxFillRequestStatus.PV1, RxFillRequestStatus.Adjudication, RxFillRequestStatus.Fill].includes(rfr.status),
    )
  const isOtcOnly = !!order.otcProducts.length && !order.rxFillRequests.length
  const inPackingQueue = path.includes('Packing')
  const disableReroute = isAfterFill && !isOtcOnly

  if (!shouldShowButtons) {
    return <></>
  }

  if (editMode || editValue) {
    return (
      <ActionButtonContainer>
        <CancelButton
          label="Cancel"
          onClick={() => {
            onCancel()
            if (editMode) {
              setEditMode(false)
            } else {
              setEditValue(undefined)
            }
          }}
        />
        <SaveButton
          label="Update"
          onClick={async () => {
            if (!editValue) {
              if (order.patient) {
                const patch = {
                  ...(!isEmpty(addressUpdates) && {
                    address: {
                      home: addressUpdates,
                    },
                  }),
                  ...(!isEmpty(contactsUpdates) && { contacts: contactsUpdates }),
                  ...diffObjects(order.patient, patientValues ?? {}, ['firstName', 'lastName', 'dob', 'gender']),
                }

                // only perform the update if there were any changes
                if (!isEmpty(patch)) {
                  await updatePatient({
                    variables: {
                      patientId: order.patient._id,
                      ...patch,
                    },
                  })
                }
              }

              if (paymentType && paymentType !== order.paymentType) {
                await updatePaymentType({ variables: { orderId: order._id, paymentType } })
              }

              if (Object.keys(otcSkuUpdates).length > 0) {
                updateOtcSkusMutation({
                  variables: {
                    orderId: order._id,
                    otcSkus: Object.entries(otcSkuValues).map(([_id, sku]) => ({ _id, sku })),
                  },
                })
              }

              if (isReservedForInvestigation !== order.isReservedForInvestigation) {
                await updateIsReservedForInvestigation({
                  variables: {
                    orderId: order._id,
                    isReservedForInvestigation,
                  },
                })
              }

              if (isReplacement !== order.isReplacement) {
                await updateIsReplacementOrder({
                  variables: {
                    orderId: order._id,
                    isReplacement,
                  },
                })
              }
            }

            if (editValue === 'trackingNumber') {
              const trackingNumbersFormats = findTracking(trackingNumber, couriers)
              if (trackingNumbersFormats.length > 0) {
                try {
                  const trackingNumberMatch = trackingNumbersFormats[0]?.trackingNumber
                  await updateTrackingNumber({
                    variables: {
                      orderId: order._id,
                      trackingNumber: trackingNumberMatch || trackingNumber,
                    },
                  })
                } catch (e) {
                  showErrorToast(
                    `Failed to update tracking number and confirm packing: ${(e as Record<string, string>).message}`,
                  )
                }
              } else {
                showErrorToast('Please enter a valid tracking number')
              }
            }

            if (editValue === 'shippingMethod') {
              if (shippingMethod && shippingMethod !== order.shippingMethod) {
                await updateShippingMethod({
                  variables: {
                    orderId: order._id,
                    shippingMethod,
                  },
                })
              }
            }

            if (editValue === 'shippingAddress') {
              if (
                shippingAddressValues &&
                Object.keys(diffObjects(order.shippingAddress, shippingAddressValues)).length > 0
              ) {
                await updateShippingAddress({
                  variables: {
                    orderId: order._id,
                    ...shippingAddressValues,
                  },
                })
              }
            }

            if (editMode) {
              setEditMode(false)
            } else {
              setEditValue(undefined)
            }
          }}
        />
      </ActionButtonContainer>
    )
  }

  return (
    <ActionButtonContainer>
      {order.status === OrderStatus.Cancelled && <ResetOrderButton orderId={order._id} disabled={disableOrder} />}
      <RevertToCoreButton order={order} disabled={disableOrder} />
      {order.status !== OrderStatus.Complete && order.status !== OrderStatus.Cancelled && (
        <TriageOrResolveTriageButton item={order} disabled={disableOrder} forceShowTriage />
      )}
      {(mode === OrderViewMode.Fulfillment || OrderViewMode.Pharmacy) && (
        <>
          {showReroute(order) && (
            <AuthLimited
              roles={[
                UserRoles.Admin,
                UserRoles.Pharmacist,
                UserRoles.LeadPharmacist,
                UserRoles.LeadCustomerSupport,
                UserRoles.CustomerSupport,
                UserRoles.Technician,
              ]}
            >
              <ConditionalWrapper
                condition={disableReroute}
                wrapper={children => (
                  <Tooltip
                    label="Rerouting disabled for orders after PV1, please send back instead"
                    variant="white"
                    css={{
                      zIndex: '999',
                    }}
                  >
                    {children}
                  </Tooltip>
                )}
              >
                <RerouteButton order={order} disabled={disableOrder || disableReroute} />
              </ConditionalWrapper>
            </AuthLimited>
          )}
        </>
      )}
      {mode === OrderViewMode.Fulfillment && (
        <>
          {order.status === OrderStatus.Packing && (
            <>
              <AuthLimited roles={[UserRoles.Admin, UserRoles.LeadPharmacist, UserRoles.LeadWarehouse]}>
                <ForceStatusToCompleteButton order={order} disabled={order.status !== OrderStatus.Packing} />
              </AuthLimited>
            </>
          )}
          {(order.status === OrderStatus.Packing || order.status === OrderStatus.Complete) && (
            <PrintShippingLabelButton order={order} setTrackingNumber={setTrackingNumber} />
          )}
          {order.status === OrderStatus.Complete && (
            <AuthLimited
              roles={[
                UserRoles.Admin,
                UserRoles.LeadPharmacist,
                UserRoles.CustomerSupport,
                UserRoles.LeadCustomerSupport,
              ]}
            >
              <ReturnOrderButton order={order} disabled={disableOrder} />
            </AuthLimited>
          )}
        </>
      )}
      {order.status !== OrderStatus.Complete &&
        order.status !== OrderStatus.Returned &&
        order.status !== OrderStatus.Cancelled && (
          <AuthLimited
            roles={[
              UserRoles.Admin,
              UserRoles.Pharmacist,
              UserRoles.LeadPharmacist,
              UserRoles.CustomerSupport,
              UserRoles.LeadCustomerSupport,
            ]}
          >
            <RejectOrderButton order={order} disabled={disableOrder} />
          </AuthLimited>
        )}
      {mode === OrderViewMode.Pharmacy && (
        <AuthLimited roles={[UserRoles.LeadCustomerSupport]}>
          <ReplacementButton orderId={order._id} disabled={disableOrder} />
        </AuthLimited>
      )}
      {mode === OrderViewMode.Pharmacy && (
        <AuthLimited roles={[UserRoles.LeadCustomerSupport]}>
          <ReplacementButton orderId={order._id} disabled={disableOrder} />
        </AuthLimited>
      )}
      {mode === OrderViewMode.Pharmacy && (
        <AuthLimited
          roles={[
            UserRoles.Admin,
            UserRoles.Pharmacist,
            UserRoles.LeadPharmacist,
            UserRoles.CustomerSupport,
            UserRoles.LeadCustomerSupport,
          ]}
        >
          <IssueButton orderId={order._id} disabled={disableOrder} />
        </AuthLimited>
      )}
      {order.status === OrderStatus.Complete && isOtcOnly && (
        <AuthLimited
          roles={[UserRoles.Admin, UserRoles.LeadPharmacist, UserRoles.CustomerSupport, UserRoles.LeadCustomerSupport]}
        >
          <ReturnOrderButton order={order} disabled={disableOrder} />
        </AuthLimited>
      )}
      {isOtcOnly && inPackingQueue && <NextOrderButton disabled={order.status !== OrderStatus.Complete} />}
    </ActionButtonContainer>
  )
}
export default ActionButtons
