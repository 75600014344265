import { Checkbox, Select } from '@truepill/react-capsule'
import { stateAllOption, dashboardStateDefaultOption, LaunchDarkly } from '@truepill/tpos-types'
import { ReactComponent as Map } from 'assets/icons/map.svg'
import IconWrapper from 'components/IconWrapper'
import { useFlag } from 'providers/LaunchDarklyProvider'
import { usePlusClient } from 'providers/VisionRouter'
import { capitalize } from 'utils'
import states from '../../utils/states'

type StatesSelectProps = {
  label: string
  variant?: 'small' | 'large'
}

const StatesSelect = (props: StatesSelectProps): JSX.Element => {
  const displayDashboardDesignV2: boolean = useFlag(LaunchDarkly.FeatureFlags.TPOS_DASHBOARD_DESIGN_V2) ?? false

  const { label, variant = 'large' } = props
  const { currentLocation, routeToMergedQuery } = usePlusClient()

  const noStates = currentLocation?.queryMap?.noStates
  const selectedOptions = currentLocation?.queryMap?.states || []

  const defaultOption = {
    name: 'All states',
    value: stateAllOption,
  }

  const statesOptions: { name: string; value: string }[] = Object.values(states).map(value => ({ name: value, value }))

  const options = [defaultOption, ...statesOptions]

  const allStatesValue = displayDashboardDesignV2 ? dashboardStateDefaultOption : stateAllOption
  const value = selectedOptions.includes(stateAllOption) ? allStatesValue : selectedOptions.join(',')

  if (!noStates && !selectedOptions.length) {
    routeToMergedQuery({ states: [stateAllOption] })
  }

  return (
    <Select
      variant={variant}
      label={label && capitalize(label)}
      css={{
        ul: { maxHeight: '21rem' },
        ...(displayDashboardDesignV2 && variant === 'small' && { width: '235px' }),
      }}
      value={value}
      onChange={() => {
        // Because the elements are checkboxes which trigger an action, we do not need to do anything here
      }}
      options={options}
      placeholder="Select states"
      selectedKey="name"
      startAdornment={() =>
        displayDashboardDesignV2 ? (
          <IconWrapper>
            <Map />
          </IconWrapper>
        ) : (
          <></>
        )
      }
      optionComponent={({ option }) => (
        <>
          <Checkbox
            label={option?.name}
            checked={selectedOptions.includes(option?.value) || selectedOptions.includes(stateAllOption)}
            onCheckedChange={checked => {
              if (option?.value === stateAllOption) {
                // Toggle between all states and no states
                routeToMergedQuery(
                  checked ? { states: [stateAllOption], noStates: undefined } : { states: [], noStates: true },
                )
              } else {
                if (checked) {
                  // Add option to selected states
                  routeToMergedQuery({
                    states: [...selectedOptions, option?.value],
                    noStates: undefined,
                  })
                } else {
                  const newSelectedOptions = selectedOptions.includes(stateAllOption)
                    ? // If 'All' option was previously selected, select all states except the now unchecked option
                      statesOptions.filter(opt => opt.value !== option?.value).map(opt => opt.value)
                    : // Otherwise remove the option from the selected options
                      selectedOptions.filter((value: string) => value !== option?.value)
                  routeToMergedQuery({
                    states: newSelectedOptions,
                    noStates: undefined,
                  })
                }
              }
            }}
          />
        </>
      )}
    />
  )
}

export default StatesSelect
