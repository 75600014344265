import { useEffect, useState } from 'react'
import { Button } from '@truepill/react-capsule'
import { useMutation } from '@truepill/tpos-react-router'
import { PrinterPurpose, OrderStatus } from '@truepill/tpos-types'
import styled, { css } from 'styled-components'
import { capsulePrimaryColorDark, capsulePrimaryColorLight, capsuleDarkBlue, capsuleRed } from 'styles/styleVariables'
import { ReactComponent as AlertIcon } from '../../../assets/icons/alert-icon.svg'
import { ReactComponent as PrintIcon } from '../../../assets/icons/print.svg'
import { GENERATE_SHIPPING_LABEL, PRINT_SHIPPING_LABEL } from '../../../gql'
import useErrorToast from '../../../hooks/toast/useErrorToast'
import useInfoToast from '../../../hooks/toast/useInfoToast'
import useSuccessToast from '../../../hooks/toast/useSuccessToast'
import { usePrintProvider } from '../../../providers/PrintProvider'
import { useTaskContext } from '../../../providers/TaskProvider'
import { useTPCacheContext } from '../../../providers/TPCacheProvider'
import type { Printer, Order } from '../../../types'
import { isLabelPrinted, isShipmentGenerated } from '../../../utils'
import ReprintShippingModal from './ReprintShippingLabelModal'

const MakePrinterOption = (printer: Printer, getLocationNameById: { (locationId: string): string }) => ({
  label: `${printer.printerName} - ${getLocationNameById(printer.locationId)}`,
  value: printer,
})

type PrintShippingLabelButtonProps = { order: Order; setTrackingNumber: (trackingNumber: string) => void }

const PrintShippingLabelButton = ({ order, setTrackingNumber }: PrintShippingLabelButtonProps): JSX.Element => {
  const { rxFillRequests, otcProducts } = order

  const { setPrinter, getSavedPrinterId } = usePrintProvider()

  const [showReprintModal, setShowReprintModal] = useState(false)
  const [currentShippingLabelPrinter, setShippingLabelPrinter] = useState<{ label: string; value: Printer }>()
  const [isGenerateLabelDisabled, setIsGenerateLabelDisabled] = useState(false)

  const { getPrinterById } = useTPCacheContext()
  const { getLocationNameById } = useTPCacheContext()
  const { tasks } = useTaskContext()

  const showErrorToast = useErrorToast()
  const showInfoToast = useInfoToast()
  const showSuccessToast = useSuccessToast()

  const hasIncompleteTasks = tasks.some(({ completed }) => !completed)
  const hasErrors = !currentShippingLabelPrinter

  useEffect(() => {
    const persistedShippingLabelPrinterId = getSavedPrinterId(PrinterPurpose.ShippingLabel)
    if (persistedShippingLabelPrinterId && persistedShippingLabelPrinterId.length) {
      const shippingLabelPrinter = getPrinterById(persistedShippingLabelPrinterId)

      if (shippingLabelPrinter) {
        setPrinter(shippingLabelPrinter as Printer)
        setShippingLabelPrinter(MakePrinterOption(shippingLabelPrinter, getLocationNameById))
      }
    }
  }, [setPrinter, getPrinterById, getSavedPrinterId, setShippingLabelPrinter, getLocationNameById])

  const [printShippingLabel, { loading: isPrintLoading }] = useMutation(PRINT_SHIPPING_LABEL, {
    refetchQueries: ['getFullOrder'],
    onCompleted: () => showSuccessToast('Print request sent.'),
    onError: error => showErrorToast(`Failed to print shipping label. ` + error.message),
  })

  const [generateShippingLabel] = useMutation(GENERATE_SHIPPING_LABEL, {
    onCompleted: () => showSuccessToast('Shipping label generated'),
    onError: error => showErrorToast(`Failed to generate label: ${error?.message}`),
  })

  const handleOnButtonClick = async () => {
    if (order.inTriage) {
      showErrorToast(`Failed to confirm packing: unable to confirm an order while it is in Triage`)
      return
    }

    if (isLabelPrinted(order)) {
      setShowReprintModal(true)
      return
    }

    if (currentShippingLabelPrinter?.value) {
      if (!isShipmentGenerated(order)) {
        if (isGenerateLabelDisabled) return
        setIsGenerateLabelDisabled(true)
        setTimeout(() => setIsGenerateLabelDisabled(false), 30000)
        showInfoToast('Requesting shipping label')
        const scannedTasks =
          order.status === OrderStatus.Packing
            ? tasks
                .filter(task => task.productType === 'OTC')
                .map(task => {
                  return {
                    sku: task.code,
                    upc: task.additionalData?.upcScanned || '',
                  }
                })
            : []
        const res = await generateShippingLabel({
          variables: { coreOrderId: order.coreOrderId, scannedTasks },
          refetchQueries: ['getFullOrder'],
        })
        const trackingNumber = res.data?.generateShippingLabel?.shipment.tracking_number
        if (trackingNumber) {
          setTrackingNumber(trackingNumber)
        }
      }
      const variables: { orderId: string; printerUrl: string } = {
        orderId: order._id,
        printerUrl: currentShippingLabelPrinter.value?.GCPAddress,
      }
      await printShippingLabel({ variables })
    }
  }

  return (
    <>
      <ReprintShippingModal
        isOpen={showReprintModal}
        setIsOpen={setShowReprintModal}
        orderId={order._id}
        locationId={order.locationId}
        customerLegacyId={order.customer?.legacyId}
      />
      <ButtonWrapper>
        <Button
          size="xs"
          variant="primary-outline"
          onClick={handleOnButtonClick}
          disabled={!currentShippingLabelPrinter || hasIncompleteTasks || isPrintLoading}
        >
          {<PrintIcon />} {`${isLabelPrinted(order) ? 'Reprint' : 'Print'} shipping label`}
        </Button>
        {hasErrors && (
          <ErrorContainer>
            <AlertIcon fill={capsuleRed} />
            Please select a printer
          </ErrorContainer>
        )}
      </ButtonWrapper>
    </>
  )
}

const StyledButton = css`
  border-radius: 8px;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  svg {
    fill: ${capsulePrimaryColorDark};
    margin-right: 0.25rem;
    min-width: 16px;
  }
  :hover {
    svg {
      fill: ${capsulePrimaryColorLight};
    }
  }
  :disabled {
    svg {
      fill: ${capsuleDarkBlue};
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-left: 0.625rem;
  ul {
    width: auto;
    right: 0;
    white-space: nowrap;
  }
  > button {
    ${StyledButton}
  }
`

const ErrorContainer = styled.div`
  position: absolute;
  bottom: 2px;
  display: flex;
  align-items: center;
  color: ${capsuleRed};
  font-size: 90%;
  svg {
    margin: 0 0.25rem 0.125rem 0.525rem;
  }
`

export default PrintShippingLabelButton
