interface filterParams {
  isBatch?: boolean
}

export const getBatchFilter = ({ isBatch }: filterParams) => {
  if (isBatch === true) {
    return {
      $and: [
        { 'rxFillRequests.1': { $exists: true } },
        // Ensure that existing orders without patientCount are filtered as batch
        { $or: [{ patientCount: 1 }, { patientCount: { $exists: false } }] },
      ],
    }
  }

  return {
    ...(isBatch !== undefined && { 'rxFillRequests.1': { $exists: false } }),
  }
}

export default getBatchFilter
